import React, { useState } from 'react';
import MasterService from '../../services/MasterService';
import { toast } from 'react-toastify';
import Loader from '../common/loader';
import InputField from '../common/Input';
const ResetMaxAttempts = (props) => {
    const [loading, setLoading] = useState(false);
    const [username, setUserName] = useState('')
    const [errors, setError] = useState({});

    const handleReset = async () => {
        let res = await isValidate()
        if (res) {
            setLoading(true)
            await MasterService.post('/sfa/admin/resetMaxAttempts', { username }).then(function (response) {
                if (response.data.status === 200) {
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message);
                }
            }).catch(function (response) {
            });
            setLoading(false)
        }
    }

    const isValidate = async () => {
        let formIsValid = true;
        let tempErrors = {};
        if (!username) {
            formIsValid = false;
            tempErrors["username"] = 'Field is required';
        }
        setError(tempErrors);
        return formIsValid;
    }

    const handleInputChange = async (target_name, type, event) => {
        if (target_name === "username") {
            setError({})
            setUserName(event.target.value)
        }
    }

    return (
        <React.Fragment>
            {loading ? <Loader /> : null}
            <div className="container-fluid">
                <div className="dealer-user-mapping-user">
                    <div className="top-heading d-flex">
                        <h1>Reset Max Attempts</h1>
                    </div>
                    <div>
                        <div className="dealer-user-mapping-tab-outer">
                            <div className="table-heading-filter">
                                <div className="filter-right-panel">
                                    <fieldset>
                                        <div className="material">
                                            <InputField type="text" name="username" error={(errors.username) ? errors.username : ''} id="username" label="Search By Email Or Mobile" placeholder="Search By Email Or Mobile" value={username} onChange={handleInputChange.bind(null, 'username', 'text')} />
                                        </div>
                                    </fieldset>
                                </div>

                            </div>

                        </div>
                        <div class="btn-submit-reset m-sm-l">
                            <button className='btn-primary btn-reset_max_attempts' onClick={() => handleReset()}>Reset Attempts</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}
export default ResetMaxAttempts;