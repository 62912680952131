import React, { Component } from 'react';
import Select, { components } from 'react-select';
// import Img_preview from "../../webroot/images/preview_img.png";//removed warning
import Selfie_KTP from "../../webroot/images/SelfieKTP.png";
import KTP from "../../webroot/images/KTP.png";
import Bank_Statement from "../../webroot/images/BankStatement.png";
import NPWP from "../../webroot/images/NPWP.png";
import Agreement_Form from "../../webroot/images/AgreementForm.png";
import SimpleSlider from "./uploadDocSlider"
import { TENANT } from '../../config/constant';
const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};
const Bank = [
    { value: 'North Zone', label: 'North Zone' },
    { value: 'West Zone', label: 'West Zone' },
    { value: 'East Zone', label: 'East Zone' },
    { value: 'south Zone', label: 'south Zone' },
];


class AgentUserDetailTab extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        this.state = {

        };
    }

    showModalAddPrefrencepopup = () => {
        this.setState({ AddPrefrencepopup: true });
        document.body.classList.add("overflow-hidden");
    }

    hideModalAddPrefrencepopup = () => {
        this.setState({ AddPrefrencepopup: false });
        document.body.classList.remove("overflow-hidden");
    }

    render() {

        return (
            <div className='preview-confirmation add-user-detail-filed'>
                <div className='top-heading d-flex'>
                    <h2>Role Details</h2>
                    <a href={() => false} className='edit-opt'>Edit</a>
                </div>

                <div className='d-flex'>
                    <div className='role-detail-preview'>
                        <h3>Business Vertical</h3>
                        <ul>
                            <li>
                                <span>Business</span>
                                <label>{(TENANT=="tri-id") ? 'TriiVCO' :(TENANT=="fav-id")? 'Favorite':"OTO"}</label>
                            </li>
                            <li>
                                <span>Vertical</span>
                                <label>Used Car</label>
                            </li>
                        </ul>
                    </div>
                    <div className='role-detail-preview'>
                        <h3>Map Region</h3>
                        <ul>
                            <li>
                                <span>Zone</span>
                                <label>Selected Zone</label>
                            </li>
                            <li>
                                <span>State</span>
                                <label>5 States</label>
                            </li>
                            <li>
                                <span>City</span>
                                <label>20 Cities</label>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='d-flex m-xl-t p-sm-t m-xl-b p-md-b'>
                    <div className='role-detail-preview'>
                        <h3>Role and Reporting</h3>
                        <ul>
                            <li>
                                <span>Role</span>
                                <label>Selected Role</label>
                            </li>
                            <li>
                                <span>Reporting Role</span>
                                <label>RBH</label>
                            </li>
                            <li>
                                <span>Reporting Manager</span>
                                <label>Selected Manager</label>
                            </li>
                        </ul>
                    </div>
                </div>


                <div className='top-heading d-flex '>
                    <h2>Basic Details</h2>
                    <a href={() => false} className='edit-opt'>Edit</a>
                </div>
                <div className='d-flex'>

                    <div className='role-detail-preview preview-basic-details'>
                        <h3>Personal Details</h3>
                        <ul>
                            <li>
                                <span>Username</span>
                                <label>OTOAgent1</label>
                            </li>
                            <li>
                                <span>Mobile</span>
                                <label>9788822112</label>
                            </li>
                            <li>
                                <span>Email</span>
                                <label>agent1@oto.com</label>
                            </li>
                            <li>
                                <span>KTP ID</span>
                                <label className='d-flex'>12345
                                    <span className='verify-txt' onClick={this.showModalAddPrefrencepopup}>Verify</span>
                                </label>
                            </li>
                            <li>
                                <span>Emploment Type</span>
                                <label>Part Time</label>
                            </li>
                            <li>
                                <span>Employee ID</span>
                                <label>OTO00123</label>
                            </li>
                            <li>
                                <span>Joining Date</span>
                                <label>01-08-2022</label>
                            </li>
                            <li>
                                <span>Anniversary</span>
                                <label>10-05-2010</label>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='d-flex  p-sm-t'>
                    <div className='role-detail-preview preview-basic-details'>
                        <h3>Bank Details
                            <span className='verify-txt'>Verify</span>

                        </h3>
                        <ul>
                            <li>
                                <span>Bank Name</span>
                                <label>MUF</label>
                            </li>
                            <li>
                                <span>Branch</span>
                                <label>Branch Address</label>
                            </li>
                            <li>
                                <span>Account Number</span>
                                <label>00000999988223</label>
                            </li>
                            <li>
                                <span>Beneficiary Name</span>
                                <label>Agent 1</label>
                            </li>
                            <li>
                                <span>NPWP</span>
                                <label>TaxID00001929</label>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='d-flex  p-sm-t'>
                    <div className='role-detail-preview preview-basic-details'>
                        <h3>Other Details</h3>
                        <ul>
                            <li>
                                <span>DOB</span>
                                <label>02-01-1990</label>
                            </li>
                            <li>
                                <span>State</span>
                                <label>Haryana</label>
                            </li>
                            <li>
                                <span>City</span>
                                <label>Gurgaon</label>
                            </li>
                            <li>
                                <span>Current Occupation</span>
                                <label>Select Source</label>
                            </li>
                            <li>
                                <span>Company</span>
                                <label>Value</label>
                            </li>
                            <li>
                                <span>Designation</span>
                                <label>Select Source</label>
                            </li>
                            <li>
                                <span>Tagging</span>
                                <label>Select Source</label>
                            </li>
                            <li>
                                <span>Business Line</span>
                                <label>Select Source</label>
                            </li>
                            <li>
                                <span>Source</span>
                                <label>Select Source</label>
                            </li>
                            <li>
                                <span>Postal Code</span>
                                <label>121001</label>
                            </li>
                            <li>
                                <span>House No.</span>
                                <label>D/156</label>
                            </li>
                            <li className='address-txt'>
                                <span>Address</span>
                                <label>Bhag Singh Road, Sector 42, 121001</label>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='d-flex  p-sm-t'>
                    <div className='role-detail-preview preview-basic-details'>
                        <h3>Set Password</h3>
                        <ul>
                            <li>
                                <span>Password</span>
                                <label>**********</label>
                            </li>
                        </ul>
                    </div>
                    <div className='role-detail-preview'>
                        <h3>Reference</h3>
                        <ul>
                            <li>
                                <span>Reference Name & ID</span>
                                <label>Agent 2 - OTO00123</label>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='d-flex  p-sm-t m-xl-b p-md-b'>
                    <div className='role-detail-preview preview-basic-details'>
                        <h3>Additional Details</h3>
                        <ul>
                            <li>
                                <span>Created Date</span>
                                <label>31-07-2022</label>
                            </li>
                            <li>
                                <span>Approval Date</span>
                                <label>NA</label>
                            </li>
                            <li>
                                <span>Resign Date 1</span>
                                <label>NA</label>
                            </li>
                            <li>
                                <span>Rejoin Date 1</span>
                                <label>NA</label>
                            </li>
                            <li>
                                <span>Resign Date 2</span>
                                <label>NA</label>
                            </li>
                            <li>
                                <span>Rejoin Date 2</span>
                                <label>NA</label>
                            </li>
                            <li className='address-txt'>
                                <span>Comments</span>
                                <label>Any comments on the user can be captured here.</label>
                            </li>



                        </ul>
                    </div>

                </div>


                <div className='top-heading d-flex'>
                    <h2>Documents</h2>
                    <a href={() => false} className='edit-opt'>Edit</a>
                </div>

                <div className='d-flex'>
                    <div className='role-detail-preview doc-preview-details'>

                        <div className='upload-document-outer'>
                            <div className="img-bx-select-opt" onClick={this.showModalAddPrefrencepopup}>
                                <div className="image-bx">
                                    <img alt="" src={Selfie_KTP} className="" />
                                </div>
                                <span className='document-name'>Selfie with KTP</span>

                            </div>
                            <div className="img-bx-select-opt">
                                <div className="image-bx">
                                    <img alt="" src={KTP} className="" />
                                </div>
                                <span className='document-name'>KTP</span>

                            </div>
                            <div className="img-bx-select-opt">
                                <div className="image-bx">
                                    <img alt="" src={Bank_Statement} className="" />
                                </div>
                                <span className='document-name'>Bank Statement</span>

                            </div>
                            <div className="img-bx-select-opt">
                                <div className="image-bx">
                                    <img alt="" src={NPWP} className="" />
                                </div>
                                <span className='document-name'>NPWP</span>

                            </div>
                            <div className="img-bx-select-opt">
                                <div className="image-bx">
                                    <img alt="" src={Agreement_Form} className="" />
                                </div>
                                <span className='document-name'>Agreement Form</span>

                            </div>

                        </div>
                    </div>

                </div>
                <div className="vechile-document-popup model-popup-outer verify-doc-detail-popup">
                    <Modal show={this.state.AddPrefrencepopup} handleClose={this.hideModalAddPrefrencepopup} >
                        <div className="modal-header">
                            <h2>Verify Details with Documents</h2>
                            <span className='subheading'>Bank Statement</span>
                        </div>
                        <div className="modal-body">
                            <div className='slider-left-sec'>
                                <SimpleSlider />
                                <div className='modal-footer'>
                                    <div className="image-opt">
                                        <ul>
                                            <li>
                                                <a href="/#">
                                                    <i className="ic-delete-icn"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                    <i className="ic-refresh"></i>
                                                </a>
                                            </li>

                                            {/* <li>
                                                <a href="/#">
                                                    <i className="ic-fullscreen"></i>
                                                </a>
                                            </li> */}

                                            <li>
                                                <a href="/#">
                                                    <i className="ic-add"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                    <i className="ic-remove"></i>
                                                </a>
                                            </li>


                                        </ul>

                                    </div>
                                    <button className='btn-line btn-re-upload'>
                                        Re-upload
                                    </button>
                                </div>
                            </div>


                            <div className="slider-right-sec">
                                <fieldset className="single-select ">
                                    <div className="material">
                                        <Select
                                            components={{ ValueContainer: CustomValueContainer }}
                                            // components={{ IndicatorSeparator:() => null }}
                                            options={Bank}
                                            placeholder="Bank"
                                            className="react-select"
                                            classNamePrefix="react-select"
                                        />
                                    </div>
                                </fieldset>
                                <fieldset class="">
                                    <div class="material">
                                        <input id="" type="text" placeholder=" " name="Username" class="form-input" />
                                        <label data-label="Username" class="form-label"></label>
                                    </div>
                                </fieldset>
                                <fieldset class="">
                                    <div class="material">
                                        <input id="" type="text" placeholder=" " name="Username" class="form-input" />
                                        <label data-label="Username" class="form-label"></label>
                                    </div>
                                </fieldset>
                                <fieldset class="">
                                    <div class="material">
                                        <input id="" type="text" placeholder=" " name="Username" class="form-input" />
                                        <label data-label="Username" class="form-label"></label>
                                    </div>
                                </fieldset>
                                <button className='btn-primary'>
                                    Update
                                </button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }
}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};
export default AgentUserDetailTab;