import React, { useEffect, useState } from "react";
import Loader from "../common/loader";
import AddAdminBasicDetails from "./AddAdminBasicDetails";
import { MasterService } from "../../services";
import { toast } from "react-toastify";
import { useNavigate , Routes, Route, NavLink } from "react-router-dom";
import secureStorage from "../../config/encrypt";


const AddAdminUser = () => {
    const [loading, setLoading] = useState(false);
    const [formFillStatusArr, setFormFillStatusList] = useState([]);
    const [adminRole, setAdminRole] = useState([]);
    const [activePageId, setActivePageId] = useState("");
    const [loginUserInfo] = useState(secureStorage.getItem('loginUserInfo'));

    useEffect(() => {
        getAdminRoleList();
    }, [])

    let historyLead = useNavigate();

    const urlprefix = "/add-admin-user";

    const menuList = [
        {
            id: 1,
            index: 1,
            sub_menu: "Admin Basic Details",
            sub_menu_url: "admin_basic_details",
        },
    ];

    // Fetch Data
    const getAdminRoleList = async () => {
        setLoading(true);
        try {
            let response = await MasterService.post('/sfa/admin/get_admin_role_list', {});
            if (response && response.data && response.data.status === 200) {
                let responseData = response.data.data;
                setAdminRole(responseData)
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    // Image Upload
    const uploadProfileImages = async (profile_image_file) => {
        let profile_data = {};
        var formData = new FormData();
        formData.append("upload_type", "sfa_docs");
        formData.append("visit_id", `user/0`);
        formData.append("images", profile_image_file);
        await MasterService.uploadFilesToS3(formData, {
            "Content-Type": "multipart/form-data",
        }).then((result) => {
            if (result.data.status === 200) {
                profile_data.profile_image_name = result.data.data[0].file_name;
                profile_data.profile_image_path = result.data.data[0].file_url;
            }
            toast.success("Profile Image uploaded");
        });
        return profile_data;
    };

    // Helper functions
    const renderSwitch = (param, index) => {
        switch (param.sub_menu) {
            case "Admin Basic Details":
                // return (
                //     <Route exact path={urlprefix + "/admin_basic_details"}>
                //         <AddAdminBasicDetails adminRole={adminRole} handleCreateUser={handleCreateUser} />
                //     </Route>
                // );

                return (
                    <Routes>
                        <Route
                            path='/admin_basic_details'
                            element={
                                <AddAdminBasicDetails adminRole={adminRole} handleCreateUser={handleCreateUser} />
                            }
                        />
                    </Routes>
            );

            default:
        }
    };

    // Handling events
    const handleMenuClick = (sub_menu, index, e) => {
        const linkDisabled = activePageId > sub_menu.id ? false : true;
        if (linkDisabled) e.preventDefault();
        else {
            setActivePageId(sub_menu.id);
            let tempformFillStatusArr = formFillStatusArr;
            if (!tempformFillStatusArr.includes(sub_menu.id)) {
                tempformFillStatusArr.push(sub_menu.id);
                setFormFillStatusList(tempformFillStatusArr);
            }
        }
    };

    const handleCreateUser = async (params) => {
        setLoading(true);
        try {
            let profileData = {};
            if (params.profile_image_file) {
                profileData = await uploadProfileImages(params.profile_image_file);
            }

            if (profileData && Object.keys(profileData).length > 0) {
                params.profile_image_name = profileData.profile_image_name ? profileData.profile_image_name : "";
                params.profile_image_path = profileData.profile_image_path ? profileData.profile_image_path : "";
            }

            const response = await MasterService.post("/sfa/admin/add_admin_user", {...params, created_by: loginUserInfo.id, updated_by: loginUserInfo.id });

            if (response && response.data && response.data.status === 200) {
                let responseData = response.data.data;
                historyLead(`/view-admin-user/${responseData.admin_id_hash}`);
                toast.success("User added successfully");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <div className="add-user-outer">
                {loading ? <Loader /> : null}
                <div className="add-user-form-sec">
                    <div className="menu-tab-left-panel">
                        <div className="add-user-detail-filed">
                            <h2> Sales Support User details </h2>
                        </div>
                        <div className="add-user-left-panel nav">
                            <ul>
                                {menuList.length !== 0 &&
                                    menuList.map((menu, index) => (
                                        <li key={index}>
                                            <NavLink
                                                to={urlprefix + "/" + menu.sub_menu_url}
                                                className={
                                                    formFillStatusArr && formFillStatusArr.includes(menu.id)
                                                        ? "completed"
                                                        : activePageId === menu.id
                                                            ? "active"
                                                            : ""
                                                }
                                                aria-current="page"
                                                exact
                                                onClick={handleMenuClick.bind(this, menu, index)}
                                            >
                                                <span className="img-type"></span>
                                                {menu.sub_menu}
                                            </NavLink>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                    <div className="route-files">{menuList.length !== 0 && menuList.map((menu, k) => <div key={k}>{renderSwitch(menu)}</div>)}</div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddAdminUser;
