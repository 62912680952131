import React, { useState ,useEffect, useRef } from 'react';
import secureStorage from './../../config/encrypt';
import  { components } from 'react-select';
import { Link,useNavigate,useParams } from 'react-router-dom';
import MasterService from './../../services/MasterService';
import ls, {get,set} from "local-storage";
import Loader from '../common/loader';
import { useCookies } from 'react-cookie';
const { ValueContainer, Placeholder } = components;
const UserLogin=(props)=>{
  const historyUser = useNavigate();
  const {sfa_logged_user_id}  = useParams();   
  const [loading, setLoading] = useState(false);
  const [PHPSESSID, setPHPSESSID] = useCookies(['PHPSESSID']);  
  const [sfaNodeCookies, setSfaNodeCookies] = useCookies(['lbwyBzfgzUIvXZFShJuikaWvLJhIVq36']);
  const [loggedInUser, setLoggedInUser] = useState({});
  const [loggedUserId, setLoggedUserId] = useState('');
  useEffect(() => {    
    //let tempphpSessId=(PHPSESSID && PHPSESSID.PHPSESSID)?PHPSESSID.PHPSESSID:'';          
    let sfa_cook_id = "573e9c57381efce35345587b246de497~d8ebe3d1198c643830f11dba35c76362"  
 ls.set('lbwyBzfgzUIvXZFShJuikaWvLJhIVq36',sfa_cook_id);         
      setLoggedUserId(sfa_cook_id);  
      getSfaUserLogIn(sfa_cook_id);                          
  }, []);
ls.set('filter_data',{});
ls.set('current_status','');
const getSfaUserLogIn = async (temp_logged_user_id) => {   
  if(temp_logged_user_id){
    setLoading(true); 
    MasterService.post('/sfa/user/login', {'userId':temp_logged_user_id})
    .then(function (response) {
      if (response.data.status == 200) {  
        setLoggedInUser(response.data.data);
        ls.set('logged_data',response.data.data);
        secureStorage.setItem('loginUserInfo', response.data.data);
        historyUser("/user-management");
        setLoading(false); 
      }else{
        historyUser("/login");
      }
    })
    .catch(function (response) {
      setLoading(false); 
    });
  }  
 }
    return (      
      <React.Fragment>    
        {loading ? <Loader/>: null }    
        <div className="container-fluid">          
        </div>
      </React.Fragment>
    )
  }
export default UserLogin;