import React from 'react';
// import { Business, Zone } from '../../config/constant';
import Loader from '../common/loader';
import { TENANT } from '../../config/constant';

const DealerDetail = (props) => {
    const { user_detail } = props
    
    return (
        <React.Fragment>
            {props.loading ? <Loader /> : null}
            {user_detail && Object.keys(user_detail).length > 0 &&
                <div className='dealer-details-outer'>
                    <h3>{user_detail.dealer_name + " (GCD-" + user_detail.dealer_id + ")"}</h3>
                    <ul>
                        <li>
                            <label>Business Type</label>
                            <div className='detail-list'>
                            {/* {user_detail.units_name?
                                <span>{user_detail.units_name} </span>
                            : */}
                            <div>
                                <span>{TENANT=="fav-id" ? "UCF, UCRF" : "UCF, UCRF, UBRF"}</span>
                                </div>
                                {/* } */}
                            </div>
                        </li>
                        
                        <li>
                            <label>Created Date</label>
                            <div className='detail-list'>
                                <span>{user_detail.created_date}</span>
                            </div>
                        </li>
                        <li>
                            <label>Contact</label>
                            <div className='detail-list'>
                                <span>{user_detail.mobile}</span>
                            </div>
                        </li>
                        <li>
                            <label>Email</label>
                            <div className='detail-list'>
                                <span title={user_detail.email}>{user_detail.email}</span>
                            </div>
                        </li>
                        <li>
                            <label>Dealer Size</label>
                            <div className='detail-list'>
                                <span>{user_detail.dealer_size?user_detail.dealer_size:"-"}</span>
                            </div>
                        </li>
                        {(TENANT=="id") &&
                        <li>
                            <label>Account Manager</label>
                            <div className='detail-list'>
                                <span>{user_detail.account_manager?user_detail.account_manager:"-"}</span>
                            </div>
                        </li>
                     }
                    </ul>

                </div>
            }
        </React.Fragment>
    )
}
export default DealerDetail;