import React, { Component } from 'react';



class MappedRegions extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

   
    render() {

        return (
            <React.Fragment>
            <div className='mapped-regions-outer'>
                <div className='mapped-regions-data'>
                    <div className='mapped-regions-heading'>
                        <h2>Aceh</h2>
                        <span>(17 cities)</span>
                    </div>
                    <ul>
                        <li>Aceh Barat</li>
                        <li>Aceh Barat Daya</li>
                        <li>Aceh Besar</li>
                        <li>Aceh Barat</li>
                        <li>Aceh Barat Daya</li>
                        <li>Aceh Besar</li>
                        <li>Aceh Barat</li>
                        <li>Aceh Barat Daya</li>
                        <li>Aceh Besar</li>
                        <li>Aceh Barat</li>
                        <li>Aceh Barat Daya</li>
                        <li>Aceh Besar</li>
                    </ul>
                </div>

                <div className='mapped-regions-data'>
                    <div className='mapped-regions-heading'>
                        <h2>Sumatera Barat</h2>
                        <span>(11 cities)</span>
                    </div>
                    <ul>
                        <li>Aceh Barat</li>
                        <li>Aceh Barat Daya</li>
                        <li>Aceh Besar</li>
                        <li>Aceh Barat</li>
                        <li>Aceh Barat Daya</li>
                        <li>Aceh Besar</li>
                        <li>Aceh Barat</li>
                        <li>Aceh Barat Daya</li>
                        <li>Aceh Besar</li>
                        <li>Aceh Barat</li>
                        <li>Aceh Barat Daya</li>
                        <li>Aceh Besar</li>
                    </ul>
                </div>

                <div className='mapped-regions-data'>
                    <div className='mapped-regions-heading'>
                        <h2>Nusa Tenggara Timur</h2>
                        <span>(24 cities)</span>
                    </div>
                    <ul>
                        <li>Aceh Barat</li>
                        <li>Aceh Barat Daya</li>
                        <li>Aceh Besar</li>
                        <li>Aceh Barat</li>
                        <li>Aceh Barat Daya</li>
                        <li>Aceh Besar</li>
                        <li>Aceh Barat</li>
                        <li>Aceh Barat Daya</li>
                        <li>Aceh Besar</li>
                        <li>Aceh Barat</li>
                        <li>Aceh Barat Daya</li>
                        <li>Aceh Besar</li>
                        <li>Aceh Barat</li>
                        <li>Aceh Barat Daya</li>
                        <li>Aceh Besar</li>
                        <li>Aceh Barat</li>
                        <li>Aceh Barat Daya</li>
                        <li>Aceh Besar</li>
                        <li>Aceh Barat</li>
                        <li>Aceh Barat Daya</li>
                        <li>Aceh Besar</li>
                        <li>Aceh Barat</li>
                        <li>Aceh Barat Daya</li>
                        <li>Aceh Besar</li>
                    </ul>
                </div>
            </div>            
            </React.Fragment>
        )
    }
}


export default MappedRegions;