import React, { useEffect, useState } from 'react';
import VisitReportTopFilter from "./VisitReportTopFilter"
import { Tab, Nav } from 'react-bootstrap';
import { MasterService } from '../../services';
import { getFirstDate } from '../../config/helper';
import { defaultZone } from '../../config/constant';
import ExecutiveVisitData from './ExecutiveVisitData';
import DealerVisitData from './DealerVisitData';
// import Pagination from '../../sfa-html/dealer-user-mapping/pagination'
const VisitReport = (props) => {
    const [activeTab, setActiveTab] = useState('executive');
    const [visitExecutiveData, setVisitExecutiveData] = useState([]);
    const [visitDealerData, setVisitDealerData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isReset, setReset] = useState(0);
    const [isPageReset, setPageReset] = useState(0);
    const [pageCount, setPageCount] = useState(0)
    const [executive_search_params, setExecutiveSearchParams] = useState({})
    const [dealer_search_params, setDealerSearchParams] = useState({})

    const handleTabChange = (value) => {
        setActiveTab(value)
        setReset(isReset+1)
        if(value === 'dealer'){
            let dealer_params = {
                from_date: getFirstDate(new Date()),
                to_date: new Date(),
                role_id: '',
                dealer:'',
                by_dealer:'',
                zone_id: defaultZone,
                state_list: [],
                visit_status: '1',
                units_id: '2',
                vertical_id: 1,
                page: 1,
                sortby: 'sort_visit_date',
                order: 'DESC',
                by_user: '',
                userId: ''
            }
    
            getDealerVisitData(1, dealer_params)
        } else {
            let executive_params = {
                from_date: getFirstDate(new Date()),
                to_date: new Date(),
                role_id: '',
                zone_id: defaultZone,
                state_list: '',
                visit_status: '1',
                units_id: '2',
                vertical_id: 1,
                page: 1,
                sortby: 'sort_visit_date',
                order: 'DESC',
                by_user: '',
                userId: ''
            }
            getExecutiveVisitData(1, executive_params)
        }
        // handleReset()
    }

    useEffect(() => {
        let executive_params = {
            from_date: getFirstDate(new Date()),
            to_date: new Date(),
            role_id: '',
            zone_id: defaultZone,
            state_list: '',
            visit_status: '1',
            units_id: '2',
            vertical_id: 1,
            page: 1,
            sortby: 'sort_visit_date',
            order: 'DESC',
            by_user: '',
            userId: ''
        }
        getExecutiveVisitData(1, executive_params)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getDealerVisitData = async (page_no, params, page_reset) => {
        if(page_reset){
            setPageReset(isPageReset+1)
        }
        let result = []
        let temp_search_params = { ...dealer_search_params }
        if (Object.keys(params).length) {
            setDealerSearchParams(params)
            temp_search_params = params
        } else {
            temp_search_params.page = page_no
        }
        setLoading(true)
        await MasterService.post('/sfa/dealer/visitDealerListing', temp_search_params).then(function (response) {
            if (response.data.data.allPackageListcount && response.data.data.allPackageListcount > 0) {
                let tempPageCount = Math.ceil(response.data.data.allPackageListcount / response.data.data.per_page);
                setVisitDealerData(response.data.data.visit_data)
                result = response.data.data.visit_data
                setPageCount(tempPageCount);
                setLoading(false)
            } else {
                setVisitDealerData([])
                setPageCount(0);
                setLoading(false)
            }
        }).catch(function (response) {
            setLoading(false)
        });
        return result
    }

    const getExecutiveVisitData = async (page_no, params, page_reset) => {
        let result = []
        if(page_reset){
            setPageReset(isPageReset+1)
        }
        let temp_search_params = { ...executive_search_params }
        if (Object.keys(params).length) {
            setExecutiveSearchParams(params)
            temp_search_params = params
        } else {
            temp_search_params.page = page_no
        }
        setLoading(true)
        await MasterService.post('/sfa/dealer/visitExecutiveListing', temp_search_params).then(function (response) {
            if (response.data.data.allPackageListcount && response.data.data.allPackageListcount > 0) {
                let tempPageCount = Math.ceil(response.data.data.allPackageListcount / response.data.data.per_page);
                setVisitExecutiveData(response.data.data.visit_data)
                result = response.data.data.visit_data
                setLoading(false)
                setPageCount(tempPageCount);
            } else {
                setVisitExecutiveData([])
                setPageCount(0);
                setLoading(false)
            }
        }).catch(function (response) {
            setLoading(false)
        });
        return result

    }

    const getExecutiveVisitExportData = async (params) => {
        let result = []
        setLoading(true)
        await MasterService.post('/sfa/dealer/visitExecutiveExport', params).then(function (response) {
            if (response.data.data.visit_data && response.data.data.visit_data.length > 0) {
                result = response.data.data.visit_data
                setLoading(false)
            } else {
                setLoading(false)
            }
        }).catch(function (response) {
            setLoading(false)
        });
        return result
    }

    const getDealerVisitExportData = async (params) => {
        let result = []
        setLoading(true)
        await MasterService.post('/sfa/dealer/visitDealerExport', params).then(function (response) {
            if (response.data.data.visit_data && response.data.data.visit_data.length > 0) {
                result = response.data.data.visit_data
                setLoading(false)
            } else {
                setLoading(false)
            }
        }).catch(function (response) {
            setLoading(false)
        });
        return result
    }

    const getExportData = async (type) => {
        console.log("type", type)
        if (type === 'executive') {
            let temp_search_params = { ...executive_search_params }
            temp_search_params.apply_limit = "no"
            let response_data = await getExecutiveVisitExportData(temp_search_params)
            return response_data
        } else {
            let temp_search_params = { ...dealer_search_params }
            temp_search_params.apply_limit = "no"
            let response_data = await getDealerVisitExportData(temp_search_params)
            return response_data
        }
    }

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="dealer-user-mapping-user">
                    <div className="top-heading d-flex">
                        <h1>Visit Reports</h1>

                    </div>
                    <div className="dealer-user-mapping-tab-outer map-dealer-main">
                        <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab}>
                            <div className="dealer-user-mapping-tabs">
                                <Nav variant="pills" className="flex-column tab-line">
                                    <div className="tab-list">

                                        <Nav.Item>
                                            <Nav.Link eventKey="executive" onClick={() => handleTabChange('executive')}>Executive Visit History</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="dealer" onClick={() => handleTabChange('dealer')}>Dealer Visit History</Nav.Link>
                                        </Nav.Item>
                                    </div>
                                </Nav>

                            </div>
                            <Tab.Content>
                                <Tab.Pane eventKey="executive">
                                    <div className='map-dealer-outer'>
                                        <div className='visit-filter-outer'>
                                            <VisitReportTopFilter isReset={isReset} type={"executive"} getVisitData={getExecutiveVisitData} />
                                        </div>
                                        <ExecutiveVisitData
                                            getVisitData={getExecutiveVisitData}
                                            pageCount={pageCount}
                                            isPageReset={isPageReset}
                                            visitData={visitExecutiveData}
                                            loading={loading}
                                            getExportData={getExportData}
                                        />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="dealer">
                                    <div className='map-dealer-outer'>
                                        <div className='visit-filter-outer'>
                                            <VisitReportTopFilter isReset={isReset} type={"dealer"} getVisitData={getDealerVisitData} />
                                        </div>
                                        <DealerVisitData
                                            getVisitData={getDealerVisitData}
                                            pageCount={pageCount}
                                            isPageReset={isPageReset}
                                            visitData={visitDealerData}
                                            loading={loading}
                                            getExportData={getExportData}
                                        />
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>


            </div>
        </React.Fragment>
    )

}
export default VisitReport;