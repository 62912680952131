import React, { useState, useEffect } from "react";
import { Zone, Business, Vertical } from '../../config/constant';
import { useNavigate } from "react-router-dom";
import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy';

const RolePreviewConfirm = (props) => {
    const { roleDetails, roleList, stateList, cityList } = props;
    const { handleCreateRole } = props;
    // const [tipOpenState, setTipOpenState] = useState(false);//removed warning
    // const [tipOpenCity, setTipOpenCity] = useState(false);//removed warning
    // const [isEdit, setIsEdit] = useState(roleDetails && roleDetails.role_id && roleDetails.role_id !== "" ? true : false);//removed warning
    const [isEdit] = useState(roleDetails && roleDetails.role_id && roleDetails.role_id !== "" ? true : false);

    const historyLead = useNavigate();
    const tipContentRef = React.createRef();

    const handleEdit = (pathname) => {
        if (isEdit) {
            historyLead(`/edit-role/${pathname}?id=${roleDetails.role_id}`);
        } else {
            historyLead(`/add-role/${pathname}`);
        }
    }

    // function toggleTipCity() {//removed warning
    //     setTipOpenCity(prevState => !prevState);
    // }

    // function toggleTipState() {//removed warning
    //     setTipOpenState(prevState => !prevState);
    // }

    function bodyClickState(e) {
        // setTipOpenState(false);//removed warning
    }

    function bodyClickCity(e) {
        // setTipOpenCity(false);//removed warning
    }

    useEffect(() => {
        document.addEventListener('mousedown', bodyClickState);
        document.addEventListener('mousedown', bodyClickCity);
        return () => {
            document.removeEventListener('mousedown', bodyClickState);
            document.removeEventListener('mousedown', bodyClickCity);
        };
    }, []);

    return <React.Fragment>
        <div className='preview-confirmation p-xxl add-user-detail-filed'>
            <div className='top-heading'>
                <h2> Preview and Confirm</h2>
            </div>

            <div className='top-heading d-flex'>
                <h2>Role Details</h2>
                <a className="edit-opt" href={() => false} onClick={() => { handleEdit(`role_details_info`) }}>
                    Edit
                </a>
            </div>

            <div className='d-flex'>
                <div className='role-detail-preview'>
                    <h3>Business Vertical</h3>
                    <ul>
                        <li>
                            <span>Business</span>
                            <label>{Business.filter(item => item.id === roleDetails?.role_details_info?.business_unit).map(item => item.value)}</label>
                        </li>
                        <li>
                            <span>Vertical</span>
                            <label>{Vertical.filter(item => item.id === roleDetails?.role_details_info?.vertical).map(item => item.value)}</label>
                        </li>
                    </ul>
                </div>

            </div>

            <div className='d-flex m-xl-t p-sm-t'>
                <div className='role-detail-preview role-informatiom'>
                    <h3>Role Information</h3>
                    <ul>
                        <li>
                            <span>Role name</span>
                            <label>{roleDetails?.role_details_info?.role_name}</label>
                        </li>
                        <li>
                            <span>Status</span>
                            <label>{roleDetails?.role_details_info?.status === 'active'? "Active" : "Inactive"}</label>
                        </li>
                        {/* <li>
                            <span>Digital Consultant</span>
                            <label>{roleDetails?.role_details_info?.digital_consultant === '1' ? "Activate" : "Deactivate"}</label>
                        </li> */}
                        <li>
                            <span>Role Discription</span>
                            <label>{roleDetails?.role_details_info?.role_description}</label>
                        </li>
                    </ul>
                </div>
            </div>

            <div className='d-flex m-xl-t p-sm-t br-bottom'>
                <div className='role-detail-preview'>

                    <div className='role-detail-preview'>
                        <h3>Map Region</h3>
                        <ul>
                            <li>
                                <span>Zone</span>
                                <label>{Zone.filter(item => item.id === roleDetails?.role_details_info?.zone_id).map(item => item.value)}</label>
                            </li>
                            <li>
                                <span>State</span>
                                <label className='region-tooltip'>{roleDetails?.role_details_info?.state.length} Selected
                                    {/* <span onClick={toggleTipState} title="" className="tooltio-ic"> <i className='ic-info-icon1 m-sm-l'></i> </span> */}

                                    {/* <Tooltip
                                        content={(
                                            <div ref={tipContentRef} className="controlled-example tooltip-main">
                                                <div className="tooltip-data">
                                                    <span className="controlled-example_close-button" onClick={toggleTipState}><i className="ic-clearclose"></i></span>
                                                    <ul className="user-detail">
                                                        {roleDetails && roleDetails?.role_details_info?.state && stateList && stateList.filter(st => roleDetails.role_details_info.state.includes(st.id)).map((item, k) => <li key={k}>{item.label}</li>)}
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                        isOpen={tipOpenState}
                                        tagName="span"
                                        direction="down"
                                        className="tootip-outer"
                                        forceDirection
                                    >
                                    </Tooltip> */}
                                    <Tooltip
                                        useContext
                                        html={(
                                            <div ref={tipContentRef} className="controlled-example tooltip-main">
                                                <div className="tooltip-data tootip-outer">
                                                    <ul className="user-detail">
                                                        {roleDetails && roleDetails?.role_details_info?.state && stateList && stateList.filter(st => roleDetails.role_details_info.state.includes(st.id)).map((item, k) => <li key={k}>{item.label}</li>)}
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                        className="tootip-outer"
                                        // open="true"
                                        theme="light"
                                        size="regular"
                                        position="bottom"
                                        trigger="click"
                                    >
                                    <span title="" className="tooltio-ic"> <i className='ic-info-icon1 m-sm-l'></i> </span>
                                    </Tooltip>
                                </label>
                            </li>
                            <li>
                                <span>City</span>
                                <label className='region-tooltip'>{roleDetails?.role_details_info?.city.length} Selected
                                    {/* <span onClick={toggleTipCity} title="" className="tooltio-ic"> <i className='ic-info-icon1 m-sm-l'></i> </span> */}
                                    {/* <Tooltip
                                        content={(
                                            <div ref={tipContentRef} className="controlled-example tooltip-main">
                                                <div className="tooltip-data">

                                                    <span className="controlled-example_close-button" onClick={toggleTipState}><i className="ic-clearclose"></i></span>
                                                    <ul className="user-detail">
                                                        {roleDetails && roleDetails?.role_details_info?.city && cityList && cityList.filter(cty => roleDetails.role_details_info.city.includes(cty.id)).map((item, k) => <li key={k}>{item.label}</li>)}
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                        isOpen={tipOpenCity}
                                        tagName="span"
                                        direction="down"
                                        className="tootip-outer"
                                        forceDirection
                                    >
                                    </Tooltip> */}
                                    <Tooltip
                                        useContext
                                        html={(
                                            <div ref={tipContentRef} className="controlled-example tooltip-main">
                                                <div className="tooltip-data tootip-outer">
                                                    <ul className="user-detail">
                                                        {roleDetails && roleDetails?.role_details_info?.city && cityList && cityList.filter(cty => roleDetails.role_details_info.city.includes(cty.id)).map((item, k) => <li key={k}>{item.label}</li>)}
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                        className="tootip-outer"
                                        // open="true"
                                        theme="light"
                                        size="regular"
                                        position="bottom"
                                        trigger="click"
                                        >
                                        <span title="" className="tooltio-ic"> <i className='ic-info-icon1 m-sm-l'></i> </span>
                                        </Tooltip>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className='top-heading d-flex'> 
                <h2>Assign Rights</h2>
                <a href={() => false} className="edit-opt" onClick={() => { handleEdit(`assign_rights`) }}>
                    Edit
                </a>
            </div>
            <div className='d-flex'>

                <div className='role-detail-preview preview-basic-details'>
                    <h3>Rights Detail</h3>
                    <ul>
                        <li>
                            <span>Report of Role    </span>
                            <label>  {roleList
                                .filter((role) => roleDetails?.assign_rights?.reportee.includes(role.id))
                                .map((item) => item.label)
                                .join(",")}</label>
                        </li>
                        <li>
                            <span>View Report of</span>
                            <label> {roleList
                                .filter((role) => roleDetails?.assign_rights?.role.includes(role.id))
                                .map((item) => item.label)
                                .join(",")}</label>
                        </li>
                        <li>
                            <span>Send Message</span>
                            <label>All</label>
                        </li>
                        <li>
                            <span>Reseive Message</span>
                            <label>All</label>
                        </li>

                    </ul>
                </div>
            </div>
            <div className='d-flex  p-sm-t'>
                <div className='role-detail-preview'>
                    <h3>Rights Detail</h3>
                    <ul className='right-detail-list'>

                        {roleDetails?.assign_rights?.feature_access &&
                            roleDetails?.assign_rights?.feature_access?.length > 0 &&
                            roleDetails?.assign_rights?.feature_access.map(
                                (rights, key) => {
                                    return (
                                        <li key={key} className="custom-control custom-checkbox" >
                                            <input
                                                id={`sli${key}`}
                                                type="checkbox"
                                                className="custom-control-input"
                                                checked={rights && rights.is_enabled === true}
                                                readOnly
                                            />
                                            <label htmlFor={`sli${key}`} className="custom-control-label" >
                                                {rights.name}
                                            </label>
                                        </li>
                                    );
                                }
                            )}
                    </ul>
                </div>
            </div>

            <div className="br-top t-right">
                <button className='btn-primary' onClick={handleCreateRole} >
                    Submit
                </button>
            </div>
        </div>
    </React.Fragment>
}

export default RolePreviewConfirm;