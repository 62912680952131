import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import { defaultZone, EXECUTIVE_ROLES, VISIT_EXECUTIVE_ROLES } from '../../config/constant';
import { MasterService } from '../../services';
import { Calendra, Calendra2 } from './DatePicker'
import { getFirstDate, sort } from '../../config/helper';
import AsyncSelect from 'react-select/async';
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const VisitTypeList = [
    { value: '', label: 'All Visit' },
    { value: 'DI', label: 'Dealer(On Location)' },
    { value: 'DO', label: 'Dealer(Off Location)' },
    { value: 'C', label: 'Customer' },
    { value: 'O', label: 'Office' },
    { value: 'A', label: 'Agent' },
    { value: 'F', label: 'Financier' },
    { value: 'ND', label: 'New Used Car Dealer' },

]
const statusOptions = [
    { value: 'All', label: 'All' },
    { value: '1', label: 'Completed' },
    { value: '0', label: 'Pending' },
]

const VisitReportTopFilter = (props) => {
    const [zoneValue, setZone] = useState(defaultZone)
    const [roleList, setRoleList] = useState([])
    const [roleSelected, setRole] = useState(null);
    const [cityList, setCityList] = useState([])
    const [stateList, setStateList] = useState([])
    const [city, setCity] = useState([]);
    const [state, setState] = useState(null);
    const [fromDate, setFromDate] = useState(getFirstDate(new Date()));
    const [toDate, setToDate] = useState(new Date());
    const [visitType, setVisitType] = useState(null);
    const [statusType, setStatusType] = useState(null);
    const [executiveData, setExecutiveData] = useState(null);
    const [dealerData, setDealerData] = useState(null);
    const [executive, setExecutive] = useState(null);
    const [dealer, setDealer] = useState(null);

    useEffect(() => {
        getRoleList()
        getStateList()
        getDealerListing()
        // getCityList([], true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.isReset) {
            handleReset()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isReset])

    useEffect(() => {
        if ((city && city.length) || roleSelected || props.type === "dealer") {
            if (props.type === "dealer") {
                getDealerListing()
            } else {
                getExecutiveListing()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [city, roleSelected])

    const getStateList = async () => {
        MasterService.post('/sfa/user/state_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var state_list = response.data.data.map(elm => ({ id: elm.id, label: elm.state_name, value: elm.id }));
                    setStateList(state_list);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
            });
    }

    const getCityList = async (state_ids) => {
        MasterService.post('/sfa/user/city_list', { state_ids })
            .then(function (response) {
                if (response.data.status === 200) {
                    var city_list = response.data.data.map(elm => ({ id: elm.id, label: elm.city_name, value: elm.id, state_id: elm.state_id }));
                    setCityList(city_list);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
            });
    }

    const getRoleList = async () => {
        MasterService.post('/sfa/user/role_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    if (props.type === "dealer") {
                        let role_list = response.data.data.filter(e => EXECUTIVE_ROLES.includes(e.id))
                        role_list = role_list.map(elm => ({ id: elm.id, label: elm.name, value: elm.name }));
                        setRoleList(role_list)
                    }
                    if (props.type === "executive") {
                        let role_list = response.data.data.filter(e => VISIT_EXECUTIVE_ROLES.includes(e.id))
                        role_list = role_list.map(elm => ({ id: elm.id, label: elm.name, value: elm.name }));
                        setRoleList(role_list)
                    }
                }
            })
            .catch(function (response) {
            });
    }

    const handleChange = (event, name) => {
        if (name === "zone") {
            setZone(event.id)
        }
        if (name === "from_date") {
            setFromDate(event)
        }
        if (name === "to_date") {
            setToDate(event)
            if (new Date(event).getTime() <= new Date(fromDate).getTime()) {
                setFromDate(null)
            }
        }
        if (name === "role") {
            setRole(event)
            setExecutive(null)
        }
        if (name === "state") {
            setState(event)
            setExecutive(null)
            setCity([])
            getCityList([event.id], true)
            setDealer(null)
        }
        if (name === "city") {
            setCity(event)
            setExecutive(null)
            setDealer(null)
        }
        if (name === "visit_type") {
            setVisitType(event)
        }
        if (name === 'status') {
            setStatusType(event)
        }
        if (name === 'executive') {
            setExecutive(event)
        }
        if (name === 'dealer') {
            setDealer(event)
        }
    };

    const getExecutiveListing = async () => {
        let searchData = {
            search_text: '',
            city_id: city && city.id ? [city.id] : '',
            state_id: state && state.id ? state.id : '',
            apply_limit: "no",
            vertical_id: 1,
            page: 1,
            role_id: roleSelected ? roleSelected.id : '',
        }
        await MasterService.post('/sfa/dealer/executiveListByCities', searchData)
            .then(function (response) {
                if (response.data.status === 200) {
                    let executive_names = response.data.data.executive_data.map((e) => ({
                        label: e.name + " (SFA ID - " + e.id + ")",
                        id: e.id,
                        value: e.id,
                        user_id: e.id
                    }))
                    executive_names = sort(executive_names, 'label')
                    setExecutiveData(executive_names);
                } else {
                    if (response.data.error && response.data.error.length > 0) {
                        response.data.error.forEach(element => {
                            toast.error(element);
                        });
                    } else {
                        toast.error(response.data.message);
                    }
                }
            })
            .catch(function (response) {
            });
    }

    const getDealerListing = async () => {
        let searchData = {
            zone: defaultZone,
            city_ids: city && city.id ? [city.id] : '',
            state_id: state && state.id ? state.id : '',
        }
        await MasterService.post('/sfa/dealer/dealerListByCities', searchData)
            .then(function (response) {
                if (response.data.status === 200) {
                    let dealer_names = response.data.data.dealer_data.map((e) => ({
                        label: e.organization + " (GCD - " + e.dealer_id + ")",
                        dealer_code: e.dealer_id,
                        id: e.dealer_id,
                        value: e.dealer_id
                    }))
                    dealer_names = sort(dealer_names, 'label')
                    setDealerData(dealer_names);
                } else {
                    if (response.data.error && response.data.error.length > 0) {
                        response.data.error.forEach(element => {
                            toast.error(element);
                        });
                    } else {
                        toast.error(response.data.message);
                    }
                }
            })
            .catch(function (response) {
            });
    }

    const handleSearch = (is_reset) => {
        if (props.type === "executive") {
            if (is_reset) {
                let params = {
                    from_date: getFirstDate(new Date()),
                    to_date: new Date(),
                    role_id: '',
                    zone_id: zoneValue,
                    state_list: '',
                    city_list: '',
                    visit_type: '',
                    visit_status: '1',
                    units_id: '2',
                    vertical_id: 1,
                    page: 1,
                    sortby: 'sort_visit_date',
                    order: 'DESC',
                    by_user: '',
                    userId: ''
                }
                props.getVisitData(1, params, true)
            } else {
                let visit_type = ''
                let visit_location_type = ''
                if(visitType && visitType.value){
                    if(visitType.value === "DI" || visitType.value === "DO"){
                        let temp_value = visitType.value.split("")
                        visit_type = temp_value[0]
                        if(temp_value[1] == "I"){
                            visit_location_type = "onsite"
                        }
                        if(temp_value[1] == "O"){
                            visit_location_type = "offsite"
                        }
                    } else {
                        visit_type = visitType.value
                    }
                }
                let params = {
                    from_date: fromDate,
                    to_date: toDate,
                    role_id: roleSelected ? roleSelected.id : '',
                    zone_id: zoneValue,
                    state_list: state && state.id ? [state.id] : '',
                    city_list: city && city.id ? [city.id] : '',
                    visit_type: visit_type,
                    visit_status: statusType && statusType.value ? statusType.value : '1',
                    units_id: '2',
                    vertical_id: 1,
                    page: 1,
                    sortby: 'sort_visit_date',
                    order: 'DESC',
                    by_user: '',
                    userId: executive && executive.id ? executive.id : '',
                    visit_location_type: visit_location_type
                }
                props.getVisitData(1, params, true)
            }
        }
        if (props.type === "dealer") {
            if (is_reset) {
                let params = {
                    from_date: getFirstDate(new Date()),
                    to_date: new Date(),
                    role_id: '',
                    zone_id: zoneValue,
                    state_list: '',
                    city_list: '',
                    visit_type: '',
                    visit_status: '1',
                    units_id: '2',
                    vertical_id: 1,
                    page: 1,
                    sortby: 'sort_visit_date',
                    order: 'DESC',
                    by_user: '',
                    dealer: ''
                }
                props.getVisitData(1, params, true)
            } else {
                let params = {
                    from_date: fromDate,
                    to_date: toDate,
                    role_id: roleSelected ? roleSelected.id : '',
                    zone_id: zoneValue,
                    state_list: state && state.id ? [state.id] : '',
                    city_list: city && city.id ? [city.id] : '',
                    visit_type: visitType && visitType.value ? visitType.value : '',
                    visit_status: statusType && statusType.value ? statusType.value : '1',
                    units_id: '2',
                    vertical_id: 1,
                    page: 1,
                    sortby: 'sort_visit_date',
                    order: 'DESC',
                    by_user: '',
                    dealer: dealer && dealer.dealer_code ? dealer.dealer_code : ''
                }
                props.getVisitData(1, params, true)
            }
        }
    }

    const handleReset = () => {
        setZone(defaultZone)
        setFromDate(getFirstDate(new Date()))
        setToDate(new Date())
        setRole(null)
        setState(null)
        setCity([])
        setCityList([])
        setVisitType(null)
        setStatusType(null)
        setExecutive(null)
        setDealer(null)
        setExecutiveData(null)
        setDealerData(null)
        handleSearch(true)
    }

    const promiseOptions = async (inputValue, type) => {
        return new Promise(async (resolve, reject) => {
            if (inputValue.trim().length > 2) {
                resolve(filterData(inputValue, type));
            }
        });
    };

    const filterData = (inputValue, type) => {
        if (type === "dealer") {
            return dealerData.filter(option =>
            (option.label.toLowerCase().includes(inputValue.toLowerCase())
                || option.dealer_code.toString().includes(inputValue.toLowerCase())
            ));
        }
        if (type === "executive") {
            return executiveData.filter(option =>
            (option.label.toLowerCase().includes(inputValue.toLowerCase())
                || option.id.toString().includes(inputValue.toLowerCase())
            ));
        }
    }

    return (
        <div className="visit-report-filter">
            <fieldset className="rangepicker-calendra">
                <div className="material">
                    <Calendra
                        placeholder={"Date From"}
                        defaultDate={fromDate}
                        endDate={toDate}
                        handleDateChange={(e) => handleChange(e, 'from_date')}
                    />
                </div>
            </fieldset>
            <fieldset className="rangepicker-calendra">
                <div className="material">
                    <Calendra2
                        placeholder={"To Date"}
                        startDate={fromDate}
                        defaultDate={toDate}
                        endDate={new Date()}
                        handleDateChange={(e) => handleChange(e, 'to_date')}
                    />
                </div>
            </fieldset>
            {props.type === "executive" &&
                <fieldset className="single-select">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            // components={{ IndicatorSeparator:() => null }}
                            value={roleSelected}
                            onChange={(e) => handleChange(e, 'role')}
                            options={roleList}
                            placeholder="Select Role"
                            className="react-select"
                            classNamePrefix="react-select"
                        />
                    </div>
                </fieldset>
            }
            {/* <fieldset className="single-select">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            options={Zone}
                            onChange={(e) => handleChange(e, 'zone')}
                            placeholder="Zone"
                            className="react-select"
                            classNamePrefix="react-select"
                            value={Zone.filter(e => e.id === zoneValue)}
                        />
                    </div>
                </fieldset> */}
            <fieldset className="single-select">
                <div className="material">
                    <Select
                        components={{ ValueContainer: CustomValueContainer }}
                        // components={{ IndicatorSeparator:() => null }}
                        options={stateList}
                        onChange={(e) => handleChange(e, 'state')}
                        placeholder="Select State"
                        className="react-select"
                        classNamePrefix="react-select"
                        value={state}
                    />
                </div>
            </fieldset>

            <fieldset className="single-select">
                <div className="material">
                    <Select
                        components={{ ValueContainer: CustomValueContainer }}
                        // components={{ IndicatorSeparator: () => null }}
                        options={cityList}
                        onChange={(e) => handleChange(e, 'city')}
                        placeholder="Select City"
                        className="react-select"
                        classNamePrefix="react-select"
                        value={city}
                    />
                </div>
            </fieldset>

            {/* <fieldset className="multiselect-dropDown col-md-4">
                    <div className={city?.length && cityList?.length ? "material":"city_color material"}>
                        <ReactMultiSelectCheckboxes
                            isMulti
                            isDisabled={state && state.id ? false : true}
                            options={cityList?.length > 0 ? [{ label: "Select All", value: "*" }, ...cityList] : cityList}
                            placeholderButtonLabel="City"
                            iconAfter="false"
                            getDropdownButtonLabel={getDropdownButtonLabel}
                            value={city?.length && cityList?.length && city?.length === cityList?.length ? [{ label: "Select All", value: "*" }, ...cityList] : city}
                            onChange={handleChangeMultiSel.bind(this, "city")}
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ id }) => id}
                        />
                    </div>
                </fieldset> */}
            {props.type === "executive" &&
                <fieldset className="single-select">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            // components={{ IndicatorSeparator: () => null }}
                            value={visitType}
                            onChange={(e) => handleChange(e, 'visit_type')}
                            options={VisitTypeList}
                            placeholder="Visit Type"
                            className="react-select"
                            classNamePrefix="react-select"
                        />
                    </div>
                </fieldset>
            }

            <fieldset className="single-select">
                <div className="material">
                    <Select
                        components={{ ValueContainer: CustomValueContainer }}
                        // components={{ IndicatorSeparator: () => null }}
                        value={statusType}
                        onChange={(e) => handleChange(e, 'status')}
                        options={statusOptions}
                        placeholder="Status"
                        className="react-select"
                        classNamePrefix="react-select"
                    />
                </div>
            </fieldset>
            <fieldset className="single-select">
                <div className="material visit_data_executive_dealer">
                    {props.type === "dealer" ?
                        <AsyncSelect
                            components={{ ValueContainer: CustomValueContainer }}
                            onChange={(e) => handleChange(e, 'dealer')}
                            value={dealer}
                            loadOptions={e => promiseOptions(e, 'dealer')}
                            placeholder={"Select Dealer"}
                            className="react-select"
                            classNamePrefix="react-select"
                        /> : <AsyncSelect
                            isDisabled={(city && city.id) || (roleSelected && roleSelected.id) ? false : true}
                            components={{ ValueContainer: CustomValueContainer }}
                            onChange={(e) => handleChange(e, 'executive')}
                            value={executive}
                            loadOptions={e => promiseOptions(e, 'executive')}
                            placeholder={"Select Executive"}
                            className="react-select"
                            classNamePrefix="react-select"
                        />}
                    {(props.type === "dealer" || (city && city.id) || (roleSelected && roleSelected.id)) &&
                        <span className="message show">Type Atleast 3 Character</span>
                    }
                </div>
            </fieldset>
            <div className="btn-submit-reset">
                <button type="submit" className="btn-primary" onClick={() => { handleSearch(false) }}>Search</button>
                <button className="btn-reset" onClick={() => handleReset()}>Reset</button>
            </div>
        </div>
    )
    // }
}
export default VisitReportTopFilter;