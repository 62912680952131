import React from "react";

const AgentHierarchyDetails = (props) => {
    const { agentHierarchy } = props;

    if (!agentHierarchy || agentHierarchy.length === 0) {
        return (
            <div className="reporting-line-detail">
                <div className="modal-header">
                    <h2>ECA Agent Hierarchy</h2>
                    <span>{"User Details"}</span>
                </div>
                <div className="modal-body">
                    <p>Loading agent hierarchy...</p>
                </div>
            </div>
        );
    }

    return (
        <div className="reporting-line-detail">
            <div className="modal-header">
                <h2>ECA Agent Hierarchy</h2>
                <span>{"User Details"}</span>
            </div>
            <div className="modal-body">
                {agentHierarchy ? agentHierarchy.map((item, idx) => (
                    <React.Fragment key={idx}>
                        <>
                            <div className="detail">
                                <div>{`L${item.level}`}</div>
                                <div>{`${item?.name} (${item.user_id})` || "-"}</div>
                            </div>
                        </>
                    </React.Fragment>
                )) : null}
            </div>
        </div>
    );
};

export default AgentHierarchyDetails;
