import React, { useEffect, useState } from 'react'
import { Route, NavLink, useNavigate } from 'react-router-dom';
import AddRoleDetailsComponent from './AddRoleDetailsComponent';
import AssignRights from './AssignRights';
import RolePreviewConfirm from './RolePreviewConfirm';
import { MasterService } from '../../services';
import { toast } from 'react-toastify';
import { STATUS } from '../../config/constant';
import Loader from "../common/loader";


const AddRole = (props) => {
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([])
    const [roleDetails, setRoleDetails] = useState({})
    const [loading, setLoading] = useState(false)
    const [activePageId, setActivePageId] = useState('')
    const [formFillStatusArr, setFormFillStatusList] = useState([]);
    let [roleList, setRoleList] = useState([])
    const historyLead = useNavigate();


    useEffect(() => {
        getCityList();
        getStateList();
        getRoleList();
        checkValidPath();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const urlPrefix = "/add-role"
    const menuList = [
        {
            id: 1,
            index: 1,
            sub_menu: "Role Details",
            sub_menu_url: "role_details_info",
        }, {
            id: 2,
            index: 2,
            sub_menu: "Assign Rights",
            sub_menu_url: "assign_rights",
        }, {
            id: 3,
            index: 3,
            sub_menu: "Role Preview Confirm",
            sub_menu_url: "role_confirm"
        }
    ]

    const handleSave = async (element, name) => {
        let temp_role_details = { ...roleDetails }
        temp_role_details[name] = element
        setRoleDetails(temp_role_details)
        let index = menuList.findIndex(x => x.sub_menu_url === name);
        let next_element = menuList[index + 1]
        let current_element = menuList[index]
        setActivePageId(next_element.id)
        let tempformFillStatusArr = formFillStatusArr
        if (!tempformFillStatusArr.includes(current_element.id)) {
            tempformFillStatusArr.push(current_element.id)
        }
        setFormFillStatusList(tempformFillStatusArr)
    }

    const handleMenuClick = (sub_menu, index, e) => {
        const linkDisabled = (activePageId > sub_menu.id) ? false : true;
        if (linkDisabled)
            e.preventDefault()

        else {
            setActivePageId(sub_menu.id)
            let tempformFillStatusArr = formFillStatusArr
            if (!tempformFillStatusArr.includes(sub_menu.id)) {
                tempformFillStatusArr.push(sub_menu.id)
                setFormFillStatusList(tempformFillStatusArr)
            }
        }
    }

    const checkValidPath = () => {
        if (Object.keys(roleDetails).length === 0) {
            historyLead('/add-role/role_details_info',  { replace: true });
            handleReset()
        }
    }

    const handleReset = () => {
        setActivePageId('')
        setFormFillStatusList([])
    }

    const getStateList = async () => {
        MasterService.post('/sfa/user/state_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var state_list = response.data.data.map(elm => ({ id: elm.id, label: elm.state_name, value: elm.id }));
                    setStateList(state_list);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
            });
    }

    const getCityList = async (state_ids) => {
        MasterService.post('/sfa/user/city_list', { state_ids })
            .then(function (response) {
                if (response.data.status === 200) {
                    var city_list = response.data.data.map(elm => ({ id: elm.id, label: elm.city_name, value: elm.id, state_id: elm.state_id }));
                    setCityList(city_list);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
            });
    }

    const getRoleList = async () => {
        MasterService.post('/sfa/user/role_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    let role_list = response.data.data;
                    role_list = role_list.map(elm => ({ id: elm.id, label: elm.name, value: elm.name }));
                    setRoleList(role_list)
                }
            })
            .catch(function (response) {
            });
    }

    const handleCreateRole = () => {
        setLoading(true);
        let city_data = cityList.filter((e) => roleDetails.role_details_info.city.includes(e.id))
        let state_data = city_data.map(({ state_id }) => state_id)
        state_data = state_data.filter((item, i, ar) => ar.indexOf(item) === i);

        let feature_access = roleDetails.assign_rights.feature_access
            .filter(item => item.is_enabled)
            .map(item => item.id)
        let params = {
            business_vertical: roleDetails.role_details_info.vertical,
            business_unit: roleDetails.role_details_info.business_unit,
            role_name: roleDetails.role_details_info.role_name,
            role_description: roleDetails.role_details_info.role_description,
            role_status: roleDetails.role_details_info.status === 'active' ? "1" : "0",
            digital_consultant: roleDetails.role_details_info.digital_consultant ? "1" : "0",
            zone_id: roleDetails.role_details_info.zone_id,
            state: state_data,
            city: city_data,
            reporting_to: roleDetails.assign_rights.role,
            reporting_from: roleDetails.assign_rights.reportee,
            feature_access: feature_access
        }
        MasterService.post('/sfa/role/create_role', params)
            .then((response) => {
                setLoading(false);
                if (response.data.status === 200) {
                    if (response.data.message === "FAILED") {
                        if (response.data.data && Object.keys(response.data.data).length > 0) {
                            for (const key in response.data.data) {
                                if (Object.hasOwnProperty.call(response.data.data, key)) {
                                    const element = response.data.data[key];
                                    toast.error(element.error_message);
                                }
                            }
                        }
                    } else {
                        toast.success("Role Added Successfully");
                        historyLead('/role-management');
                    }
                } else {
                    if (response.data.message !== "") {
                        toast.error(response.data.message)
                    } else if (response.data.error && response.data.error.length > 0) {
                        response.data.error.forEach(msg => {
                            toast.error(msg)
                        })
                    }
                }
            })
    }

    const renderSwitch = (param, index) => {
        switch (param.sub_menu) {
            case 'Role Details':
                return <Route exact path={urlPrefix + '/role_details_info'}>
                    <AddRoleDetailsComponent
                        status={STATUS}
                        roleDetails={roleDetails}
                        handleSave={handleSave}
                        cityList={cityList}
                        stateList={stateList}
                    />
                </Route>
            case 'Assign Rights':
                return <Route exact path={urlPrefix + '/assign_rights'}>
                    <AssignRights
                        roleDetails={roleDetails}
                        handleSave={handleSave}
                        roleList={roleList}
                    />
                </Route>
            case 'Role Preview Confirm':
                return <Route exact path={urlPrefix + '/role_confirm'}>
                    <RolePreviewConfirm
                        stateList={stateList}
                        cityList={cityList}
                        roleDetails={roleDetails}
                        handleCreateRole={handleCreateRole}
                        roleList={roleList}
                    />
                </Route>
            default:
        }
    }

    return (<React.Fragment>
        {loading ? <Loader /> : null}
        <div className='add-user-outer'>
            <div className="add-user-form-sec">
                <div className="menu-tab-left-panel">
                    <div className="add-user-left-panel nav">
                        <ul>
                            {
                                menuList.length !== 0 && menuList.map((menu, index) => {
                                    return <li key={index}>
                                        <NavLink
                                            to={urlPrefix + '/' + menu.sub_menu_url}
                                            className={(formFillStatusArr && formFillStatusArr.includes(menu.id))
                                                ? 'completed' : (activePageId === menu.id) ? 'active'
                                                    : ''}
                                            aria-current="page"
                                            exact
                                            onClick={handleMenuClick.bind(this, menu, index)}
                                        >
                                            <span className='img-type'></span>
                                            {menu.sub_menu}
                                        </NavLink>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className="route-files">
                    {
                        menuList.length !== 0 && menuList.map((menu, index) => {
                            return <div key={index}>
                                {renderSwitch(menu)}
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    </React.Fragment>)
}

export default AddRole;