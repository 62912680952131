import React, { useEffect, useRef, useState, useMemo } from "react";
import Select, { components } from "react-select";
import InputField from "../common/Input";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { MasterService } from "../../services";
import Loader from "../common/loader";
import DateFormat from "dateformat";
import { Role, MONTH_NAMES, TARGET_UPLOAD_SAMPLE } from "../../config/constant";
import ReactMultiSelectCheckboxes from "../common/ReactMultiSelectCheckboxes";
import TargetFileUpload from "./TargetFileUpload";

const { ValueContainer, Placeholder } = components;

const SalesTarget = () => {
    let csvLink = useRef();

    const csvData = useMemo(() => TARGET_UPLOAD_SAMPLE, []);
    const file = useMemo(() => null, []);

    const [roleList, setRoleList] = useState([]);
    const [businessLineList, setBusinessLineList] = useState([]);
    const [targetUploadHistoryPopup, setTargetUploadHistoryPopup] = useState(false);
    const [targetUploadHistory, setTargetUploadHistory] = useState([]);
    const [targetList, setTargetList] = useState([]);
    const [targetListCount, setTargetListCount] = useState(0);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getTargetList();
        getRoleList();
        getBusinessLineList();
    }, []);

    const getTargetUploadHistory = async () => {
        setLoading(true);
        try {
            const response = await MasterService.post("/sfa/user/target_upload_history", {});
            if (response?.data?.status === 200) {
                let responseData = response.data.data;
                setTargetUploadHistory(responseData);
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    };

    const getTargetList = async (params = {}) => {
        setLoading(true);
        try {
            const response = await MasterService.post("/sfa/user/target_list", params);
            if (response?.data?.status === 200) {
                let responseData = response.data.data;
                setTargetListCount(responseData?.length || 0);
                setTargetList(responseData);
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    };

    const getRoleList = async () => {
        setLoading(true);
        try {
            let response = await MasterService.post("/sfa/user/role_list", {});
            if (response?.data?.status === 200) {
                let updatedRoleList = response.data.data
                    .filter((item) => [Role["BM"], Role["AM"], Role["ZM"], Role["RBH"]].includes(item.id))
                    .map((item) => {
                        return {
                            id: item.id,
                            label: item.name,
                        };
                    });
                setRoleList(updatedRoleList);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    };

    const getBusinessLineList = async () => {
        setLoading(true);
        try {
            let response = await MasterService.post("/sfa/user/business_line_list", {});
            if (response?.data?.status === 200) {
                let responseData = response.data.data
                    .filter((item) => ["UCF", "UCRF"].includes(item.business_line_id))
                    .map((item) => {
                        return {
                            id: item.business_line_id,
                            label: item.business_line,
                        };
                    });
                setBusinessLineList(responseData);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleChangeMultiSelect = (fieldName, fieldObj, event) => {
        let currVal = [];
        let tempFilters = { ...filters };
        let tempErrors = { ...errors };
        if (event.action === "select-option" && event.option.value === "*") {
            if (fieldName === "role_ids") {
                currVal = roleList;
            }
        } else if (event.action === "deselect-option" && event.option.value === "*") {
            currVal = [];
        } else if (event.action === "deselect-option" && event.option.value !== "*") {
            currVal = fieldObj.filter((o) => o.value !== "*");
        } else if (fieldName === "role_ids" && fieldObj.length === roleList.length) {
            currVal = roleList;
        } else {
            currVal = fieldObj;
        }
        tempFilters[fieldName] = currVal;
        tempErrors[fieldName] = "";
        setErrors(tempErrors);
        setFilters(tempFilters);
    };

    const handleChange = (event, name) => {
        let tempFilters = { ...filters };
        if (name === "search_text") {
            tempFilters[name] = event.target.value;
            getTargetList(tempFilters);
        } else if (["business_line", "month_id"].includes(name)) {
            tempFilters[name] = event.id;
        }
        setFilters(tempFilters);
    };

    const hideTargetUploadHistoryPopup = () => {
        getTargetList();
        setTargetUploadHistoryPopup(false);
    };

    const showTargetUploadHistoryPopup = () => {
        setTargetUploadHistoryPopup(true);
        getTargetUploadHistory();
    };

    const downloadData = () => {
        csvLink.current.link.click();
    };

    const resetFilter = () => {
        setFilters({});
        getTargetList({});
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        getTargetList(filters);
    };

    return (
        <React.Fragment>
            {loading ? <Loader /> : null}

            <div className="container-fluid">
                <div className="top-heading d-flex">
                    <h1>Sales Target</h1>
                </div>
                <div className="loan-filter-bg">
                    <div className="top-filter-bg">
                        <div className="lead-list-filter">
                            <fieldset className="single-select">
                                <div className="material">
                                    <Select
                                        components={{ ValueContainer: CustomValueContainer }}
                                        id="month_id"
                                        name="month_id"
                                        onChange={(e) => {
                                            handleChange(e, "month_id");
                                        }}
                                        options={MONTH_NAMES}
                                        value={MONTH_NAMES.filter((item) => filters.month_id === item.id)}
                                        placeholder="Month"
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        getOptionValue={({ id }) => id}
                                        getOptionLabel={({ label }) => label}
                                    />
                                </div>
                            </fieldset>

                            <fieldset className="single-select">
                                <div className="material">
                                    <Select
                                        components={{ ValueContainer: CustomValueContainer }}
                                        id="business_line"
                                        name="business_line"
                                        onChange={(e) => {
                                            handleChange(e, "business_line");
                                        }}
                                        options={businessLineList}
                                        value={businessLineList.filter((item) => filters.business_line === item.id)}
                                        placeholder="Business Line"
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        getOptionValue={({ id }) => id}
                                        getOptionLabel={({ label }) => label}
                                    />
                                </div>
                            </fieldset>

                            <fieldset className="multiselect-dropDown">
                                <div className="material">
                                    <ReactMultiSelectCheckboxes
                                        isMulti
                                        options={[{ label: "Select All", value: "*" }, ...roleList]}
                                        placeholderButtonLabel="Role"
                                        iconAfter="false"
                                        value={
                                            filters?.role_ids?.length === roleList.length
                                                ? [{ label: "Select All", value: "*" }, ...roleList]
                                                : filters?.role_ids
                                                ? filters.role_ids
                                                : []
                                        }
                                        onChange={handleChangeMultiSelect.bind(this, "role_ids")}
                                        getOptionLabel={({ label }) => label}
                                        getOptionValue={({ id }) => id}
                                    />
                                </div>
                                {errors?.role_ids ? <span className="error show">{errors.role_ids}</span> : ""}
                            </fieldset>

                            <fieldset className="admin-email">
                                <div className="material">
                                    <InputField
                                        id="search_text"
                                        type="text"
                                        placeholder="Search By SFA ID or Name"
                                        name="search_text"
                                        autoComplete="off"
                                        value={filters?.search_text ? filters.search_text : ""}
                                        label="Search By SFA ID or Name"
                                        onChange={(e) => {
                                            handleChange(e, "search_text");
                                        }}
                                    />
                                </div>
                            </fieldset>

                            <div className="btn-submit-reset m-sm-l">
                                <button
                                    onClick={(e) => {
                                        handleSubmit(e);
                                    }}
                                    type="button"
                                    className="btn-primary"
                                >
                                    Search
                                </button>
                                <button
                                    onClick={() => {
                                        resetFilter();
                                    }}
                                    className="btn-reset"
                                >
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="user-management-detail-tab-outer">
                        <div className="top-heading d-flex">
                            <div className="top-heading d-flex m-md-l">
                                <h2>{`User Level Targets (${targetListCount})`}</h2>
                            </div>
                            <button
                                onClick={() => {
                                    showTargetUploadHistoryPopup();
                                }}
                                className="btn-primary m-md-r"
                            >
                                {" "}
                                + Upload Target
                            </button>
                        </div>

                        <div className="admin-table user-managemnt-detail-table data-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>SFA ID</th>
                                        <th>User Name</th>
                                        <th>Month</th>
                                        <th>Business Line</th>
                                        <th>Role</th>
                                        <th>Overall Target</th>
                                        <th>NIM Target</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {targetList?.length ? (
                                        targetList.map((item, idx) => (
                                            <tr key={idx}>
                                                <td>{item.id}</td>
                                                <td>{item.name}</td>
                                                <td>{item.created_date}</td>
                                                <td>{item.business_line}</td>
                                                <td>{item.role}</td>
                                                <td>{item.overall_target}</td>
                                                <td>{item.nim_target}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={5} style={{ textAlign: "left" }}>
                                                No records found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="bulk-upload-popup model-popup-outer">
                        <Modal show={targetUploadHistoryPopup} handleClose={hideTargetUploadHistoryPopup}>
                            <div style={{ display: "flex" }}>
                                <div className="sales-target-upload">
                                    <div className="modal-header">
                                        <h2>Bulk Upload</h2>
                                    </div>
                                    <div className="modal-body">
                                        <TargetFileUpload getBulkUploadHistory={() => getTargetUploadHistory()} file={file} />
                                        <div className="download-txt">
                                            Download input format <span onClick={() => downloadData()}>Click Here</span>
                                        </div>
                                        <CSVLink data={csvData} filename={`sample_format.csv`} data-interception="off" ref={csvLink}></CSVLink>
                                    </div>
                                </div>
                                <div style={{ width: "700px" }} className="m-lg-l">
                                    <div className="modal-header">
                                        <h2>Upload History</h2>
                                    </div>
                                    <div className="modal-body">
                                        <div className="user-managemnt-detail-table data-table">
                                            <table style={{ width: "auto" }}>
                                                <thead>
                                                    <tr>
                                                        <th>Date & Time</th>
                                                        <th>Uploaded By</th>
                                                        <th>Records</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {targetUploadHistory &&
                                                        targetUploadHistory.length > 0 &&
                                                        targetUploadHistory.map((element, index) => (
                                                            <tr key={index}>
                                                                <td>{DateFormat(element.created_date, "dd mmm yy, hh:MM TT")}</td>
                                                                <td className="show_ellipsis">{element.display_name}</td>
                                                                <td>
                                                                    <ul className="bulk_records">
                                                                        <li>{element.success_records + " -"}</li>
                                                                        <li className="failed_records">{element.failed_records}</li>
                                                                        <li>{"- " + element.total_records}</li>
                                                                    </ul>
                                                                </td>
                                                                <td>
                                                                    {element.file_url ? (
                                                                        <ul className="action-btn">
                                                                            <li>
                                                                                <a href={element.file_url} rel="noreferrer" target="_blank">
                                                                                    <i className="ic-save_alt"></i>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
        </ValueContainer>
    );
};

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return (
        <div className={showHideClassName}>
            <section className="modal-main">
                {children}
                <button onClick={handleClose} className="close_icn">
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};

export default SalesTarget;
