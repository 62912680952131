import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.scss';
import Header from "./view/common/Header"
import Footer from './view/common/Footer'
import NavigationRoutes from "./routes/routes";
// import { renderRoutes } from "react-router-config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


class App extends Component {
  render() {
    return (
      <>
        <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={5000} />
        <div>
          <Header />
          <Routes>
            {NavigationRoutes.map((route) => (
              <Route key={route.path} path={route.path} element={<route.component />} />
            ))}
          </Routes>
          <Footer />
        </div>
      </>
    );
  }
}

export default App;

