import React from "react";
import { NavLink } from 'react-router-dom';
//import { Accordion, Card, Button, DropdownButton, Dropdown } from "react-bootstrap";
import Notifaction from './Notification'
import logo_img from "../../webroot/images/logo.svg";
import logo_img_fav from "../../webroot/images/logo-fav.svg";
import logo_img_tri from "../../webroot/images/logo-tri-header.svg";
import secureStorage from '../../config/encrypt'; 
import WithRights from "./WithRights";
import {TENANT } from '../../config/constant';
    const Navigation = (props) => {
        const changeLanguage = (lang) => {      
                secureStorage.setItem('lang', lang);
        }       
        let language =  secureStorage.getItem("lang") === "id" ? "ID" : "EN";
        let  logged_data = localStorage.getItem("logged_data") ? JSON.parse(localStorage.getItem("logged_data")) : {}
        let display_name=(logged_data.display_name)?logged_data.display_name:'';
        return (
            <div className="container-fluid" >
                {logged_data.display_name &&
                <div className="header-main">
                    <div className="logo">
                         <img src={TENANT=="id" ? logo_img :(TENANT=="tri-id")? logo_img_tri : logo_img_fav} className="" alt='SFA' /> 
                    </div>
                    <div className="right-panel-naviganion menupanel">
                        <div className="nav">
                            <ul>                                
                                <WithRights rightKey="user_management">
                                <li><NavLink to="/">User Management</NavLink></li>
                                </WithRights>
                                
                                <WithRights rightKey="map_dealer_executive">
                                <li><NavLink to="/map-dealer">Map Dealer/Executive</NavLink></li>
                                </WithRights>
                                <WithRights rightKey="dealer_executive_mapping">
                                <li><NavLink to="/dealer-user-listing">Dealer/Executive Mapping</NavLink></li>
                                </WithRights>
                                <WithRights rightKey="reports">
                                <li className="dropdownmenu">
                                    <NavLink to="#">                                       
                                        <div className="username">
                                            <span>Reports</span>                                            
                                        </div>
                                    </NavLink>
                                    <ul className="submenu">
                                        <WithRights rightKey="visit_report">
                                        <li><NavLink to="/view-report">Visit Reports</NavLink></li>
                                        </WithRights>
                                        <WithRights rightKey="no_visit_report">
                                        <li><NavLink to="/no-view-report">No Visit Reports</NavLink></li>
                                        </WithRights>
                                    </ul> 
                                </li>
                                </WithRights>
     <WithRights rightKey="access_more_dropdown">
                                <li className="dropdownmenu">
                                    <NavLink to="#">                                       
                                        <div className="username">
                                            <span>More</span>                                            
                                        </div>
                                    </NavLink>
                                    <ul className="submenu">
                                        <WithRights rightKey="role_management">
                                        <li><NavLink to="/role-management">Role Management</NavLink></li>
                                        </WithRights>
                                        <WithRights rightKey="journey_config">
                                        {(TENANT =="id") && <li><NavLink to="/progression-config"> Journey Config</NavLink> </li>}                                                                                
                                        </WithRights>
                                        <WithRights rightKey="journey_dashboard">
                                        {(TENANT =="id") && <li><NavLink to="/progression-dashboard">Journey Dashboard</NavLink> </li>}                                                                                
                                        </WithRights>
                                        <WithRights rightKey="sales_support_user_management">
                                        <li><NavLink to="/admin-user-management">Sales support user management</NavLink> </li>                                                                                
                                        </WithRights>
                                        <WithRights rightKey="sales_target">
                                        {(TENANT =="id") && <li><NavLink to="/sales-target">Sales Target</NavLink> </li>  }                                                                              
                                        </WithRights>
                                    </ul> 
                                </li>
                                </WithRights>
                            </ul>                            
                        </div>
                        <WithRights rightKey="custom_notification">
                        <li className="notification-btn">
                            <NavLink to="/custom-notification">
                                <Notifaction />
                            </NavLink>
                        </li>
                        </WithRights>

                        <div className="user-detail ">
                            <ul>

                                <li className="dropdownmenu">
                                    <NavLink to="#">
                                        <span className="userarc"> 
                                        T                                          
                                        </span>
                                        <div className="username">
                                            <span className="nowrap">{logged_data.role_display_name}</span>
                                            <span className="user-degintion">{display_name}</span>
                                        </div>
                                    </NavLink>
                                    <ul className="submenu">
                                        <li><NavLink to="/logout">Logout</NavLink> </li> 
                                        {(TENANT=="id" || TENANT=="tri-id") && <li><NavLink to="/office_address">Office Address</NavLink> </li>}
                                        <li><NavLink to="/reset_max_attempts">Reset Max Attempts</NavLink> </li>                                                                                
                                    </ul> 
                                </li>

                            </ul>
                        </div>

                        <div className="language">
                            <ul>
                                <li className="dropdownmenu">
                                    <NavLink to="#">
                                        {language}
                                    </NavLink>
                                    <ul className="submenu lang-menu">
                                        <li><NavLink to="#" onClick={() => changeLanguage('en')}>EN</NavLink></li>
                                        <li><NavLink to="#" onClick={() => changeLanguage('id')}>ID</NavLink></li> 
                                    </ul>
                                </li>

                            </ul>
                        </div>
                    </div>

                </div>
    }
            </div >
        );
} 
export default Navigation;