import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import NoImage from "../../webroot/images/noimage.svg";

const IfSimpleSlider = ({ doc, image_width, rotated_angle, activeIndex, errors, ConfigList, changeSlide }) => {
    const slider = useRef(null);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(current),
    };

    useEffect(() => {
        if (slider.current && typeof slider.current.slickGoTo === "function") {
            slider.current.slickGoTo(activeIndex);
        }
    }, [activeIndex]);

    const handleSlideChange = (cur) => {
        if (cur !== activeIndex) changeSlide(cur);
    };

    const renderSlides = () => {
        if (!ConfigList?.if_document?.length || !doc?.length) {
            return (
                <div>
                    <label>No Document</label>
                </div>
            );
        }

        return doc.map((data, index) => {
            const documentConfig = ConfigList.if_document.find((e) => parseInt(e.id) === parseInt(data.document_id));
            const docTitle = documentConfig?.doc_title || "";
            const errorMessage = errors && errors[docTitle];
            return (
                <div className="slider_image_text" key={index}>
                    <h2>{docTitle}</h2>
                    <embed
                        src={data?.doc_url || NoImage}
                        className=""
                        alt="No Document found"
                        style={{ width: `${image_width}px`, transform: `rotate(${rotated_angle}deg)` }}
                    />
                    {errorMessage && <span className="error show">{errorMessage}</span>}
                </div>
            );
        });
    };

    return (
        <Slider ref={slider} {...settings}>
            {renderSlides()}
        </Slider>
    );
};

export default IfSimpleSlider;
