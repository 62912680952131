import React, { useEffect, useState } from 'react'
import Select, { components } from 'react-select';
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import ReactMultiSelectCheckboxes from "../common/ReactMultiSelectCheckboxes"
import { useNavigate } from 'react-router-dom';
import { MasterService } from '../../services';

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const SendMessage = [
    { value: 'All', label: 'All' },
    { value: 'Agent', label: 'Agent' },
    { value: 'BRO', label: 'BRO' },
    { value: 'ARO', label: 'ARO' },
    { value: 'ARCO', label: 'ARCO' },
    { value: 'BM', label: 'BM' },
    { value: 'AM', label: 'AM' },
];

const ReceiveMessage = [
    { value: 'All', label: 'All' },
    { value: 'Agent', label: 'Agent' },
    { value: 'BRO', label: 'BRO' },
    { value: 'ARO', label: 'ARO' },
    { value: 'ARCO', label: 'ARCO' },
    { value: 'BM', label: 'BM' },
    { value: 'AM', label: 'AM' },
];

const AssignRights = (props) => {
    const { roleList, roleDetails } = props;
    const [reporteeList, setReporteeList] = useState([])
    const [roleData, setRoleData] = useState([])
    const [reporteeData, setReporteeData] = useState([])
    const [assignRights, setAssignRights] = useState({})
    const [roleRights, setRoleRights] = useState([])
    const [featureAccess, setFeatureAccess] = useState(new Set())
    const [errors, setErrors] = useState({})
    // const [isEdit, setIsEdit] = useState(roleDetails && roleDetails.role_id && roleDetails.role_id !== "" ? true : false);//removed warning
    const [isEdit] = useState(roleDetails && roleDetails.role_id && roleDetails.role_id !== "" ? true : false);
    // const [loading, setLoading] = useState(false)//removed warning

    const historyLead = useNavigate();

    useEffect(() => {
        getAllRoleRights();
        if (roleDetails.assign_rights && roleDetails.role_id) {
            setAssignRights(roleDetails.assign_rights);
            let tempRoleData = roleList.filter(item => roleDetails.assign_rights.reportee.includes(item.id))
            setRoleData(tempRoleData)
            let tempReporteeData = roleList.filter(item => roleDetails.assign_rights.role.includes(item.id));
            setReporteeData(tempReporteeData);
            if(roleDetails.role_id){
                getReportingRole(roleDetails.role_id, true, assignRights)
            }
            let tempSet = new Set();
            if (roleDetails.assign_rights.feature_access) {
                roleDetails.assign_rights.feature_access.forEach(item => {
                    if (item.is_enabled === true) {
                        tempSet.add(item.id);
                    }
                })
            }
            setFeatureAccess(tempSet);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleDetails])

    useEffect(() => {
        if (roleData.length === 0) {
            setReporteeList([])
        }
        if (!roleDetails.role_id && roleDetails.assign_rights) {
            let tempRoleData = roleList.filter(item => roleDetails.assign_rights.reportee.includes(item.id))
            setRoleData(tempRoleData)
            let tempReporteeData = roleList.filter(item => roleDetails.assign_rights.role.includes(item.id));
            setReporteeData(tempReporteeData);
            if (roleDetails.role_id) {
                getReportingRole(roleDetails.role_id, true, assignRights)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleData])

    const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
        let text = placeholderButtonLabel.split("*")[0]
        if (value && value?.length !== 0 && value.some((o) => o.value === "*")) {
            return `All ${text} selected`;
        } else if (value && value.length === 0) {
            return `${placeholderButtonLabel}`;
        } else if (value && value.length) {
            if (text === "Role" && value?.length === roleList.length) {
                return `All ${text} selected`;
            } else if (text === "Reportee" && value?.length === reporteeList.length) {
                return `All ${text} selected`;
            } else {
                return `${value.length} ${text} selected`;
            }
        } else {
            return `${placeholderButtonLabel}`;
        }
    }

    const handleChangeMultiSel = (fieldName, fieldObj, event) => {
        let currVal = [];
        let selectedVal = [];
        let tempAssignRights = { ...assignRights }
        if (event.action === 'select-option' && event.option.value === "*") {
            selectedVal = fieldName === 'role'
                ? [{ label: "Select All", value: "*" }, ...roleList]
                : [{ label: "Select All", value: "*" }, ...reporteeList];
            currVal = fieldName === 'role' ? roleList.map(v => v.id) : reporteeList.map(v => v.id);
        } else if (event.action === 'deselect-option' && event.option.value === "*") {
            selectedVal = []
        } else if (event.action === 'deselect-option' && event.option.value !== "*") {
            selectedVal = fieldObj.filter((o) => o.value !== "*");
            currVal = selectedVal.map(v => v.id);
        } else if (fieldName === 'role' && fieldObj.length === roleList.length) {
            currVal = roleList.map(v => v.id);
            selectedVal = [{ label: "Select All", value: "*" }, ...roleList]
        } else if (fieldName === 'reportee' && fieldObj.length === reporteeList.length) {
            currVal = reporteeList.map(v => v.id);
            selectedVal = [{ label: "Select All", value: "*" }, ...reporteeList];
        } else {
            currVal = fieldObj.map(v => v.id);
            selectedVal = fieldObj
        }
        tempAssignRights[fieldName] = currVal
        if (fieldName === 'role') {
            errors['role'] = '';
            currVal.forEach(i => getReportingRole(i))
            setRoleData(selectedVal);
        }

        if (fieldName === 'reportee') {
            errors['reportee'] = '';
            setReporteeData(selectedVal);
        }
        setAssignRights(tempAssignRights);
    }

    const getReportingRole = async (role_id, setData, assignRights) => {
        MasterService.post('/sfa/user/reporting_role_list', { role_id })
            .then(function (response) {
                if (response.data.status === 200) {
                    let reporting_role_list = response.data.data.map(elm => ({ id: elm.id, label: elm.name, value: elm.name }));
                    setReporteeList(prev => {
                        if (prev.length > 1) {
                            return prev.filter(obj1 => {
                                return reporting_role_list.some(obj2 => {
                                    return obj1.id === obj2.id && obj1.label === obj2.label && obj1.value === obj2.value;
                                })
                            })
                        } else {
                            return reporting_role_list;
                        }
                    });
                    /* setReporteeList(prev => {
                        let roleSet = new Set();
                        prev.map(i => roleSet.add(JSON.stringify(i)));
                        reporting_role_list.map(i => roleSet.add(JSON.stringify(i)))
                        let uniqueRoles = [];
                        roleSet.forEach(i => uniqueRoles.push(JSON.parse(i)));
                        return Array.from(uniqueRoles);
                    }); */
                }
            })
            .catch(function (response) {
            });
    }

    const getAllRoleRights = async () => {
        // setLoading(true);//removed warning
        MasterService.post('sfa/role/get_all_rights', {})
            .then(response => {
                if (response.status === 200) {
                    setRoleRights(response.data.data);
                    // setLoading(false);//removed warning
                } else {
                    // setLoading(false);//removed warning
                }
            }).catch(err => {
                // setLoading(false);//removed warning
            })
    }


    const isValid = () => {
        let tempAssignRights = { ...assignRights }
        let tempErrors = { ...errors }
        let isFormVaild = true;

        if (!tempAssignRights['role']) {
            isFormVaild = false;
            tempErrors['role'] = 'Role is required'
        }
        if (!tempAssignRights['reportee']) {
            isFormVaild = false
            tempErrors['reportee'] = 'Reportee is required'
        }
        setErrors(tempErrors)
        return isFormVaild;
    }

    const updateRights = (newSet) => {
        let tempAssignRights = { ...assignRights };
        let newList = roleRights.map(item => {
            if (newSet.has(item.id)) {
                return { ...item, is_enabled: true }
            } else {
                return { ...item, is_enabled: false }
            }
        })
        tempAssignRights['feature_access'] = newList;
        setAssignRights(tempAssignRights);
    }

    const handleInputChange = (id, event) => {
        if (event.target.checked) {
            setFeatureAccess(prev => {
                let newSet = new Set(Array.from(prev))
                newSet.add(id)
                updateRights(newSet);
                return newSet;
            });
        } else {
            setFeatureAccess(prev => {
                let newSet = new Set(Array.from(prev))
                newSet.delete(id)
                updateRights(newSet);
                return newSet;
            })
        }
    }

    const handleClickNext = async () => {
        if (isValid()) {
            handleClick();
        }
    }

    const handleClick = async () => {
        await props.handleSave(assignRights, 'assign_rights')
        if (isEdit) {
            historyLead(`role_confirm?id=${roleDetails.role_id}`)
        } else {
            historyLead('role_confirm')
        }
    }

    return (
        <div className='basic-detail-form p-xxl add-user-detail-filed'>
            <h2>Assign Rights</h2>

            <h3>Rights Detail</h3>

            <div className="row">
                <fieldset className="multiselect-dropDown col-md-4">
                    <div className="material">
                        {/* <ReactMultiSelectCheckboxes
                            isMulti
                            options={[{ label: "Select All", value: "*" }, ...roleList]}
                            placeholderButtonLabel="Role"
                            iconAfter="false"
                            getDropdownButtonLabel={getDropdownButtonLabel}
                            value={roleData?.length === roleList?.length
                                ? [{ label: "Select All", value: "*" }, ...roleList]
                                : roleData}
                            onChange={handleChangeMultiSel.bind(this, "role")}
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ id }) => id}
                        /> */}
                        <ReactMultiSelectCheckboxes
                            options={[{ label: "Select All", value: "*" }, ...roleList]}
                            value={roleData?.length === roleList?.length
                                ? [{ label: "Select All", value: "*" }, ...roleList]
                                : roleData}
                            onChange={handleChangeMultiSel.bind(this, "role")}
                            name="role"
                            placeholder="Role"
                            isDisabled={false}
                            isClearable={true}
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ id }) => id}
                        />
                        {(roleData && roleData.length > 0) ? <label data-label="Report of Role*" className="form-label date-label"></label> : ''}
                    </div>
                    {(errors && errors.role) ? <span className="error show absolute">{errors.role}</span> : ''}
                </fieldset>

                <fieldset className="multiselect-dropDown col-md-4">
                    <div className="material">
                        <ReactMultiSelectCheckboxes
                            isMulti
                            options={[{ label: "Select All", value: "*" }, ...reporteeList]}
                            placeholderButtonLabel="Reportee"
                            iconAfter="false"
                            getDropdownButtonLabel={getDropdownButtonLabel}
                            value={reporteeData?.length === reporteeList?.length && reporteeList.length !== 0
                                ? [{ label: "Select All", value: "*" }, ...reporteeList]
                                : reporteeData}
                            onChange={handleChangeMultiSel.bind(this, "reportee")}
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ id }) => id}
                        />
                        {(reporteeData && reporteeData.length > 0) ? <label data-label="View Reports of*" className="form-label date-label"></label> : ''}
                    </div>
                    {(errors && errors.reportee) ? <span className="error show absolute">{errors.reportee}</span> : ''}
                </fieldset>


                <fieldset className="single-select col-md-4">
                    <div className="material">
                        <Select 
                            components={{ ValueContainer: CustomValueContainer }}
                            // components={{ IndicatorSeparator:() => null }}
                            options={SendMessage}
                            placeholder="Send Message to"
                            className="react-select"
                            classNamePrefix="react-select"
                        />
                    </div>
                </fieldset>

                <fieldset className="single-select col-md-4">
                    <div className="material">
                        <Select 
                            components={{ ValueContainer: CustomValueContainer }}
                            // components={{ IndicatorSeparator:() => null }}
                            options={ReceiveMessage}
                            placeholder="Receive Message from"
                            className="react-select"
                            classNamePrefix="react-select"
                        />
                    </div>
                </fieldset>
            </div>

            <h3>Rights Detail</h3>
            <div className='row'>
                <div className='col-md-12'>
                    <ul className='right-detail-list'>
                        {
                            roleRights && roleRights.map((rights, key) => {
                                return <li key={key} className="custom-control custom-checkbox">
                                    <input
                                        id={`sli${key}`}
                                        type="checkbox"
                                        className="custom-control-input"
                                        onChange={handleInputChange.bind(null, rights.id)}
                                        checked={featureAccess.size > 0 && featureAccess.has(rights.id)}
                                    />
                                    <label htmlFor={`sli${key}`} className="custom-control-label">
                                        {rights.name}
                                    </label>
                                </li>
                            })
                        }
                    </ul>
                </div>
            </div>

            <div className="br-top t-right">
                <button className='btn-primary' onClick={handleClickNext}>
                    Save & Continue
                </button>
            </div>
        </div>
    )
}


export default AssignRights;