import React, { useEffect } from "react";
import Select, { components } from "react-select";
import { Role } from "../../config/constant";
import ReactMultiSelectCheckboxes from "../common/ReactMultiSelectCheckboxes";

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
        </ValueContainer>
    );
};

const BasicFilter = (props) => {
    useEffect(() => { }, [
        props.config,
        props.element.role_id,
        props.element.business_line,
        props.element.parameter_type,
        props.element.month,
        props.tempErrors,
    ]);

    const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
        if (value && value.length === 0) {
            return `${placeholderButtonLabel}`;
        } else if (value && value.length) {
            return `${value.length} selected`;
        } else return `${placeholderButtonLabel}`;
    };

    const handleChangeSelect = (option, key, index) => {
        if (!props.element.is_expired) {
            props.onChange(key, option, index);
        }
    };

    const handleChangeMultiSelect = (index, fieldName, fieldObj, event) => {
        let currVal = [];
        let tempFinanciers = { ...config.financier_list };
        let tempApplicationTypes = { ...config.application_type };
        if (event.action === "select-option" && event.option.value === "*") {
            if (fieldName === "financier_list") {
                currVal = [{ label: "Select All", value: "*" }, ...config.financier_list];
            }
            if (fieldName === "application_type") {
                currVal = [{ label: "Select All", value: "*" }, ...config.application_type];
            }
        } else if (event.action === "deselect-option" && event.option.value === "*") {
            currVal = [];
        } else if (event.action === "deselect-option" && event.option.value !== "*") {
            currVal = fieldObj.filter((o) => o.value !== "*");
        } else if (fieldName === "financier_list" && fieldObj.length === tempFinanciers.length) {
            currVal = [{ label: "Select All", value: "*" }, ...tempFinanciers];
        } else if (fieldName === "application_type" && fieldObj.length === tempApplicationTypes.length) {
            currVal = [{ label: "Select All", value: "*" }, ...tempApplicationTypes];
        } else {
            currVal = fieldObj;
        }
        props.onChange(fieldName, currVal, index);
    };

    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);

        return date.toLocaleString("en-US", {
            month: "long",
        });
    };
    const { tempErrors, element, index, config } = props;
    return (
        <div className="basic-filter-outer">
            <h2>Basic Details </h2>

            <div className="basic-detail-filter progression-top-filter">
                <fieldset className="single-select">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            value={
                                element.role_id && config.role.findIndex(({ value }) => parseInt(value) === element.role_id) > -1
                                    ? config.role.filter(({ value }) => parseInt(value) === element.role_id)
                                    : null
                            }
                            onChange={(e) => handleChangeSelect(e, "role_id", index)}
                            options={config.role}
                            isDisabled={element.is_expired}
                            placeholder="User Role*"
                            className="react-select"
                            classNamePrefix="react-select"
                        />
                        {tempErrors && tempErrors["user_role_" + index] ? <span className="error show">{tempErrors["user_role_" + index]}</span> : ""}
                    </div>
                </fieldset>
                <fieldset className="single-select">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            value={config.parameter_type.filter((item) => item.id === element.parameter_type)}
                            onChange={(e) => handleChangeSelect(e, "parameter_type", index)}
                            options={parseInt(element.role_id) === Role.BM ? config.parameter_type.filter((e) => e.id === "average") : config.parameter_type}
                            getOptionLabel={({ value }) => value}
                            getOptionValue={({ id }) => id}
                            placeholder="Parameter Type*"
                            className="react-select"
                            classNamePrefix="react-select"
                            isDisabled={!element.role_id ? true : false}
                        />
                        {tempErrors && tempErrors["parameter_type_" + index] ? <span className="error show">{tempErrors["parameter_type_" + index]}</span> : ""}
                    </div>
                </fieldset>

                <fieldset className="single-select">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            value={
                                element.business_line && config.business_line.findIndex(({ value }) => value === element.business_line) > -1
                                    ? config.business_line.filter(({ value }) => value === element.business_line)
                                    : null
                            }
                            onChange={(e) => handleChangeSelect(e, "business_line", index)}
                            options={config.business_line}
                            isDisabled={element.is_expired}
                            placeholder="Business Line*"
                            className="react-select"
                            classNamePrefix="react-select"
                        />
                        {tempErrors && tempErrors["business_line_" + index] ? <span className="error show">{tempErrors["business_line_" + index]}</span> : ""}
                    </div>
                </fieldset>

                <fieldset className="single-select">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            value={
                                element.month && config.month.findIndex(({ value }) => value === element.month) > -1
                                    ? config.month.filter(({ value }) => value === element.month)
                                    : element.month
                                        ? { value: element.month, label: getMonthName(element.month), year: element.year }
                                        : null
                            }
                            onChange={(e) => handleChangeSelect(e, "month", index)}
                            options={config.month}
                            isDisabled={element.is_expired}
                            placeholder="Month*"
                            className="react-select"
                            classNamePrefix="react-select"
                        />
                        {tempErrors && tempErrors["month_" + index] ? <span className="error show">{tempErrors["month_" + index]}</span> : ""}
                    </div>
                </fieldset>

                {element.role_id !== Role["BM"] && (
                    <>
                        <fieldset className="multiselect-dropDown">
                            <div className="material">
                                <ReactMultiSelectCheckboxes
                                    isMulti
                                    options={[{ label: "Select All", value: "*" }, ...config.application_type]}
                                    placeholderButtonLabel="Application Type"
                                    iconAfter="false"
                                    getDropdownButtonLabel={getDropdownButtonLabel}
                                    value={
                                        element.application_type?.length === config.application_type?.length
                                            ? [{ label: "Select All", value: "*" }, ...config.application_type]
                                            : element?.application_type?.length
                                                ? element.application_type
                                                : []
                                    }
                                    onChange={handleChangeMultiSelect.bind(this, index, "application_type")}
                                    getOptionLabel={({ label }) => label}
                                    getOptionValue={({ value }) => value}
                                />
                                {element.application_type && element.application_type.length > 0 ? (
                                    <label data-label="Application Type*" className="form-label date-label"></label>
                                ) : (
                                    ""
                                )}
                                {tempErrors && tempErrors["application_type_" + index] ? (
                                    <span className="error show">{tempErrors["application_type_" + index]}</span>
                                ) : (
                                    ""
                                )}
                            </div>
                        </fieldset>

                        <fieldset className="multiselect-dropDown">
                            <div className="material">
                                <ReactMultiSelectCheckboxes
                                    isMulti
                                    options={[{ label: "Select All", value: "*" }, ...config.financier_list]}
                                    placeholderButtonLabel="Financier List"
                                    iconAfter="false"
                                    getDropdownButtonLabel={getDropdownButtonLabel}
                                    value={
                                        element.financier_list?.length === config.financier_list?.length
                                            ? [{ label: "Select All", value: "*" }, ...config.financier_list]
                                            : element?.financier_list?.length
                                                ? element.financier_list
                                                : []
                                    }
                                    onChange={handleChangeMultiSelect.bind(this, index, "financier_list")}
                                    getOptionLabel={({ label }) => label}
                                    getOptionValue={({ value }) => value}
                                />
                                {element.financier_list && element.financier_list.length > 0 ? (
                                    <label data-label="Financier List*" className="form-label date-label"></label>
                                ) : (
                                    ""
                                )}
                                {tempErrors && tempErrors["financier_list_" + index] ? (
                                    <span className="error show">{tempErrors["financier_list_" + index]}</span>
                                ) : (
                                    ""
                                )}
                            </div>
                        </fieldset>

                        <fieldset className="single-select">
                            <div className="material">
                                <Select
                                    value={
                                        element.duration && config.duration_options.findIndex(({ value }) => value === element.duration) > -1
                                            ? config.duration_options.filter(({ value }) => value === element.duration)
                                            : null
                                    }
                                    onChange={(e) => handleChangeSelect(e, "duration", index)}
                                    options={config.duration_options}
                                    isDisabled={element.is_expired}
                                    placeholder="Duration*"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                />
                                {tempErrors && tempErrors["duration_" + index] ? (
                                    <span className="error show">{tempErrors["duration_" + index]}</span>
                                ) : (
                                    ""
                                )}
                            </div>
                        </fieldset>
                    </>
                )}
            </div>
        </div>
    );
};
export default BasicFilter;
