import secureStorage from "../../config/encrypt";

const WithRights = ({ children, rightKey, fallback = null }) => {
    const loginUserInfo = secureStorage.getItem("loginUserInfo");
    const hasRights = loginUserInfo && loginUserInfo.admin_role_rights && loginUserInfo.admin_role_rights[rightKey] === 1;

    const content = typeof children === "function" ? children(hasRights, loginUserInfo) : children;

    return hasRights ? content : fallback;
};

export default WithRights;
