import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import Loader from "../common/loader";
import { toast } from "react-toastify";
import { MasterService } from "../../services";
import { CSVLink } from "react-csv";
import NotificationFileUpload from "./NotificationFileUpload";
import ReactMultiSelectCheckboxes from "../common/ReactMultiSelectCheckboxes";
import { Role } from "../../config/constant";

const { ValueContainer, Placeholder } = components;

const notificationType = [
    {
        id: "push_notification",
        label: "Push Notification",
    },
    {
        id: "popup_banner",
        label: "Popup Banner",
    },
    {
        id: "notification_and_banner",
        label: "Notification and Banner",
    },
];

const frequencyOptions = [
    {
        id: "once",
        label: "Once",
    },
    {
        id: "every_8_hours",
        label: "Every 8 Hours",
    },
    {
        id: "daily",
        label: "Every Day",
    },
    {
        id: "every_3_days",
        label: "Every 3 Days",
    },
    {
        id: "weekly",
        label: "Every Week",
    },
    {
        id: "monthly",
        label: "Every Month",
    },
];

const CreateCustomNotification = (props) => {
    const csvLink = useRef();
    const userDownloadLink = useRef();
    const [csvData] = useState([{ id: "99999", name: "Test User", role: "54" }]);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [customNotification, setCustomNotification] = useState({});
    const [errors, setErrors] = useState({});
    const [roleList, setRoleList] = useState([]);
    const [businessLineList, setBusinessLineList] = useState([]);
    const [imagePopup, setImagePopup] = useState(false);
    const [fileUploadPopup, setFileUploadPopup] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);
    // const [isCreateDisable, setIsCreateDisable] = useState(false);
    const [notificationId, setNotificationId] = useState(0);
    const [userDownloadList, setUserDownloadList] = useState([]);
    const [startDate, endDate] = dateRange;

    let historyLead = useNavigate();
    let location = useLocation();

    useEffect(() => {
        if (location && location.state && location.state.notificationId) {
            setNotificationId(location.state.notificationId);
            getCustomNotification(location.state.notificationId);
        }
        getRoleList();
        getBusinessLineList();
    }, []);

    const getCustomNotification = async (notification_id) => {
        setLoading(true);
        try {
            let response = await MasterService.post("/sfa/user/get_custom_notification", { notification_id: notification_id });
            if (response && response.data && response.data.status === 200) {
                let responseData = response.data.data;
                let tempDateRange = [];
                if (responseData.start_duration_date && !isNaN(Date.parse(responseData.start_duration_date))) {
                    tempDateRange.push(Date.parse(responseData.start_duration_date));
                }
                if (responseData.end_duration_date && !isNaN(Date.parse(responseData.end_duration_date))) {
                    tempDateRange.push(Date.parse(responseData.end_duration_date));
                }
                /* if (responseData.is_active === "1") {
                    setIsCreateDisable(true);
                } */
                setDateRange(tempDateRange);
                setCustomNotification(responseData);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    };

    const downloadNotificationList = async (notification_id) => {
        setLoading(true);
        try {
            let response = await MasterService.post("/sfa/user/download_notification_users", { notification_id: notification_id });
            if (response && response.data && response.data.status === 200) {
                if (response.data.data && response.data.data.length) {
                    setUserDownloadList(response.data.data);
                    userDownloadLink.current.link.click();
                } else {
                    setUserDownloadList([{ ID: "", Name: "", Role: "", Remarks: ""}]);
                    userDownloadLink.current.link.click();
                }
                toast.success("Success");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    };

    const getRoleList = async () => {
        setLoading(true);
        try {
            let response = await MasterService.post("/sfa/user/role_list", {});
            if (response && response.data && response.data.status === 200) {
                let updatedRoleList = response.data.data
                    .filter((item) => item.id !== Role["Virtual Admin"])
                    .map((item) => {
                        return {
                            id: item.id,
                            label: item.name,
                        };
                    });
                setRoleList(updatedRoleList);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    };

    const getBusinessLineList = async () => {
        setLoading(true);
        try {
            let response = await MasterService.post("/sfa/user/business_line_list", {});
            if (response && response.data && response.data.status === 200) {
                let updatedBusinessLineList = response.data.data.map((item) => {
                    return {
                        id: item.business_line_id,
                        label: item.business_line,
                    };
                });
                setBusinessLineList(updatedBusinessLineList);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    };

    const uploadImages = async (docObject) => {
        let documentInfo = {};
        if (docObject.notification_banner_path.includes("blob")) {
            let formData = new FormData();
            formData.append("upload_type", "sfa_docs");
            formData.append("visit_id", `user/0`);
            formData.append("images", docObject.doc_file);
            let result = await MasterService.uploadFilesToS3(formData, { "Content-Type": "multipart/form-data" });
            if (result.data.status === 200) {
                documentInfo.notification_banner_path = result.data.data[0].file_url;
                toast.success(`Popup Banner Uploaded`);
            }
        } else {
            documentInfo.doc_url = docObject.doc_url;
        }
        return documentInfo;
    };

    const createCustomNotification = async (params) => {
        setLoading(true);
        try {
            if (isValidNotification()) {
                let updated_params = {
                    name: params.name,
                    type: params.type,
                    frequency: params.frequency,
                    applicable_to: params.applicable_to,
                };

                updated_params.start_duration_date = params.start_duration_date || "";
                updated_params.end_duration_date = params.end_duration_date || "";

                if (notificationId !== 0) {
                    updated_params.notification_id = notificationId;
                }

                if (params.type === "push_notification") {
                    updated_params.notification_header = params.notification_header;
                    updated_params.notification_body = params.notification_body;
                    updated_params.notification_banner_path = "";
                }

                if (params.type === "popup_banner") {
                    let popup_banner = await uploadImages({ doc_file: params.doc_file, notification_banner_path: params.notification_banner_path });
                    let is_doc_uploaded = true;
                    if (!popup_banner || (popup_banner && !popup_banner.notification_banner_path)) {
                        is_doc_uploaded = false;
                    }
                    updated_params.notification_banner_path = is_doc_uploaded ? popup_banner.notification_banner_path : params.notification_banner_path;
                    updated_params.notification_header = "";
                    updated_params.notification_body = "";
                }

                if (params.type === "notification_and_banner") {
                    let popup_banner = await uploadImages({ doc_file: params.doc_file, notification_banner_path: params.notification_banner_path });
                    let is_doc_uploaded = true;
                    if (!popup_banner || (popup_banner && !popup_banner.notification_banner_path)) {
                        is_doc_uploaded = false;
                    }
                    updated_params.notification_banner_path = is_doc_uploaded ? popup_banner.notification_banner_path : params.notification_banner_path;
                    updated_params.notification_header = params.notification_header;
                    updated_params.notification_body = params.notification_body;
                }

                if (params.applicable_to === "user_role_and_business_line") {
                    updated_params.applicable_to_roles = params.role_ids.map((item) => item.id);
                    updated_params.applicable_to_businesses = params.business_lines.map((item) => item.id);
                }
                let response = await MasterService.post("/sfa/user/create_custom_notification", updated_params);
                if (response && response.data && response.data.status === 200) {
                    toast.success("Notification Created");
                    if (file) {
                        let formData = new FormData();
                        formData.append("file", file);
                        formData.append("notification_id", response.data.data.id);
                        let uploadResponse = await MasterService.post("/sfa/user/notification_bulk_upload", formData, {
                            "Content-Type": "multipart/form-data",
                        });
                        if (uploadResponse && uploadResponse.data && uploadResponse.data.status === 200) {
                            toast.success("File Uploaded");
                        } else {
                            toast.error(uploadResponse.data.message);
                        }
                    }
                    historyLead("/custom-notification");
                } else {
                    toast.error(response.data.message);
                }
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (event, name) => {
        let tempCustomNotification = { ...customNotification };
        let tempErrors = { ...errors };
        if (name === "notification_duration") {
            if (event.every((item) => item)) {
                setDateRange(event);
                if (event[0]) {
                    tempCustomNotification["start_duration_date"] = event[0];
                }
                if (event[1]) {
                    tempCustomNotification["end_duration_date"] = event[1];
                }
            } else {
                setDateRange(event);
                tempCustomNotification["start_duration_date"] = "";
                tempCustomNotification["end_duration_date"] = "";
            }
        } else if (name === "type") {
            tempCustomNotification[name] = event.id;
            tempCustomNotification["notification_banner_path"] = "";
            tempCustomNotification["notification_header"] = "";
            tempCustomNotification["notification_body"] = "";
        } else if (name === "frequency") {
            tempCustomNotification[name] = event.id;
            setDateRange([null, null]);
            tempCustomNotification["start_duration_date"] = "";
            tempCustomNotification["end_duration_date"] = "";
        } else if (name === "popup_banner") {
            let { files } = event.target;
            let [fileObject] = files;
            let browseUrl = URL.createObjectURL(fileObject);
            tempCustomNotification["doc_file"] = fileObject;
            tempCustomNotification["notification_banner_path"] = browseUrl;
        } else if (name === "applicable_to") {
            tempCustomNotification[name] = event.target.value;
            tempCustomNotification["role_ids"] = [];
            tempCustomNotification["business_lines"] = [];
            setFile(null);
        } else {
            tempCustomNotification[name] = event.target.value;
        }
        tempErrors[name] = "";
        setErrors(tempErrors);
        setCustomNotification(tempCustomNotification);
    };

    const handleImageClick = () => {
        showImagePopup();
    };

    const handleReUpload = () => {
        let element = document.getElementById("popup_banner");
        element.click();
    };

    const handleRemove = () => {
        let tempCustomNotification = { ...customNotification };
        tempCustomNotification.notification_banner_path = "";
        setCustomNotification(tempCustomNotification);
        hideImagePopup();
    };

    const handleChangeMultiSelect = (fieldName, fieldObj, event) => {
        let currVal = [];
        let selectedVal = [];
        let tempCustomNotification = { ...customNotification };
        let tempErrors = { ...errors };
        if (event.action === "select-option" && event.option.value === "*") {
            if (fieldName === "role_ids") {
                selectedVal = [{ label: "Select All", value: "*" }, ...roleList];
                currVal = roleList;
            } else if (fieldName === "business_lines") {
                selectedVal = [{ label: "Select All", value: "*" }, ...businessLineList];
                currVal = businessLineList;
            }
        } else if (event.action === "deselect-option" && event.option.value === "*") {
            selectedVal = [];
        } else if (event.action === "deselect-option" && event.option.value !== "*") {
            selectedVal = fieldObj.filter((o) => o.value !== "*");
            currVal = selectedVal;
        } else if (fieldName === "role_ids" && fieldObj.length === roleList.length) {
            currVal = roleList;
            selectedVal = [{ label: "Select All", value: "*" }, ...roleList];
        } else if (fieldName === "business_lines" && fieldObj.length === businessLineList.length) {
            currVal = businessLineList;
            selectedVal = [{ label: "Select All", value: "*" }, ...businessLineList];
        } else {
            currVal = fieldObj;
            selectedVal = fieldObj;
        }
        tempCustomNotification[fieldName] = currVal;
        tempErrors[fieldName] = "";
        setErrors(tempErrors);
        setCustomNotification(tempCustomNotification);
    };

    const showImagePopup = () => {
        setImagePopup(true);
    };

    const hideImagePopup = () => {
        setImagePopup(false);
    };

    const showFileUploadPopup = () => {
        let tempErrors = { ...errors };
        tempErrors["user_ids"] = "";
        setFileUploadPopup(true);
        setErrors(tempErrors);
    };

    const hideFileUploadPopup = () => {
        setFileUploadPopup(false);
    };

    const downloadData = () => {
        csvLink.current.link.click();
    };

    const isValidNotification = () => {
        let isValid = true;
        let tempErrors = { ...errors };
        let tempCustomNotification = { ...customNotification };
        if (!tempCustomNotification["name"]) {
            tempErrors["name"] = "Name  is required";
            isValid = false;
        }

        if (!tempCustomNotification["type"]) {
            tempErrors["type"] = "Type is required";
            isValid = false;
        }

        if (!tempCustomNotification["frequency"]) {
            tempErrors["frequency"] = "Frequency is required";
            isValid = false;
        }

        if (tempCustomNotification["frequency"] !== "once") {
            if (!tempCustomNotification["start_duration_date"] || !tempCustomNotification["end_duration_date"]) {
                tempErrors["notification_duration"] = "Start and End duration date is required";
                isValid = false;
            }
        }

        if (!tempCustomNotification["applicable_to"]) {
            tempErrors["applicable_to"] = "Applicable to is required";
            isValid = false;
        }

        if (tempCustomNotification["applicable_to"]) {
            if (tempCustomNotification["applicable_to"] === "custom_users") {
                if (!file) {
                    tempErrors["user_ids"] = "User IDs are required";
                    isValid = false;
                }
            } else if (tempCustomNotification["applicable_to"] === "user_role_and_business_line") {
                if (!tempCustomNotification["role_ids"] || (tempCustomNotification["role_ids"] && tempCustomNotification["role_ids"].length === 0)) {
                    tempErrors["role_ids"] = "Role IDs are required";
                    isValid = false;
                }

                if (
                    !tempCustomNotification["business_lines"] ||
                    (tempCustomNotification["business_lines"] && tempCustomNotification["business_lines"].length === 0)
                ) {
                    tempErrors["business_lines"] = "Business Lines are required";
                    isValid = false;
                }
            }
        }

        if (
            tempCustomNotification["type"] &&
            (tempCustomNotification["type"] === "push_notification" || tempCustomNotification["type"] === "notification_and_banner")
        ) {
            if (!tempCustomNotification["notification_header"]) {
                tempErrors["notification_header"] = "Notification header is requried";
                isValid = false;
            }
            if (!tempCustomNotification["notification_body"]) {
                tempErrors["notification_body"] = "Notification body is required";
                isValid = false;
            }
        }
        if (
            tempCustomNotification["type"] &&
            (tempCustomNotification["type"] === "popup_banner" || tempCustomNotification["type"] === "notification_and_banner")
        ) {
            if (!tempCustomNotification["notification_banner_path"]) {
                tempErrors["notification_banner_path"] = "Notification banner is required";
                isValid = false;
            }
        }

        setErrors(tempErrors);
        return isValid;
    };

    return (
        <React.Fragment>
            <div className="add-user-form-sec">
                <div className="custom-notification">
                    <div className="basic-detail-form p-xxl add-user-detail-filed">
                        {loading ? <Loader /> : null}
                        <h2>Create Notification</h2>
                        <h3>Notification Information</h3>
                        <div className="row">
                            <div className="col-md-3">
                                <fieldset className="">
                                    <div className="material">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            label="Notification Name"
                                            placeholder=" "
                                            value={customNotification && customNotification.name}
                                            onChange={(e) => {
                                                handleChange(e, "name");
                                            }}
                                            required={true}
                                            className="form-input"
                                        />
                                        <label data-label="Notification Name*" className="form-label"></label>
                                    </div>
                                    {errors && errors.name ? <span className="error show">{errors.name}</span> : ""}
                                </fieldset>
                            </div>

                            <div className="col-md-3">
                                <fieldset className="single-select">
                                    <div className="material">
                                        <Select
                                            components={{ ValueContainer: CustomValueContainer }}
                                            options={notificationType}
                                            placeholder="Notification Type*"
                                            value={notificationType.filter((item) => customNotification && customNotification.type === item.id)}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            onChange={(e) => {
                                                handleChange(e, "type");
                                            }}
                                            getOptionLabel={({ label }) => label}
                                            getOptionValue={({ id }) => id}
                                        />
                                    </div>
                                    {errors && errors.type ? <span className="error show">{errors.type}</span> : ""}
                                </fieldset>
                            </div>

                            <div className="col-md-3">
                                <fieldset className="single-select">
                                    <div className="material">
                                        <Select
                                            components={{ ValueContainer: CustomValueContainer }}
                                            options={frequencyOptions}
                                            placeholder="Frequency*"
                                            value={frequencyOptions.filter((item) => customNotification && customNotification.frequency === item.id)}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            onChange={(e) => {
                                                handleChange(e, "frequency");
                                            }}
                                            getOptionLabel={({ label }) => label}
                                            getOptionValue={({ id }) => id}
                                        />
                                    </div>
                                    {errors && errors.frequency ? <span className="error show">{errors.frequency}</span> : ""}
                                </fieldset>
                            </div>

                            <div className="col-md-3">
                                <fieldset className="rangepicker-calendra">
                                    <div className="material">
                                        <div className="datepicker">
                                            <DatePicker
                                                name="notification_duration"
                                                id="notification_duration"
                                                selectsRange={true}
                                                startDate={startDate}
                                                endDate={endDate}
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                dateFormat="yyyy-MM-dd"
                                                onChange={(e) => {
                                                    handleChange(e, "notification_duration");
                                                }}
                                                isClearable={false}
                                                placeholderText={"Notification Duration*"}
                                                autoComplete="off"
                                                minDate={new Date()}
                                            />
                                            {customNotification && customNotification.start_duration_date ? (
                                                <label data-label="Notification Duration*" className="form-label date-label"></label>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                    {errors && errors.notification_duration ? <span className="error show">{errors.notification_duration}</span> : ""}
                                </fieldset>
                            </div>
                        </div>

                        <h3>Applicable To</h3>
                        <div className={`row ${!customNotification.applicable_to ? "m-xl-b" : ""}`}>
                            <div className="col-md-3 custom-checkbox">
                                <input
                                    id="custom_users"
                                    type="radio"
                                    value="custom_users"
                                    name="custom_users"
                                    onChange={(e) => {
                                        handleChange(e, "applicable_to");
                                    }}
                                    className="custom-control-input"
                                    checked={customNotification && customNotification.applicable_to === "custom_users"}
                                />

                                <label htmlFor="custom_users" className="custom-control-label">
                                    Custom Users
                                </label>
                                <div>{errors && errors.applicable_to ? <span className="error show">{errors.applicable_to}</span> : ""}</div>
                            </div>

                            <div className="col-md-3 custom-checkbox">
                                <input
                                    id="user_role_and_business_line"
                                    type="radio"
                                    value="user_role_and_business_line"
                                    name="user_role_and_business_line"
                                    onChange={(e) => {
                                        handleChange(e, "applicable_to");
                                    }}
                                    className="custom-control-input"
                                    checked={customNotification && customNotification.applicable_to === "user_role_and_business_line"}
                                />
                                <label htmlFor="user_role_and_business_line" className="custom-control-label">
                                    User Role and Business Line
                                </label>
                            </div>

                            {customNotification && customNotification.applicable_to && customNotification.applicable_to === "custom_users" && (
                                <React.Fragment>
                                    <div className="col-md-3">
                                        <button className="btn-line m-xl-r" onClick={showFileUploadPopup}>
                                            <i className="ic-upload"></i> Bulk Upload
                                        </button>
                                        {notificationId ? (
                                            <button className="btn-line">
                                                <i className="ic-export m-xs-r"></i>
                                                <span
                                                    onClick={() => {
                                                        downloadNotificationList(notificationId);
                                                    }}
                                                >
                                                    Export Data
                                                </span>
                                                <CSVLink
                                                    data={userDownloadList}
                                                    filename={`notification-list-${Math.round(+new Date() / 1000)}.csv`}
                                                    data-interception="off"
                                                    ref={userDownloadLink}
                                                ></CSVLink>
                                            </button>
                                        ) : null}
                                        <div>{errors && errors.user_ids ? <span className="error show">{errors.user_ids}</span> : ""}</div>
                                    </div>
                                </React.Fragment>
                            )}

                            {customNotification && customNotification.applicable_to && customNotification.applicable_to === "user_role_and_business_line" && (
                                <React.Fragment>
                                    <div className="col-md-3">
                                        <fieldset className="multiselect-dropDown">
                                            <div className="material">
                                                <ReactMultiSelectCheckboxes
                                                    isMulti
                                                    options={[{ label: "Select All", value: "*" }, ...roleList]}
                                                    placeholderButtonLabel="Role"
                                                    iconAfter="false"
                                                    value={
                                                        customNotification &&
                                                            customNotification.role_ids &&
                                                            customNotification.role_ids.length === roleList.length
                                                            ? [{ label: "Select All", value: "*" }, ...roleList]
                                                            : customNotification.role_ids
                                                    }
                                                    onChange={handleChangeMultiSelect.bind(this, "role_ids")}
                                                    getOptionLabel={({ label }) => label}
                                                    getOptionValue={({ id }) => id}
                                                />
                                            </div>
                                            {errors && errors.role_ids ? <span className="error show">{errors.role_ids}</span> : ""}
                                        </fieldset>
                                    </div>

                                    <div className="col-md-3">
                                        <fieldset className="multiselect-dropDown">
                                            <div className="material">
                                                <ReactMultiSelectCheckboxes
                                                    isMulti
                                                    options={[{ label: "Select All", value: "*" }, ...businessLineList]}
                                                    placeholderButtonLabel="Business Line"
                                                    iconAfter="false"
                                                    value={
                                                        customNotification &&
                                                            customNotification.business_lines &&
                                                            customNotification.business_lines.length === businessLineList.length
                                                            ? [{ label: "Select All", value: "*" }, ...businessLineList]
                                                            : customNotification.business_lines
                                                    }
                                                    onChange={handleChangeMultiSelect.bind(this, "business_lines")}
                                                    getOptionLabel={({ label }) => label}
                                                    getOptionValue={({ id }) => id}
                                                />
                                            </div>
                                            {errors && errors.business_lines ? <span className="error show">{errors.business_lines}</span> : ""}
                                        </fieldset>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>

                        {customNotification &&
                            customNotification.type &&
                            (customNotification.type === "notification_and_banner" || customNotification.type === "push_notification") && (
                                <React.Fragment>
                                    <h3>Push Notification</h3>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <fieldset className="">
                                                <div className="material">
                                                    <input
                                                        type="text"
                                                        name="notification_header"
                                                        id="notification_header"
                                                        label="Notification Header"
                                                        placeholder=" "
                                                        value={customNotification && customNotification.notification_header}
                                                        onChange={(e) => {
                                                            handleChange(e, "notification_header");
                                                        }}
                                                        required={true}
                                                        className="form-input"
                                                        maxLength={20}
                                                    />
                                                    <label data-label="Notification Header" className="form-label"></label>
                                                </div>
                                                {errors && errors.notification_header ? <span className="error show">{errors.notification_header}</span> : ""}
                                            </fieldset>
                                        </div>

                                        <div className="col-md-3">
                                            <fieldset className="">
                                                <div className="material">
                                                    <textarea
                                                        placeholder=" "
                                                        className="form-input"
                                                        rows="1"
                                                        name="notification_body"
                                                        value={customNotification && customNotification.notification_body}
                                                        required={true}
                                                        onChange={(e) => {
                                                            handleChange(e, "notification_body");
                                                        }}
                                                    ></textarea>
                                                    <label data-label="Notification Body" className="form-label"></label>
                                                </div>
                                                {errors && errors.notification_body ? <span className="error show">{errors.notification_body}</span> : ""}
                                            </fieldset>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}

                        {customNotification &&
                            customNotification.type &&
                            (customNotification.type === "notification_and_banner" || customNotification.type === "popup_banner") && (
                                <React.Fragment>
                                    <h3>Add Banner Image</h3>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="upload-document-outer">
                                                <div className="img-bx-select-opt">
                                                    <div className="image-bx">
                                                        {customNotification &&
                                                            customNotification.notification_banner_path &&
                                                            customNotification.notification_banner_path !== "" && (
                                                                <img
                                                                    src={customNotification && customNotification.notification_banner_path}
                                                                    alt="No Document"
                                                                    onClick={() => {
                                                                        handleImageClick();
                                                                    }}
                                                                />
                                                            )}
                                                        {customNotification &&
                                                            (Object.keys(customNotification).length === 0 ||
                                                                !customNotification.notification_banner_path ||
                                                                customNotification.notification_banner_path === "") && (
                                                                <div className="image-bx more-file-upload">
                                                                    <i className="ic-add"></i>
                                                                    <input
                                                                        type="file"
                                                                        name="popup_banner"
                                                                        accept="image/*"
                                                                        id="popup_banner"
                                                                        className="upload"
                                                                        onChange={(e) => {
                                                                            handleChange(e, "popup_banner");
                                                                        }}
                                                                    />
                                                                    <p>Upload</p>
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div>
                                                        {errors && errors.notification_banner_path ? (
                                                            <span className="error show">{errors.notification_banner_path}</span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}

                        <div className="t-right">
                            <button
                                className="btn-primary"
                                onClick={() => {
                                    createCustomNotification(customNotification);
                                }}
                                // disabled={isCreateDisable}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="vechile-document-popup model-popup-outer">
                <Modal show={imagePopup} handleClose={hideImagePopup}>
                    <div className="modal-header"></div>
                    <div className="modal-body">
                        <img src={customNotification && customNotification.notification_banner_path} alt="No Document" />
                    </div>
                    <div className="modal-footer">
                        <div className="image-opt">
                            <ul>
                                <li>
                                    <a onClick={handleRemove}>
                                        <i className="ic-delete-icn"></i>
                                    </a>
                                </li>
                            </ul>
                            {customNotification && customNotification.notification_banner_path && customNotification.notification_banner_path !== "" && (
                                <input
                                    type="file"
                                    name="popup_banner"
                                    accept="image/*"
                                    id="popup_banner"
                                    className="upload"
                                    style={{ display: "none" }}
                                    onChange={(e) => {
                                        handleChange(e, "popup_banner");
                                    }}
                                />
                            )}
                            <button onClick={handleReUpload} className="btn-line btn-re-upload">
                                Re-upload
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>

            <div className="bulk-upload-popup model-popup-outer custom-notification-file-upload">
                <Modal show={fileUploadPopup} handleClose={hideFileUploadPopup}>
                    <div>
                        <div>
                            <div className="modal-header">
                                <h2>File Upload</h2>
                            </div>
                            <div className="modal-body">
                                <NotificationFileUpload file={file} setFile={setFile} errors={errors} />
                                <div className="download-txt">
                                    Download input format <span onClick={downloadData}>Click Here</span>
                                </div>
                                <CSVLink data={csvData} filename={`sample_format.csv`} data-interception="off" ref={csvLink}></CSVLink>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    );
};

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
        </ValueContainer>
    );
};

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";
    return (
        <div className={showHideClassName}>
            <section className="modal-main">
                {children}
                <button onClick={handleClose} className="close_icn">
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};

export default CreateCustomNotification;
