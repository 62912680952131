import React from "react";
import ls from "local-storage";
import { MasterService } from "../../services";
import { toast } from "react-toastify";
import Loader from "../common/loader";
class ImageUploader extends React.Component {
    constructor(props) {
        super(props);
        this.imageRef = React.createRef(null);
        this.handleAddImage = this.handleAddImage.bind(this);
        this.handleUploadImage = this.handleUploadImage.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDragEnter = this.handleDragEnter.bind(this);
        this.handleDragLeave = this.handleDragLeave.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleCancelUpload = this.handleCancelUpload.bind(this);
        this.state = {
            file: null,
            dragOver: false,
            errorNoficication: null,
            csvData:[],
            loading:false
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
          file: nextProps.file
        });
      }

    /**
       Drag and Drop Event Handlers
    **/
    handleDragEnter(e) {
        e.preventDefault();
    }
    handleDragOver(e) {
        e.preventDefault();
        if (!this.state.dragOver) {
            this.setState({
                dragOver: true
            });
        }
    }
    handleDragLeave(e) {
        e.preventDefault();
        this.setState({
            dragOver: false
        });
    }
    handleDrop(e) {
        e.preventDefault();
        let file = e.dataTransfer.files[0];

        // Validate file is of type Image
        let fileType = file.type.split("/")[0];
        if (fileType !== "text") {
            this.setState({
                file: null,
                errorNotification: "Not a csv File",
                dragOver: false
            });
        }
        document.getElementById('upload-image-input').fileList = e.dataTransfer.files[0];
        this.setState({
            file,
            dragOver: false
        });
    }


    /**
       Handle Manually (File Input) Added Files
    **/
    handleAddImage(e) {
        e.preventDefault();
        let file = this.imageRef.current.files[0];

        // Validate file is of type Image
        let fileType = this.imageRef.current.files[0].type.split('/')[0];
        if (fileType !== "text") {
            this.setState({
                file: null,
                errorNotification: "Not a csv File",
                dragOverClass: ""
            });
        }
        this.setState({
            file
        });
    }

    /**
       Handle Upload after Upload Button Clicked
    **/
    handleUploadImage(e) {
        e.preventDefault();
        
    }
    handleCancelUpload(e) {
        e.preventDefault();
        this.imageRef = React.createRef(null)
        this.setState({
            file: null,
            dragOver: false
        });
    }

    handleBulkUpload = async (e) => {
        let self = this
        let files = this.state.file
        this.setState({loading:true})
        let logged_data = ls.get('logged_data')
        if (logged_data && logged_data.token && logged_data.user_data && logged_data.user_data.user_id) {
            let user_id = logged_data.user_data.user_id
            var formData = new FormData();
            formData.append("file", files);
            formData.append("logged_user_id", user_id);
            await MasterService.post('/sfa/admin/bulkUploadUserData', formData, {
                "Content-Type": "multipart/form-data",
            }).then((result) => {
                if (result.data.status === 200) {
                    self.setState({loading:false, file: null, dragOver: false})
                    toast.success("Data uploaded")
                    self.handleCancelUpload(e)
                    self.props.props.getBulkUploadHistory()
                } else if(result.data.message){
                    self.setState({loading:false})
                    toast.error(result.data.message)
                }
            })
        }
    }


    render() {

        // Match drag over css to hover css
        let dragOverClass = this.state.dragOver
            ? `display-box drag-over`
            : `display-box`;

        // If file is set, change upload box text to file name
        let uploadText = this.state.file
            ? <div className="upload-file-name">
                <div className="upload-file-img">
                    <span className="suceess-icn">
                        <i className="ic-check"></i>
                    </span>

                    <h4>{this.state.file.name}
                        <i onClick={(e) => this.handleCancelUpload(e)} className="ic-clearclose"></i>
                    </h4>
                </div>
                <button
                    onClick={(e) => { this.handleBulkUpload(e) }}
                    className="btn-line file-btn btn-primary"
                >
                    Upload
                </button>
            </div >
            : <div className="upload-txt">
                <h4> Drop Files Here to Upload</h4>
                <span>Only csv files are accepted</span>
                <div className="or-txt">
                    or
                </div>
                <button
                    className="btn-line file-btn btn-primary"
                    onClick={() => document.getElementById('upload-image-input').click()}>
                    Upload
                </button>

            </div>;

        // Show Error message if file type is not an image
        let errorNotification = this.state.errorNotification
            ? <div className="error-notification">
                <p>{this.state.errorNotification}</p>
            </div>
            : null;


        return (
            <div className="image-uploader-wrapper">
                {this.state.loading ? <Loader /> : null}
                <div className={dragOverClass}>
                    <div className="icon-text-box">
                        <div className="upload-icon">
                            <i className="ic-file-icon" aria-hidden="true" />
                        </div>

                        <div className="upload-text">
                            {uploadText}
                        </div>

                        {errorNotification}
                    </div>
                    {this.state.file == null && 
                    <div>
                        <input
                            type="file"
                            ref={this.imageRef}
                            id="upload-image-input"
                            className="upload-image-input"
                            accept="text/csv"
                            onDrop={this.handleDrop}
                            onDragEnter={this.handleDragEnter}
                            onDragOver={this.handleDragOver}
                            onDragLeave={this.handleDragLeave}
                            onChange={this.handleAddImage}
                        />
                    </div>
                    }
                </div>
            </div>
        );
    }
}

class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file:null
        };
    }
    componentWillMount() { }

    componentWillReceiveProps(nextProps) {
        this.setState({
          file: nextProps.file
        });
      }

    render() {
        return (
            <div style={{ textAlign: "center" }}>
                <div>
                    {/* <CSVLink
                      data={csvData}
                      filename={`sample_format.csv`}
                      data-interception='off'
                      ref={csvLink}
                    >
                    </CSVLink> */}
                    <ImageUploader props={this.props} file={this.state.file} />
                </div>
            </div>
        );
    }
}

export default FileUpload



