import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import InputField from "../common/Input";
import Select, { components } from "react-select";

import Loader from "../common/loader";
import { MasterService } from "../../services";
import CustomNotificationHistory from "./CustomNotificationHistory";
import ReactMultiSelectCheckboxes from "../common/ReactMultiSelectCheckboxes";

const STATUS = [
    { id: 1, label: "Active", value: "1" },
    { id: 2, label: "Inactive", value: "0" },
];
const { ValueContainer, Placeholder } = components;

const CustomNotification = () => {
    const [notificationList, setNotificationList] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [businessLineList, setBusinessLineList] = useState([]);
    const [filterOptions, setFilterOptions] = useState({});

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    let historyLead = useNavigate();

    // API calls
    useEffect(() => {
        getBusinessLineList();
        getNotificationList();
    }, []);

    const getNotificationList = async (params) => {
        setLoading(true);
        try {
            let response = await MasterService.post("/sfa/user/custom_notification_list", params);
            if (response && response.data && response.data.status === 200) {
                let { data, perPage, total } = response.data.data;
                setNotificationList(data);
                setPageCount(Math.ceil(total / perPage));
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    };

    const deactivateNotification = async (notification) => {
        setLoading(true);
        let status = "";
        let successMessage = "";
        let notificationId = notification.id;
        if (notification.is_active === "1") {
            status = "0";
            successMessage = "Notification deactivated successfully";
        } else {
            status = "1";
            successMessage = "Notification activated successfully";
        }
        try {
            let response = await MasterService.post("/sfa/user/change_notification_status", { notification_id: notificationId, status: status });
            if (response && response.data && response.data.status === 200) {
                toast.success(successMessage);
                getNotificationList({ page: offset + 1 });
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    };

    const getBusinessLineList = async (role_id) => {
        try {
            let response = await MasterService.post("/sfa/user/business_line_list", {});
            if (response.data.status === 200) {
                let new_business_line = response.data.data.map((elm) => ({ label: elm.business_line, value: elm.business_line_id, id: elm.business_line_id }));
                setBusinessLineList(new_business_line);
            }
        } catch (error) {
            toast.error(error);
        }
    };

    const handleEditNotification = (notification) => {
        historyLead("/create-custom-notification", { state: { notificationId: notification.id } });
    };

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setOffset(selectedPage);
        getNotificationList({ page: selectedPage + 1 });
    };

    const handleChangeMultiSelect = (fieldName, fieldObj, event) => {
        let currVal = [];
        let tempFilterOptions = { ...filterOptions };
        if (event.action === "select-option" && event.option.value === "*") {
            if (fieldName === "business_lines") {
                currVal = [{ label: "Select All", value: "*" }, ...businessLineList];
            }
        } else if (event.action === "deselect-option" && event.option.value === "*") {
            currVal = [];
        } else if (event.action === "deselect-option" && event.option.value !== "*") {
            currVal = fieldObj.filter((o) => o.value !== "*");
        } else if (fieldName === "business_lines" && fieldObj.length === businessLineList.length) {
            currVal = [{ label: "Select All", value: "*" }, ...businessLineList];
        } else {
            currVal = fieldObj;
        }
        tempFilterOptions[fieldName] = currVal;
        setFilterOptions(tempFilterOptions);
    };

    const handleChange = (event, name) => {
        let tempFilterOptions = { ...filterOptions };
        if (name === "status") {
            tempFilterOptions["status"] = event.value;
        } else if (name === "created_date") {
            setDateRange(event);
            if (event !== null) {
                if (event[0]) {
                    tempFilterOptions["start_created_date"] = event[0];
                }
                if (event[1]) {
                    tempFilterOptions["end_created_date"] = event[1];
                }
            }
        } else if (name === "notification_name") {
            tempFilterOptions["notification_name"] = event.target.value;
            getNotificationList(tempFilterOptions);
        }
        setFilterOptions(tempFilterOptions);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        getNotificationList(filterOptions);
    };

    const resetFilter = () => {
        setFilterOptions({});
        setDateRange([null, null]);
        getNotificationList();
    };

    return (
        <React.Fragment>
            {loading ? <Loader /> : null}
            <div className="container-fluid">
                <div className="top-heading d-flex">
                    <h1>Notification History</h1>
                    <div className="right-btn">
                        <button className="btn-line p-md">
                            <NavLink to="/create-custom-notification">Create Notification</NavLink>
                        </button>
                    </div>
                </div>
                <div className="loan-filter-bg">
                    <div className="top-filter-bg">
                        <React.Fragment>
                            <div className="lead-list-filter">
                                <fieldset className="rangepicker-calendra">
                                    <div className="material">
                                        <div className="datepicker">
                                            <DatePicker
                                                name="created_date"
                                                id="created_date"
                                                selectsRange={true}
                                                startDate={startDate}
                                                endDate={endDate}
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                dateFormat="yyyy-MM-dd"
                                                onChange={(e) => {
                                                    handleChange(e, "created_date");
                                                }}
                                                isClearable={false}
                                                placeholderText={`Created Date`}
                                                autoComplete="off"
                                                maxDate={new Date()}
                                            />
                                            {startDate ? <label data-label={"Created Date"} className="form-label date-label"></label> : ""}
                                        </div>
                                    </div>
                                </fieldset>

                                <fieldset className="single-select">
                                    <div className="material">
                                        <Select
                                            components={{ ValueContainer: CustomValueContainer }}
                                            id="status"
                                            name="status"
                                            onChange={(e) => {
                                                handleChange(e, "status");
                                            }}
                                            options={STATUS}
                                            value={STATUS.filter((item) => filterOptions.status === item.value)}
                                            placeholder="Status"
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            getOptionValue={({ id }) => id}
                                            getOptionLabel={({ label }) => label}
                                        />
                                    </div>
                                </fieldset>

                                <fieldset className="multiselect-dropDown col-md-4">
                                    <div className="material">
                                        <ReactMultiSelectCheckboxes
                                            isMulti
                                            options={[{ label: "Select All", value: "*" }, ...businessLineList]}
                                            placeholderButtonLabel="Business Line"
                                            iconAfter="false"
                                            value={
                                                filterOptions?.business_line?.length === businessLineList.length
                                                    ? [{ label: "Select All", value: "*" }, ...businessLineList]
                                                    : filterOptions?.business_lines
                                                    ? filterOptions.business_lines
                                                    : []
                                            }
                                            onChange={handleChangeMultiSelect.bind(this, "business_lines")}
                                            getOptionLabel={({ label }) => label}
                                            getOptionValue={({ id }) => id}
                                        />
                                    </div>
                                </fieldset>

                                <fieldset className="admin-email">
                                    <div className="material">
                                        <InputField
                                            id="notification_name"
                                            type="notification_name"
                                            placeholder="Notification Name"
                                            name="notification_name"
                                            autoComplete="off"
                                            value={filterOptions && filterOptions.notification_name ? filterOptions.notification_name : ""}
                                            label="Notification Name"
                                            onChange={(e) => {
                                                handleChange(e, "notification_name");
                                            }}
                                        />
                                    </div>
                                </fieldset>
                                <div className="btn-submit-reset m-sm-l">
                                    <button
                                        onClick={(e) => {
                                            handleSubmit(e);
                                        }}
                                        type="button"
                                        className="btn-primary"
                                    >
                                        Search
                                    </button>
                                    <button
                                        onClick={() => {
                                            resetFilter();
                                        }}
                                        className="btn-reset"
                                    >
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                </div>

                <div className="role-management-table-outer">
                    <CustomNotificationHistory
                        notificationList={notificationList}
                        deactivateNotification={deactivateNotification}
                        handleEditNotification={handleEditNotification}
                    />
                </div>
                <div className="pagination-bx">
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        forcePage={offset}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
        </ValueContainer>
    );
};

export default CustomNotification;
