import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { imageWidth } from '../../config/constant';
import SimpleSlider from './uploadDocSlider';

const AddDocuments = (props) => {
    let { ConfigList, user_detail } = props
    const [added_documents, setDocuments] = useState([]);
    const [errors, SetErrors] = useState(props.location_state && props.location_state.tempErrors ? props.location_state.tempErrors : {});
    const [activeIndex, setAcitveIndex] = useState(0);
    const [show_popup, setPopup] = useState(false);
    const [image_width, setImageWidth] = useState(imageWidth);
    const [rotated_angle, setRotateAngle] = useState(0);
    // const [is_edit, setEdit] = useState(user_detail && user_detail.user_id && user_detail.user_id !== "" ? true : false)//removed warning
    const [is_edit] = useState(user_detail && user_detail.user_id && user_detail.user_id !== "" ? true : false)

    const historyLead = useNavigate();

    useEffect(() => {
        if (!props.user_detail.role_details || !props.user_detail.basic_details) {
            props.handleReset()
            if (is_edit) {
                // historyLead('documents?id=' + user_detail.user_id);
                historyLead('/edit-user/documents?id=' + user_detail.user_id);
            } else {
                // historyLead('documents');
                historyLead('/add-user/documents');
            }
        }
        if (ConfigList.length === 0) {
            props.handleReset()
            if (is_edit) {
                // historyLead('role_details?id=' + user_detail.user_id);
                historyLead('/edit-user/role_details?id=' + user_detail.user_id);
            } else {
                // historyLead('role_details');
                historyLead('/add-user/role_details');
            }
        }
        if (props.user_detail.documents && props.user_detail.documents?.length > 0) {
            let documents = []
            for (let index = 0; index < ConfigList.document.length; index++) {
                const elm = ConfigList.document[index];
                let file_index = props.user_detail.documents.findIndex(e => e.document_id === elm.id)
                if (file_index >= 0) {
                    documents.push({
                        created_date: elm.created_date,
                        doc_bg_color: elm.doc_bg_color,
                        doc_logo_url: elm.doc_logo_url,
                        doc_max: elm.doc_max,
                        doc_min: elm.doc_min,
                        doc_required: elm.doc_required,
                        doc_title: elm.doc_title,
                        doc_url: props.user_detail.documents[file_index].doc_url,
                        is_doc_verified: props.user_detail.documents[file_index].is_doc_verified,
                        edit_verify_status: props.user_detail.documents[file_index].is_doc_verified ? 0 : 1,
                        doc_file: props.user_detail.documents[file_index].doc_file,
                        document_id: elm.id,
                        status: elm.status,
                        updated_date: elm.updated_date
                    })

                } else {
                    documents.push({
                        created_date: elm.created_date,
                        doc_bg_color: elm.doc_bg_color,
                        doc_logo_url: elm.doc_logo_url,
                        doc_max: elm.doc_max,
                        doc_min: elm.doc_min,
                        doc_required: elm.doc_required,
                        doc_title: elm.doc_title,
                        doc_url: "",
                        is_doc_verified: 0,
                        edit_verify_status: 1,
                        doc_file: "",
                        document_id: elm.id,
                        status: elm.status,
                        updated_date: elm.updated_date
                    })
                }
            }
            setDocuments(documents)
        } else {
            var documents = ConfigList.document.map(elm => ({
                created_date: elm.created_date,
                doc_bg_color: elm.doc_bg_color,
                doc_logo_url: elm.doc_logo_url,
                doc_max: elm.doc_max,
                doc_min: elm.doc_min,
                doc_required: elm.doc_required,
                doc_title: elm.doc_title,
                doc_url: "",
                is_doc_verified: 0,
                edit_verify_status: 1,
                doc_file: "",
                document_id: elm.id,
                status: elm.status,
                updated_date: elm.updated_date
            }));
            setDocuments(documents)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, historyLead, ConfigList]);

    const handleChangeImage = (event) => {
        let { files, name } = event.target
        let temp_errors = {...errors};
        let file_index = ConfigList.document.findIndex(e => e.doc_title === name)
        temp_errors[name] = '';
        let filesInfo = Array.from(files);
        if (filesInfo && filesInfo.length) {
            filesInfo.forEach((file, index) => {
                hidePopup()
                let browse_url = URL.createObjectURL(file);
                let temp_added_documents = added_documents;
                let temp_data = temp_added_documents[file_index]
                temp_data["doc_file"] = file;
                temp_data["doc_url"] = browse_url;
                temp_data["is_doc_verified"] = 0
                temp_data["edit_verify_status"] = 1
                temp_added_documents[file_index] = temp_data
                setImageProps(file_index)
                setDocuments(temp_added_documents);
            });
        }
        SetErrors(temp_errors);
    }

    const handleClickCancel = ()=>{
        if(user_detail?.user_id){
            historyLead('/view-user/' + user_detail?.user_id_hash,  { replace: true })
        }        
    }

    const handleClick = async () => {
        let res = await isValidate()
        if (res) {
            await props.handleSave(added_documents, 'documents', false)
            if (is_edit) {
                // historyLead('preview_and_confirm?id=' + user_detail.user_id);
                historyLead('/edit-user/preview_and_confirm?id=' + user_detail.user_id_hash);
            } else {
                // historyLead('preview_and_confirm');
                historyLead('/add-user/preview_and_confirm');
            }
        }
    }

    const isValidate = async () => {
        let formIsValid = true;
        let tempErrors = {};
        for (let index = 0; index < added_documents.length; index++) {
            const element = added_documents[index];
            let config_doc = ConfigList.document.filter(e => e.id === element.document_id)
            if (element.doc_url === "" && config_doc[0].doc_required) {
                formIsValid = false
                let doc_title = ConfigList.document.filter(e => parseInt(e.id) === parseInt(element.document_id))[0].doc_title
                tempErrors[doc_title] = doc_title + " is required"
            }
            if (element.doc_url !== "" && !element.is_doc_verified) {
                formIsValid = false
                let doc_title = ConfigList.document.filter(e => parseInt(e.id) === parseInt(element.document_id))[0].doc_title
                tempErrors[doc_title] = doc_title + " verification is required"
            }
        }
        SetErrors(tempErrors);
        return formIsValid;
    }

    const setImageProps = (index) => {
        showPopup()
        setTimeout(() => {
            hidePopup()
        }, 0);
        setAcitveIndex(index)
        setRotateAngle(0)
        setImageWidth(imageWidth)
    }

    const handleImageClick = (index) => {
        showPopup()
        setAcitveIndex(index)
        setRotateAngle(0)
        setImageWidth(imageWidth)
    }

    const showPopup = () => {
        setPopup(true)
    }

    const hidePopup = () => {
        setPopup(false)
    }

    const changeSlide = (cur) => {
        setAcitveIndex(cur)
        setRotateAngle(0)
        setImageWidth(imageWidth)
    }

    const handleRemove = () => {
        let data = added_documents[activeIndex]
        data.doc_url = ""
        data.is_doc_verified = 0
        data.edit_verify_status = 1
        added_documents[activeIndex] = data
        setDocuments(added_documents)
        hidePopup()
    }

    const handleReUpload = () => {
        let element = document.getElementById("upload-image-doc_" + activeIndex)
        element.click()
    }

    const zoomOut = () => {
        let temp_image_width = image_width >= 460 ? (image_width - 100) : image_width
        setImageWidth(temp_image_width)
    }

    const zoomIn = () => {
        let temp_image_width = image_width < 1260 ? (image_width + 100) : image_width
        setImageWidth(temp_image_width)
    }

    const handleRotate = () => {
        let temp_rotated_angle = rotated_angle === 360 ? 0 : rotated_angle + 90
        setRotateAngle(temp_rotated_angle)
    }

    const handleDocVerifiedStatus = async (index) => {
        let data = [...added_documents]
        if(data[index].doc_url != ""){
            data[index].is_doc_verified = !data[index].is_doc_verified
            setDocuments(data)
            let temp_errors = {...errors}
            temp_errors[data[index].doc_title] = '';
            SetErrors(temp_errors)
        }
    }

    // useEffect(()=>{
    //     setDocuments(props.user_detail.documents)
    // }, [props.user_detail.documents])

    return (
        <React.Fragment>
            <React.Fragment>
                <div className='add-user-outer'>
                    <div className={"agent-detail-outer route-files"}>
                        <div className='document-details-form p-xxl add-user-detail-filed'>
                            <h2>Documents</h2>

                            <div className='upload-document-outer'>
                                {ConfigList && ConfigList.document && ConfigList.document?.length > 0 && added_documents && added_documents?.length && added_documents.map((data, index) => (
                                    <div key={index} className="img-bx-select-opt">
                                        <div className="image-bx">
                                            {data.doc_url !== "" &&
                                                <img alt='No Document' src={data.doc_url} onClick={() => handleImageClick(index)} />
                                            }
                                            {data.doc_url === "" &&
                                                <div className="image-bx more-file-upload">
                                                    <i className='ic-add'></i>
                                                    <input
                                                        type="file"
                                                        name={ConfigList.document.filter(e => parseInt(e.id) === parseInt(data.document_id))[0].doc_title}
                                                        accept="image/*"
                                                        id={"upload-image-input_" + index}
                                                        className="upload"
                                                        // onClick={() => setImageProps(index)}
                                                        onChange={handleChangeImage}
                                                    />
                                                    <p>Upload</p>
                                                </div>
                                            }
                                        </div>
                                        <span className='document-name'>{ConfigList.document.filter(e => parseInt(e.id) === parseInt(data.document_id))[0].doc_title + (data.doc_required ? "*" : "")}</span>
                                        {
                                            (errors && errors[ConfigList.document.filter(e => parseInt(e.id) === parseInt(data.document_id))[0].doc_title]) ? <span className="error show">{errors[ConfigList.document.filter(e => parseInt(e.id) === parseInt(data.document_id))[0].doc_title]}</span> : ''
                                        }

                                    </div>
                                ))}
                            </div>
                            <div className='br-top-flex'>
                                {is_edit ? <div className="t-right">
                                    <button className='btn-line m-xs-l' onClick={handleClickCancel.bind(this)}>
                                        Cancel
                                    </button>
                                </div> : null}
                                <div className="t-right">
                                    <button className='btn-primary' onClick={handleClick.bind(this)}>
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="vechile-document-popup model-popup-outer">
                        <Modal show={show_popup} handleClose={hidePopup} >
                            <div className="modal-header">
                            </div>
                            <div className="modal-body">
                                <SimpleSlider ConfigList={ConfigList} image_width={image_width} rotated_angle={rotated_angle} activeIndex={activeIndex} changeSlide={changeSlide} doc={added_documents} />
                            </div>
                            <div className='modal-footer'>
                                <div className="image-opt p-rel">
                                    <ul>
                                        <li>
                                            <a href={() => false} onClick={handleRemove}>
                                                <i className="ic-delete-icn"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href={() => false} onClick={handleRotate}>
                                                <i className="ic-refresh"></i>
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a >
                                                <i className="ic-fullscreen"></i>
                                            </a>
                                        </li> */}
                                        <li>
                                            <a href={() => false} onClick={zoomIn}>
                                                <i className="ic-add"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href={() => false} onClick={zoomOut}>
                                                <i className="ic-remove"></i>
                                            </a>
                                        </li>
                                    </ul>
                                    {ConfigList && ConfigList.document && ConfigList.document?.length > 0 && added_documents?.length > 0 &&
                                        <input
                                            type="file"
                                            name={ConfigList.document.filter(e => parseInt(e.id) === parseInt(added_documents[activeIndex].document_id))[0].doc_title}
                                            accept="image/*"
                                            id={"upload-image-doc_" + activeIndex}
                                            className="upload"
                                            style={{ display: 'none' }}
                                            onChange={handleChangeImage}
                                        />
                                    }
                                    {ConfigList && ConfigList.document && ConfigList.document?.length > 0 && added_documents?.length > 0 &&
                                        <div className='role-detail-preview d-flex user-wallet-view doc_verify_check'>
                                            <ul>
                                                <li className="custom-control custom-checkbox">
                                                    <input
                                                        id={`doc_` + activeIndex}
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        disabled={added_documents[activeIndex].edit_verify_status ? false : true}
                                                        onChange={() => handleDocVerifiedStatus(activeIndex)}
                                                        checked={added_documents[activeIndex].is_doc_verified}
                                                    />
                                                    <label htmlFor={`doc_` + activeIndex} className="custom-control-label">
                                                        Verified
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                    <button onClick={handleReUpload} className="btn-line btn-re-upload">Re-upload</button>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </React.Fragment>

        </React.Fragment>
    )

}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};
export default AddDocuments;