import React, { Component } from 'react';



class ReporteesTable extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        this.state = {
           
        };
    }

  
    render() {
        
        return (
            <React.Fragment>
              <div className='reportees-data-table data-table'>
                <table>
                    <thead>
                        <tr>
                            <th className="sorting sorting_asc">Name</th>
                            <th>Role</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>City</th>
                            <th>Status</th>
                            <th className="sorting sorting_asc">Created Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Ravinder Singh</td>
                            <td>Agent</td>
                            <td>username@oto.com</td>
                            <td>9718905472</td>
                            <td>Gurgaon</td>
                            <td><span className='status-active'>Active</span></td>
                            <td>20 Jul, 2022</td>
                        </tr>
                        <tr>
                            <td>Ravinder Singh</td>
                            <td>Agent</td>
                            <td>username@oto.com</td>
                            <td>9718905472</td>
                            <td>Gurgaon</td>
                            <td><span className='status-inactive'>Inactive  </span></td>
                            <td>20 Jul, 2022</td>
                        </tr>
                        <tr>
                            <td>Ravinder Singh</td>
                            <td>Agent</td>
                            <td>username@oto.com</td>
                            <td>9718905472</td>
                            <td>Gurgaon</td>
                            <td><span className='status-inprocess'>In Process</span></td>
                            <td>20 Jul, 2022</td>
                        </tr>
                        <tr>
                            <td>Ravinder Singh</td>
                            <td>Agent</td>
                            <td>username@oto.com</td>
                            <td>9718905472</td>
                            <td>Gurgaon</td>
                            <td><span className='status-active'>Active</span></td>
                            <td>20 Jul, 2022</td>
                        </tr>
                        <tr>
                            <td>Ravinder Singh</td>
                            <td>Agent</td>
                            <td>username@oto.com</td>
                            <td>9718905472</td>
                            <td>Gurgaon</td>
                            <td><span className='status-inactive'>Inactive  </span></td>
                            <td>20 Jul, 2022</td>
                        </tr>
                        <tr>
                            <td>Ravinder Singh</td>
                            <td>Agent</td>
                            <td>username@oto.com</td>
                            <td>9718905472</td>
                            <td>Gurgaon</td>
                            <td><span className='status-inprocess'>In Process</span></td>
                            <td>20 Jul, 2022</td>
                        </tr>
                        <tr>
                            <td>Ravinder Singh</td>
                            <td>Agent</td>
                            <td>username@oto.com</td>
                            <td>9718905472</td>
                            <td>Gurgaon</td>
                            <td><span className='status-active'>Active</span></td>
                            <td>20 Jul, 2022</td>
                        </tr>
                        <tr>
                            <td>Ravinder Singh</td>
                            <td>Agent</td>
                            <td>username@oto.com</td>
                            <td>9718905472</td>
                            <td>Gurgaon</td>
                            <td><span className='status-inactive'>Inactive  </span></td>
                            <td>20 Jul, 2022</td>
                        </tr>
                        <tr>
                            <td>Ravinder Singh</td>
                            <td>Agent</td>
                            <td>username@oto.com</td>
                            <td>9718905472</td>
                            <td>Gurgaon</td>
                            <td><span className='status-inprocess'>In Process</span></td>
                            <td>20 Jul, 2022</td>
                        </tr>
                        <tr>
                            <td>Ravinder Singh</td>
                            <td>Agent</td>
                            <td>username@oto.com</td>
                            <td>9718905472</td>
                            <td>Gurgaon</td>
                            <td><span className='status-active'>Active</span></td>
                            <td>20 Jul, 2022</td>
                        </tr>
                        <tr>
                            <td>Ravinder Singh</td>
                            <td>Agent</td>
                            <td>username@oto.com</td>
                            <td>9718905472</td>
                            <td>Gurgaon</td>
                            <td><span className='status-inactive'>Inactive  </span></td>
                            <td>20 Jul, 2022</td>
                        </tr>
                        <tr>
                            <td>Ravinder Singh</td>
                            <td>Agent</td>
                            <td>username@oto.com</td>
                            <td>9718905472</td>
                            <td>Gurgaon</td>
                            <td><span className='status-inprocess'>In Process</span></td>
                            <td>20 Jul, 2022</td>
                        </tr>
                        <tr>
                            <td>Ravinder Singh</td>
                            <td>Agent</td>
                            <td>username@oto.com</td>
                            <td>9718905472</td>
                            <td>Gurgaon</td>
                            <td><span className='status-active'>Active</span></td>
                            <td>20 Jul, 2022</td>
                        </tr>
                        <tr>
                            <td>Ravinder Singh</td>
                            <td>Agent</td>
                            <td>username@oto.com</td>
                            <td>9718905472</td>
                            <td>Gurgaon</td>
                            <td><span className='status-inactive'>Inactive  </span></td>
                            <td>20 Jul, 2022</td>
                        </tr>
                        <tr>
                            <td>Ravinder Singh</td>
                            <td>Agent</td>
                            <td>username@oto.com</td>
                            <td>9718905472</td>
                            <td>Gurgaon</td>
                            <td><span className='status-inprocess'>In Process</span></td>
                            <td>20 Jul, 2022</td>
                        </tr>
                       
                    </tbody>
                </table>
              </div>

            </React.Fragment>
        )
    }
}
export default ReporteesTable;