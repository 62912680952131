import React from 'react';
import Navigation from './Navigation';

// import { Redirect } from 'react-router-dom';

const Header = () => {
    return (
        <header className='header'>
            <Navigation />
        </header>
    );
}

export default Header;