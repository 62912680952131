import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MasterService from '../../services/MasterService';
import Loader from '../common/loader';

const ViewMappedRegions = (props) => {
    let { user_id } = useParams()
    const [loading, setLoading] = useState(false);
    const [mappedRegions, setMappedRegions] = useState([]);

    useEffect(() => {
        if (user_id) {
            getMappedRegions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getMappedRegions = () => {
        setLoading(true);
        MasterService.post('/sfa/user/get-state-and-city-by-userid', { user_id })
            .then(function (response) {
                setMappedRegions(response?.data?.data?.stateList || []);
                setLoading(false);
            })
            .catch(function (response) {
                setLoading(false);
            });
    }

    return (
        <React.Fragment>
            {loading ? <Loader /> : null}
            <div className='mapped-regions-outer'>
                {mappedRegions && mappedRegions.length ? mappedRegions.map((mappedRegion) => {
                    return (

                        <div className='mapped-regions-data' >
                            <div className='mapped-regions-heading'>
                                <h2>{mappedRegion.state_name}</h2>
                                <span>({mappedRegion.city_count} cities)</span>
                            </div>

                            <ul style={{ backgroundColor: "rgba(255,255,255)", padding: "10px 15px" }}>
                                {mappedRegion.cityList.map((city) => {
                                    return (
                                        <li>
                                            {city}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>

                    )
                }) : <div><label>No Record Found</label></div>}
            </div>

        </React.Fragment>
    )
}
export default ViewMappedRegions;