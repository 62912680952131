import React, { Component } from 'react';
// import { BrowserRouter, Route, Switch, Router, NavLink } from 'react-router-dom';//removed warning
import { BrowserRouter, NavLink } from 'react-router-dom';
import Select, { components } from 'react-select';
import { JoiningDate, Anniversary, DOBDate } from './DatePicker'
import Img_preview from "../../webroot/images/preview_img.png";


const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const EmplomentType = [
    { value: 'Permanent', label: 'Permanent' },
    { value: 'On Roll', label: 'On Roll' },
];
const City = [
    { value: 'Gurgaon', label: 'Gurgaon' },
    { value: 'Nodia', label: 'Nodia' },
];

const State = [
    { value: 'Delhi', label: 'Delhi' },
    { value: 'Haryana', label: 'Haryana' },
];

const CurrentOccupation = [
    { value: 'Value', label: 'Value' },
    { value: 'Value 1', label: 'Value 1' },
];
const Company = [
    { value: 'Value', label: 'Value' },
    { value: 'Value 1', label: 'Value 1' },
];

const Designation = [
    { value: 'Value', label: 'Value' },
    { value: 'Value 1', label: 'Value 1' },
];

const Source = [
    { value: 'Value', label: 'Value' },
    { value: 'Value 1', label: 'Value 1' },
];

const BusinessLine = [
    { value: 'Value', label: 'Value' },
    { value: 'Value 1', label: 'Value 1' },
];

const ReferralName = [
    { value: 'Agent 2 - OTO00123', label: 'Agent 2 - OTO00123' },
    { value: 'Agent 2 - OTO00173', label: 'Agent 2 - OTO00123' },
];
class BasicDetailUpdated extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        this.state = {

        };
    }


    render() {

        return (
            <div className='add-user-outer'>
                <BrowserRouter>
                    <div className="add-user-form-sec">
                        <div className="menu-tab-left-panel">
                            <div className="add-user-left-panel nav">
                                <ul>
                                    <li>
                                        <NavLink to="/RoleDetails" className="completed active" aria-current="page" exact>
                                            <span className="img-type"></span>
                                            Role Details
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/BasicDetails" className="">
                                            <span className="img-type"></span>
                                            Basic Details
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/LeadDetailsForm" className="">
                                            <span className="img-type"></span>
                                            Documents
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/LeadDetailsForm" className="">
                                            <span className="img-type"></span>
                                            Preview and Confirm
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="route-files">
                            <div className='basic-detail-form p-xxl add-user-detail-filed'>
                                <h2>Basic Details</h2>
                                <div className='add-profile-photo'>
                                    <span className='add-img-txt'>
                                        Add Profile Photo
                                    </span>
                                    <span className='subheading'>Only jpg and png files are accepted</span>
                                    <div className='add-photo-btn'>
                                        <div className='preview-img'>
                                            <img alt="" src={Img_preview} className="" />
                                        </div>
                                        <div className="fileUpload">
                                            <input type="file" className="upload" />
                                            <span>Reupload Photo</span>
                                        </div>
                                    </div>
                                </div>

                                <h3>Personal Details</h3>

                                <div className="row">
                                    <fieldset className="col-md-4">
                                        <div className="material">
                                            <input id="" type="text" placeholder=" " name="Username" className="form-input" />
                                            <label data-label="Username" className="form-label"></label>
                                        </div>
                                    </fieldset>
                                    <fieldset className="col-md-4">
                                        <div className="material">
                                            <input id="" type="text" placeholder=" " name="Mobile" className="form-input" />
                                            <label data-label="Mobile" className="form-label"></label>
                                        </div>
                                    </fieldset>
                                    <fieldset className="col-md-4">
                                        <div className="material">
                                            <input id="" type="text" placeholder=" " name="Email" className="form-input" />
                                            <label data-label="Email" className="form-label"></label>
                                        </div>
                                    </fieldset>

                                    <fieldset className="col-md-4">
                                        <div className="material">
                                            <input id="" type="text" placeholder=" " name="KTPID" className="form-input" />
                                            <label data-label="KTP ID" className="form-label"></label>

                                        </div>
                                    </fieldset>

                                    <fieldset className="single-select col-md-4">
                                        <div className="material">
                                            <Select
                                                components={{ ValueContainer: CustomValueContainer }}
                                                // components={{ IndicatorSeparator:() => null }}
                                                options={EmplomentType}
                                                placeholder="Employment Type"
                                                className="react-select"
                                                classNamePrefix="react-select"
                                            />
                                        </div>
                                    </fieldset>
                                    <fieldset className="col-md-4">
                                        <div className="material">
                                            <input id="" type="text" placeholder=" " name="EmployeeID" className="form-input" />
                                            <label data-label="Employee ID" className="form-label"></label>
                                        </div>
                                    </fieldset>
                                    <fieldset className="col-md-4">
                                        <div className="material">
                                            <JoiningDate />
                                        </div>
                                    </fieldset>
                                    <fieldset className="col-md-4">
                                        <div className="material">
                                            <Anniversary />
                                        </div>
                                    </fieldset>
                                </div>
                                <h3>Bank Details</h3>
                                <div className="row">
                                    <fieldset className="col-md-4">
                                        <div className="material">
                                            <input id="" type="text" placeholder=" " name="BankName" className="form-input" />
                                            <label data-label="Bank Name" className="form-label"></label>
                                        </div>
                                    </fieldset>

                                    <fieldset className="col-md-4">
                                        <div className="material">
                                            <input id="" type="text" placeholder=" " name="Branch" className="form-input" />
                                            <label data-label="Branch" className="form-label"></label>
                                        </div>
                                    </fieldset>
                                    <fieldset className="col-md-4">
                                        <div className="material">
                                            <input id="" type="text" placeholder=" " name="AccountNumber" className="form-input" />
                                            <label data-label="Account Number" className="form-label"></label>
                                        </div>
                                    </fieldset>
                                    <fieldset className="col-md-4">
                                        <div className="material">
                                            <input id="" type="text" placeholder=" " name="BeneficiaryName" className="form-input" />
                                            <label data-label="Beneficiary Name" className="form-label"></label>

                                        </div>
                                    </fieldset>
                                    <fieldset className="col-md-4">
                                        <div className="material">
                                            <input id="" type="text" placeholder=" " name="NPWP" className="form-input" />
                                            <label data-label="NPWP" className="form-label"></label>
                                        </div>
                                    </fieldset>
                                </div>

                                <h3>Other Details</h3>
                                <div className="row">
                                    <fieldset className="col-md-4">
                                        <div className="material">
                                            <DOBDate />
                                        </div>
                                    </fieldset>
                                    <fieldset className="single-select col-md-4">
                                        <div className="material">
                                            <Select
                                                components={{ ValueContainer: CustomValueContainer }}
                                                // components={{ IndicatorSeparator: () => null }}
                                                options={City}
                                                placeholder="City"
                                                className="react-select"
                                                classNamePrefix="react-select"
                                            />
                                        </div>
                                    </fieldset>
                                    <fieldset className="single-select col-md-4">
                                        <div className="material">
                                            <Select
                                                components={{ ValueContainer: CustomValueContainer }}
                                                // components={{ IndicatorSeparator: () => null }}
                                                options={State}
                                                placeholder="State"
                                                className="react-select"
                                                classNamePrefix="react-select"
                                            />
                                        </div>
                                    </fieldset>
                                    <fieldset className="single-select col-md-4">
                                        <div className="material">
                                            <Select
                                                components={{ ValueContainer: CustomValueContainer }}
                                                // components={{ IndicatorSeparator: () => null }}
                                                options={CurrentOccupation}
                                                placeholder="CurrentOccupation"
                                                className="react-select"
                                                classNamePrefix="react-select"
                                            />
                                        </div>
                                    </fieldset>
                                    <fieldset className="single-select col-md-4">
                                        <div className="material">
                                            <Select
                                                components={{ ValueContainer: CustomValueContainer }}
                                                // components={{ IndicatorSeparator: () => null }}
                                                options={Company}
                                                placeholder="Company"
                                                className="react-select"
                                                classNamePrefix="react-select"
                                            />
                                        </div>
                                    </fieldset>
                                    <fieldset className="single-select col-md-4">
                                        <div className="material">
                                            <Select
                                                components={{ ValueContainer: CustomValueContainer }}
                                                // components={{ IndicatorSeparator: () => null }}
                                                options={Designation}
                                                placeholder="Designation"
                                                className="react-select"
                                                classNamePrefix="react-select"
                                            />
                                        </div>
                                    </fieldset>

                                    <fieldset className="col-md-4">
                                        <div className="material">
                                            <input id="" type="text" placeholder=" " name="Tagging" className="form-input" />
                                            <label data-label="Tagging" className="form-label"></label>
                                        </div>
                                    </fieldset>

                                    <fieldset className="single-select col-md-4">
                                        <div className="material">
                                            <Select
                                                components={{ ValueContainer: CustomValueContainer }}
                                                // components={{ IndicatorSeparator: () => null }}
                                                options={BusinessLine}
                                                placeholder="Business Line"
                                                className="react-select"
                                                classNamePrefix="react-select"
                                            />
                                        </div>
                                    </fieldset>
                                    <fieldset className="single-select col-md-4">
                                        <div className="material">
                                            <Select
                                                components={{ ValueContainer: CustomValueContainer }}
                                                // components={{ IndicatorSeparator: () => null }}
                                                options={Source}
                                                placeholder="Source"
                                                className="react-select"
                                                classNamePrefix="react-select"
                                            />
                                        </div>
                                    </fieldset>
                                    <fieldset className="col-md-4">
                                        <div className="material">
                                            <input id="" type="text" placeholder=" " name="PostalCode" className="form-input" />
                                            <label data-label="Postal Code" className="form-label"></label>

                                        </div>
                                    </fieldset>


                                    <fieldset className="col-md-4">
                                        <div className="material">
                                            <input id="" type="text" placeholder=" " name="HouseNo" className="form-input" />
                                            <label data-label="House No." className="form-label"></label>
                                        </div>
                                    </fieldset>
                                    <fieldset className="col-md-8">
                                        <div className="material">
                                            <input id="" type="text" placeholder=" " name="Tagging" className="form-input" />
                                            <label data-label="Address" className="form-label"></label>
                                        </div>
                                    </fieldset>

                                </div>

                                <h3>Set Password</h3>
                                <div className="row">

                                    <fieldset className="col-md-4">
                                        <div className="material info-icn">
                                            <input id="" type="text" placeholder=" " name="Password" className="form-input" />
                                            <label data-label="Password" className="form-label"></label>
                                            <i className='ic-show-password'>
                                                <i className='path1'></i>
                                                <i className='path2'></i>
                                                <i className='path3'></i>
                                                <i className='path4'></i>
                                            </i>
                                        </div>
                                    </fieldset>
                                    <fieldset className="col-md-4">
                                        <div className="material info-icn">
                                            <input id="" type="text" placeholder=" " name="ConfirmPassword" className="form-input" />
                                            <label data-label="Confirm Password" className="form-label"></label>
                                            <i className='ic-show-password'>
                                                <i className='path1'></i>
                                                <i className='path2'></i>
                                                <i className='path3'></i>
                                                <i className='path4'></i>
                                            </i>
                                        </div>
                                    </fieldset>
                                    <fieldset className="single-select col-md-4">
                                        <div className="material">
                                            <Select
                                                components={{ ValueContainer: CustomValueContainer }}
                                                // components={{ IndicatorSeparator: () => null }}
                                                options={ReferralName}
                                                placeholder="Referral Name & ID"
                                                className="react-select"
                                                classNamePrefix="react-select"
                                            />
                                        </div>
                                    </fieldset>
                                </div>
                                <div className="br-top t-right">
                                    <button className='btn-primary'>
                                        Save & Continue
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </BrowserRouter>
            </div>
        )
    }
}
export default BasicDetailUpdated;