import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { minDateYear } from "../../config/constant";

function Calendra2() {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    return (
        <div className="datepicker">
            <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={(update) => {
                    setDateRange(update);
                }}
                isClearable={false}
                placeholderText="Created Date "
            />
        </div>
    );
}


function Calendra() {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    return (
        <div className="datepicker">
            <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={(update) => {
                    setDateRange(update);
                }}
                isClearable={false}
                placeholderText="Approved Date"
            />
        </div>
    );
}

function AppDisbursedDate() {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    return (
        <div className="datepicker">
            <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={(update) => {
                    setDateRange(update);
                }}
                isClearable={false}
                placeholderText="App Disbursed Date"
            />
        </div>
    );
}


function AppReceivedDate() {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    return (
        <div className="datepicker">
            <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={(update) => {
                    setDateRange(update);
                }}
                isClearable={false}
                placeholderText="App Received Date"
            />
        </div>
    );
}




// function Calendra() {
//     // const [value, onChange] = useState(new Date());
//     const [startDate, setStartDate] = useState(null);

//     return (
//         <div className="datepicker">
//             <DatePicker
//                 selected={startDate}
//                 onChange={(date) => setStartDate(date)}
//                 peekNextMonth
//                 showMonthDropdown
//                 showYearDropdown
//                 dropdownMode="select"
//             />
//         </div>
//     );
// }

function FollowUpDate() {
    // const [value, onChange] = useState(new Date());
    const [startDate, setStartDate] = useState(null);

    return (
        <div className="datepicker">
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="Follow-up Date"
            />
            {/* <label data-label="Start Date" class="form-label"></label> */}
        </div>
    );
}
function DOBDate(props) {

    return (
        <div className="datepicker">
            <DatePicker
                dateFormat="yyyy-MM-dd"
                minDate={new Date(minDateYear)}
                maxDate={new Date()}
                selected={props.value ? props.value : null}
                onChange={(date) => props.handleDateChange(date, "date_of_birth")}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="DOB (YYYY-MM-DD)*"
            />
            {/* <label data-label="Start Date" class="form-label"></label> */}
        </div>
    );
}



function Timepicker() {
    const [startDate, setStartDate] = useState(new Date());
    return (
        <div className="timepicker-filed">
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                placeholderText="Follow-up Time"
            />
            {/* <label data-label="  Follow-up Time" class="form-label datepick-heading"></label> */}

        </div>
    );
}


function JoiningDate(props) {
    return (
        <div className="datepicker">
            <DatePicker
                maxDate={new Date()}
                minDate={new Date(minDateYear)}
                dateFormat="yyyy-MM-dd"
                selected={props.value ? props.value : null}
                onChange={(date) => props.handleDateChange(date, "joining_date")}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="Joining Date*"
            // showDisabledMonthNavigation
            />
            {/* <label data-label="Date/Time" class="form-label"></label> */}
        </div>
    );
}

function Calender3(props) {
    return (
        <div className="datepicker">
            <DatePicker
                maxDate={new Date()}
                minDate={new Date(minDateYear)}
                dateFormat="yyyy-MM-dd"
                selected={props.value ? props.value : null}
                onChange={(date) => props.handleDateChange(date, props.name)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText={props.text}
                disabled={props.disabled ? props.disabled : false}
            // showDisabledMonthNavigation
            />
            {/* <label data-label="Date/Time" class="form-label"></label> */}
        </div>
    );
}


function Anniversary(props) {
    return (
        <div className="datepicker">
            <DatePicker
                maxDate={new Date()}
                minDate={new Date(minDateYear)}
                dateFormat="yyyy-MM-dd"
                selected={props.value ? props.value : null}
                onChange={(date) => props.handleDateChange(date, "anniversary_date")}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="Anniversary"
            // showDisabledMonthNavigation
            />
        </div>
    );
}



export { Calendra, Calendra2, Calender3, FollowUpDate, Timepicker, DOBDate, JoiningDate, Anniversary, AppReceivedDate, AppDisbursedDate }