import { CommonConstants } from '../constants/common.constants'; 

 
export default function CommonReducer(state = {}, action) { 
  switch (action.type) { 
    case CommonConstants.STATE_LIST_REQUEST:
      return {
        ...state,
        stateList: action.payload,
      };  
      
    case CommonConstants.USER_LIST_REQUEST:
      return {
        ...state,
        userList: action.payload,
      };  

    case CommonConstants.SCHEME_LIST_REQUEST: 
      return {
        ...state,
        schemeList: action.payload,
      }; 
    case CommonConstants.GET_ROLE_LIST: 
      return {
        ...state,
        getRoleList: action.payload,
      };
    case CommonConstants.FINANCIER_LIST_REQUEST: 
      return {
        ...state,
        financierList: action.payload,
      };  
      
    case CommonConstants.ROLEWISE_SCHEME_LIST: 
      return {
        ...state,
        roleSchemeList: (state && state.roleSchemeList) ? {...state.roleSchemeList, ...action.payload} : action.payload
      };

    case CommonConstants.BM_USER_LIST: 
      return {
        ...state,
        bmUserList: action.payload,
      }; 
    
    case CommonConstants.ROLE_PRODUCTS_LIST: 
      return {
        ...state,
        roleProductList: action.payload,
      }; 

    default:
      return state
  }
}