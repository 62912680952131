function Loader() {
    return (
      <div
        className="loader loader-default is-active loader-main loader-fullscreen"
        role="status"
      >
        {" "}
      </div>
    );
}
export default Loader;