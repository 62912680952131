import ToggleSwitch from "../element/ToggleSwitch";

const CustomNotificationHistory = ({ notificationList, deactivateNotification, handleEditNotification }) => {
    return (
        <div>
            <div className="data-table">
                <table>
                    <thead>
                        <tr>
                            <th>Created Date</th>
                            <th>Created By</th>
                            <th>Business Line</th>
                            <th>Notification Name</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {notificationList && notificationList.length ? (
                            notificationList.map((item, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td>{item.created_date}</td>
                                        <td>{item.display_name}</td>
                                        <td>{item?.business_line || 'Custom'}</td>
                                        <td>{item.name}</td>
                                        <td>
                                            <ToggleSwitch
                                                toggleChecked={item.is_active === "1"}
                                                onClick={() => {
                                                    deactivateNotification(item);
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <ul className="action-btn">
                                                <li>
                                                    <a
                                                        className="btn btn-default"
                                                        onClick={() => {
                                                            handleEditNotification(item);
                                                        }}
                                                    >
                                                        <i className="ic-remove_red_eyevisibility"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={5}>No Records Found</td>
                            </tr>
                        )}
                        <tr></tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CustomNotificationHistory;
