import React, { useState, useEffect } from 'react';
import { Tab } from 'react-bootstrap';
import loanbox_Illustration from "../../webroot/images/sfa_node_bg.svg";
import logo from "../../webroot/images/logo.svg";
import loanbox_Illustration_fav from "../../webroot/images/sfa_node_bg-fav.svg";
import loanbox_Illustration_tri from "../../webroot/images/sfa_node_bg-tri.svg";
import logo_fav from "../../webroot/images/logo-fav.svg";
import logo_tri from "../../webroot/images/logo-tri.svg";
import ls from "local-storage";
import { MasterService } from '../../services';
import { toast } from 'react-toastify';
import InputField from '../element/Input';
import Captcha from '../common/Captcha';
import Loader from '../common/loader';
import { EMAIL_VALIDATION, TENANT } from '../../config/constant';

const Login = (props) => {
    const [loading, setLoading] = useState(false);
    const [errors, SetErrors] = useState({});
    const [login_detail, SetLoginDetail] = useState({});
    const [reloadCaptcha, setReloadCaptcha] = useState(0);
    const [showOtp, setShowOtp] = useState(false);
    const [otpTimer, setOtpTimer] = useState(60);
    const [otpValue, setOtpValue] = useState('');
    const [inputFieldCaptcha, SetInputFieldCaptch] = useState({ captcha: "" });

    useEffect(() => {
        let header = document.getElementsByClassName("header")
        header[0].classList.add("hidden")
        let footer = document.getElementsByClassName("footer")
        footer[0].classList.add("hidden")
    }, []);
    ls.set('filter_data', {});
    ls.set('current_status', '');

    useEffect(() => {
        if (!otpTimer) return;

        const intervalId = setInterval(() => {
            setOtpTimer(otpTimer - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [otpTimer])

    const handleLogin = async () => {
        let res = await isValidate()
        if (res) {
            setLoading(true);
            let params = {
                captchaId: login_detail.captchaId,
                captchaVal: login_detail.captchaVal,
                username: login_detail.login_username,
            }
            MasterService.post('/sfa/user/remove-info', params)
                .then(function (response) {
                    if (response.data.status === 200) {
                        if (response.data.data) {

                            handleSendOTP()
                        } else if (response.data.error && response.data.error.length > 0) {
                            response.data.error.forEach(element => {
                                setReloadCaptcha(reloadCaptcha + 1)
                                resetCaptcha()
                                toast.error(element);
                                setLoading(false)
                            });
                        }
                    } else {
                        setReloadCaptcha(reloadCaptcha + 1)
                        resetCaptcha()
                        if (response.data.error && response.data.error.length > 0) {
                            response.data.error.forEach(element => {
                                toast.error(element);
                                setLoading(false)
                            });
                        } else if (response.data.message) {
                            toast.error(response.data.message);
                            setLoading(false)
                        } else {
                            setLoading(false);
                            toast.error("Failed");
                        }
                    }
                })
                .catch(function (error) {
                    toast.error(error);
                    setLoading(false);
                });
        }
    }

    const resetCaptcha = () => {
        let tempLoginDetail = { ...login_detail };
        tempLoginDetail.captchaVal = ""
        SetLoginDetail(tempLoginDetail)
    }

    const isValidate = async (value) => {
        let tempLoginDetail = { ...login_detail };
        let formIsValid = true;
        let tempErrors = {};
        if (value === "send_otp") {
            if (!tempLoginDetail["login_username"]) {
                formIsValid = false;
                tempErrors["login_username"] = 'Email is required';
            } else if (tempLoginDetail["login_username"].match(EMAIL_VALIDATION) == null) {
                tempErrors["login_username"] = 'Email is not valid';
            }
        } else if (value === "validate_otp_captcha") {
            if (!tempLoginDetail["login_username"]) {
                formIsValid = false;
                tempErrors["login_username"] = 'Email is required';
            } else if (tempLoginDetail["login_username"].match(EMAIL_VALIDATION) == null) {
                tempErrors["login_username"] = 'Email is not valid';
            }

            if (!tempLoginDetail["otp"]) {
                formIsValid = false;
                tempErrors["otp"] = 'OTP is required';
            }

            if (!tempLoginDetail["captchaId"] || !tempLoginDetail["captchaVal"]) {
                formIsValid = false;
                tempErrors["captcha"] = 'Captcha is required';
            }
        } else if (value === "validate_otp") {
            if (!tempLoginDetail["otp"]) {
                formIsValid = false;
                tempErrors["otp"] = 'OTP is required';
            }
        } else {
            if (!tempLoginDetail["login_username"]) {
                formIsValid = false;
                tempErrors["login_username"] = 'Email is required';
            }

            // if (!tempLoginDetail["password"]) {
            //     formIsValid = false;
            //     tempErrors["password"] = 'Password is required';
            // }

            if (!tempLoginDetail["captchaId"] || !tempLoginDetail["captchaVal"]) {
                formIsValid = false;
                tempErrors["captcha"] = 'Captcha is required';
            }
        }

        if (Object.keys(tempErrors).length > 0) {
            formIsValid = false
        }
        SetErrors(tempErrors);
        return formIsValid;
    }

    const handleInputChange = async (target_name, type, event) => {
        let temp_login_details = { ...login_detail };
        let tempErrors = { ...errors }
        temp_login_details[target_name] = event.target.value;
        tempErrors[target_name] = ""
        SetErrors(tempErrors)
        SetLoginDetail(temp_login_details);
    }

    const captchaFiledHandler = (childData) => {
        let captchaId = childData?.captchaData?.data?.value;

        let tempErrors = { ...errors }
        let temp_login_details = { ...login_detail };
        if (captchaId) {
            tempErrors['captchaErr'] = "";
            temp_login_details['captchaVal'] = childData.captchaVal;
            temp_login_details['captchaId'] = childData.captchaData.data.value;
        } else {
            tempErrors['captchaErr'] = "Please enter valid captcha.";
        }
        SetLoginDetail(temp_login_details);
        SetErrors(tempErrors)
    }

    const handleSendOTP = async () => {
        let res = await isValidate("send_otp")
        if (res) {
            setLoading(true);
            let params = {
                username: login_detail.login_username
            }
            MasterService.post('/sfa/user/send_admin_otp', params)
                .then(function (response) {
                    if (response.data.status === 200) {
                        if (response.data.data && response.data.data.value) {
                            setShowOtp(true)
                            setOtpValue(response.data.data.value)
                            setLoading(false);
                            setOtpTimer(60)
                            toast.success(response.data.message)
                        } else if (response.data.error && response.data.error.length > 0) {
                            response.data.error.forEach(element => {
                                toast.error(element);
                                setLoading(false)
                            });
                        }
                    } else {
                        if (response.data.error && response.data.error.length > 0) {
                            response.data.error.forEach(element => {
                                toast.error(element);
                                setLoading(false)
                            });
                        } else if (response.data.message) {
                            toast.error(response.data.message);
                            setLoading(false)
                        } else {
                            setLoading(false);
                            toast.error("Failed");
                        }
                    }
                })
                .catch(function (error) {
                    console.log("=================error==============",error)
                    toast.error(error);
                    setLoading(false);
                });
        }else{
            setLoading(false);
        }
    }

    const handleSubmit = async (with_captcha) => {
        let res = with_captcha ? await isValidate("validate_otp_captcha") : await isValidate("validate_otp")
        if (res) {
            setLoading(true);
            // toast.error("Wrong OTP");
            toast.success("Your request has been submitted successfully, you will get a confirmation email shortly.")
            setLoading(false);
            setShowOtp(false);
        }
    }

    return (
        <div className="container-fluid">
            {loading ? <Loader /> : null}
            <div className="row">

                <div className='login-main'>

                    <div className="login-outer">
                        <div className="login-left-panel">
                            <div className={(TENANT=="id") ? "login-bg" :(TENANT=="tri-id")? "login-bg login-bg-tri":"login-bg login-bg-fav"}>

                                <div className="logo-img">
                                    {<img alt="" src={(TENANT=="id") ? logo :(TENANT=="tri-id")? logo_tri : logo_fav} className="" />}
                                </div>
                                <div className={(TENANT=="tri-id")?'login-txt-tri':'login-txt'}>

                                    {(TENANT=="id") ? <h1>Welcome to OTO SFA</h1> :(TENANT=="tri-id")? <h1>Building Bridges for Your Journey!</h1>:<h1>Welcome to Favorite</h1>}
                                    {TENANT === 'fav-id' ? <span>Easily explore your options and find the best refinancing deal tailored to your needs</span> : ''}
                                </div>
                                <div className={(TENANT=="tri-id")?'login-bg-img-tri':'login-bg-img'}>
                                    {<img alt="" src={(TENANT=="id") ? loanbox_Illustration :(TENANT=="tri-id")? loanbox_Illustration_tri : loanbox_Illustration_fav} className="" />}
                                </div>
                            </div>
                        </div>
                        <div className="login-right-panel">
                            <div className="login-heading">
                                <h2>Request data deletion</h2>
                            </div>
                            <Tab.Container id="" defaultActiveKey="first">
                                <div className="login-tab">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <div className={(TENANT=="id") ? "login-form" :(TENANT=="tri-id")? "login-form login-form-tri":"login-form login-form-fav"}>
                                                {!showOtp &&
                                                    <div>
                                                        <fieldset className="">
                                                            <div className={errors.login_username ? "material error" : "material"}>
                                                                <InputField type="text" name="login_username" id="login_username" label="Email" placeholder=" " value={login_detail.login_username} onChange={handleInputChange.bind(null, 'login_username', 'text')} required={true} />
                                                                <i className="ic-email1 icn-login"></i>
                                                                <label data-label="Email*" className="form-label"></label>
                                                                {errors.login_username && <span className="erroe-txt">{errors.login_username}</span>}
                                                            </div>
                                                        </fieldset>
                                                        {/* <fieldset className="">
                                                            <div className={errors.password ? "material error" : "material"}>
                                                                <InputField type="password" name="password" id="password" label="Password" placeholder=" " value={login_detail.password} onChange={handleInputChange.bind(null, 'password', 'text')} required={true} />
                                                                <i className="ic-password icn-login"></i>
                                                                <label data-label="Password*" className="form-label"></label>
                                                                {errors.password && <span className="erroe-txt">{errors.password}</span>}
                                                            </div>
                                                        </fieldset> */}
                                                        <fieldset className="">
                                                            <div className={errors.captcha ? "material error" : "material"}>
                                                                <Captcha className="form-input" onKeyPressCaptcha={captchaFiledHandler} errField={errors} inputField={inputFieldCaptcha} reloadCaptcha={reloadCaptcha} />
                                                                {errors.captcha && <span className="erroe-txt">{errors.captcha}</span>}
                                                            </div>
                                                        </fieldset>
                                                        <button type="button" onClick={handleLogin.bind(this)} className="btn-primary">Log In</button>
                                                    </div>
                                                }
                                                {showOtp &&
                                                    <div>
                                                        <fieldset className="">
                                                            <div className={errors.otp ? "material error" : "material"}>
                                                                <InputField type="text" name="otp" id="otp" label="OTP" placeholder=" " value={login_detail.otp} onChange={handleInputChange.bind(null, 'otp', 'text')} required={true} />
                                                                <i className="ic-otp icn-login">
                                                                    <i className="path1"></i>
                                                                    <i className="path2"></i>
                                                                    <i className="path3"></i>
                                                                    <i className="path4"></i>
                                                                </i>
                                                                <label data-label="OTP" className="form-label"></label>
                                                                {errors.otp && <span className="erroe-txt">{errors.otp}</span>}
                                                                {otpTimer > 0 ?
                                                                    <span className="otp-time">{"00:" + (otpTimer > 9 ? otpTimer : "0" + otpTimer)}</span> :
                                                                    <span className="otp-time" style={{ cursor: "pointer" }} onClick={() => { handleSendOTP() }}>Resend</span>
                                                                }

                                                                <div className="otp-success-icon" style={{ display: "none" }} >
                                                                    <i className="ic-check"></i>
                                                                </div>

                                                            </div>
                                                        </fieldset>
                                                        <button type="button" className="btn-primary" onClick={() => { handleSubmit(false) }}>Submit</button>
                                                    </div>
                                                }
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <div className="login-form">
                                                <fieldset className="">
                                                    <div className={errors.login_username ? "material error" : "material"}>
                                                        <InputField type="text" name="login_username" id="login_username" label="Email" placeholder=" " value={login_detail.login_username} onChange={handleInputChange.bind(null, 'login_username', 'text')} required={true} />
                                                        <i className="ic-email1 icn-login"></i>
                                                        <label data-label="Email" className="form-label"></label>
                                                        {errors.login_username && <span className="erroe-txt">{errors.login_username}</span>}
                                                    </div>
                                                </fieldset>
                                                {showOtp &&
                                                    <div>
                                                        <fieldset className="">
                                                            <div className={errors.otp ? "material error" : "material"}>
                                                                <InputField type="text" name="otp" id="otp" label="OTP" placeholder=" " value={login_detail.otp} onChange={handleInputChange.bind(null, 'otp', 'text')} required={true} />
                                                                <i className="ic-otp icn-login">
                                                                    <i className="path1"></i>
                                                                    <i className="path2"></i>
                                                                    <i className="path3"></i>
                                                                    <i className="path4"></i>
                                                                </i>
                                                                <label data-label="OTP" className="form-label"></label>
                                                                {errors.otp && <span className="erroe-txt">{errors.otp}</span>}
                                                                {otpTimer > 0 ?
                                                                    <span className="otp-time">{"00:" + (otpTimer > 9 ? otpTimer : "0" + otpTimer)}</span> :
                                                                    <span className="otp-time" style={{ cursor: "pointer" }} onClick={() => { handleSendOTP() }}>Resend</span>
                                                                }

                                                                <div className="otp-success-icon" style={{ display: "none" }} >
                                                                    <i className="ic-check"></i>
                                                                </div>

                                                            </div>
                                                        </fieldset>
                                                        <fieldset className="">
                                                            <div className={errors.captcha ? "material error" : "material"}>
                                                                <Captcha className="form-input" onKeyPressCaptcha={captchaFiledHandler} errField={errors} inputField={inputFieldCaptcha} reloadCaptcha={reloadCaptcha} />
                                                                {errors.captcha && <span className="erroe-txt">{errors.captcha}</span>}
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                }
                                                {!showOtp && <button type="button" className="btn-primary" onClick={() => { handleSendOTP() }}>Get OTP</button>}
                                                {showOtp && <button type="button" className="btn-primary" onClick={() => { handleSubmit(true) }}>Submit</button>}
                                            </div>
                                        </Tab.Pane>


                                    </Tab.Content>
                                </div>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Login;