import React, { useEffect, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import Select, { components } from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import { Zone, BasicDetailConfig, Role, validationForFields, imageWidth, EMAIL_VALIDATION, defaultZone, EmploymentType, RoleId, ADMIN_ROLES,REJECT_STATUS, INACTIVE_STATUS,TENANT,MOBILE_VALIDATION } from '../../config/constant';
import MasterService from '../../services/MasterService';
import DateFormat from 'dateformat';
import ViewMappedRegions from './ViewMappedRegions';
import ViewReportees from './ViewReportees';
import SimpleSlider from './uploadDocSlider';
// import { DealerService } from '../../services';//removed warning
import { toast } from 'react-toastify';
import Loader from '../common/loader';
import HierarchyDetails from './HierarchyPopUp';
import HierarchyIcon from "../../webroot/images/hierarchy_icon.png";
import InputField from '../common/Input';
import NumberFormat from 'react-number-format';
// import secureStorage from './../../config/encrypt';//removed warning
import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy';
import WithRights from '../common/WithRights';
import { useHasRight } from '../hooks/useHasRights'
import AgentHierarchyDetails from './AgentHierarchyDetails';
import IfSimpleSlider from './uploadIfDocSlider';
import downloadIcon from "../../webroot/images/download.png";

const ViewUser = (props) => {
    let { user_id } = useParams()
    // let { stateList, cityList } = props
    const [loading, setLoading] = useState(false);
    const [cityList, setCityList] = useState([])
    const [localityList, setLocalityList] = useState([])
    const [stateList, setStateList] = useState([])
    const [userDetail, setUserDetail] = useState({})
    const [roleList, setRoleDetails] = useState([])
    const [showEdit, setshowEdit] = useState(false)
    const [activeClass, setActiveClass] = useState(false)
    const [reportingRoleList, setReportingRoleList] = useState([])
    const [existing_errors, SetExistingErrors] = useState({});
    const [is_existing, setIsExisting] = useState(false);
    const [ReferralName, setReferralName] = useState([])
    const [ConfigList, setConfigList] = useState([])
    const [reportingManagerList, setReportingManagerList] = useState([])
    const [bankList, setBankList] = useState([])
    const [bankOption, setBankOption] = useState([])
    const [UserHistoryPopup, SetUserHistoryPopup] = useState(false);
    const [UserRoleHistoryList, setUserRoleHistoryList] = useState([]);
    const [UserAccountHistoryList, setUserAccountHistoryList] = useState([]);
    const [VendorHistoryList, setVendorHistoryList] = useState([]);
    const [ProgressionHistoryList, setProgressionHistoryList] = useState([]);
    const [showMappedRegions, setShowMappedRegions] = useState(false);
    const [showReportees, setShowReportees] = useState(false);
    // const [show_bank_detail, setShowBankDetail] = useState(false);//removed warning
    // const [show_ktp_detail, setShowKTPDetail] = useState(false);//removed warning
    const [show_image_detail, setShowImageDetail] = useState(false);
    // const [doc_index, setDocIndex] = useState(0);//removed warning
    const [activeIndex, setActiveIndex] = useState(0)
    const [rotated_angle, setRotateAngle] = useState(0);
    const [image_width, setImageWidth] = useState(imageWidth);
    const [show_doc, setShowDoc] = useState(false);
    const [errors, SetErrors] = useState({});
    const { ValueContainer, Placeholder } = components;
    const [userLocationDetail, setUserLocationDetail] = useState({})
    const [showHierarchyDetailsModal, setShowHierarchyDetailsModal] = useState(false);
    const [showAgentHierarchyECA, setShowAgentHierarchyECA] = useState(false);
    // const [esignApprovedHTML, setEsignApprovedHTML] = useState([]);//removed warning
    const [esignUserDoc, setEsignUserDoc] = useState([]);
    const [commitmentForm, setCommitmentForm] = useState([]);
    const [terminationForm, setTerminationForm] = useState([]);
    // const [passwordToShow, setPasswordToShow] = useState('');//removed warning
    // const [showPassword, setShowPassword] = useState(false);
    // const [loginUserInfoTemp, SetLoginUserInfoTemp] = useState(secureStorage.getItem('loginUserInfo'));//removed warning
    const [account_length, setAccountLength] = useState(0)
    const [BMGradingHistoryList, setBMGradingHistoryList] = useState([]);
    const [enable_bank_info, setEnableBankInfo] = useState(true)
    const [designationList, setDesignationList] = useState([])
    const [rejectionReasonModal, setRejectionReasonModal] = useState(false)
    const [reason_for_rejection, setReasonForRejection] = useState(null)
    const [rejection_reason_comment, setRejectionReasonComment] = useState('')
    const [rejection_data, setRejectionData] = useState(null)
    const [tipOpen, setTipOpen] = useState(false);
    const [tipOpenId, setTipOpenId] = useState(0);
    const [AppUsageList, setAppUsageList] = useState([]);
    const [secondaryReportingRoleList, setSecondaryReportingRoleList] = useState([])
    const [secondaryReportingManagerList, setSecondaryReportingManagerList] = useState([])
    const [sources, setSources] = useState([]);
    const [inactiveReasonModal, setInactiveReasonModal] = useState(false)
    const [reason_for_inactive, setReasonForInactive] = useState(null)
    const [inactive_reason_comment, setInactiveReasonComment] = useState('')
    const [inactive_data, setInactiveData] = useState(null)
    const [ifDocList, setIfDocList] = useState([]);
    const [showIfDocDetails, setShowIfDocDetails] = useState(false);
    const [activeIfIndex, setActiveIfIndex] = useState(0)

    const { rightsStatus, adminRoleId } = useHasRight(['edit_user', 'activate_user', 'deactivate_user', 'approve_user', 'reject_user']);

    const historyLead = useNavigate();
    const OnboardedFrom = [
        { value: 'mobile_app', label: 'App' },
        { value: 'join_us', label: 'Join Us' },
        { value: 'admin', label: 'SFA Backend' },
    ];
    useEffect(() => {
        window.addEventListener('scroll', () => {
            let activeClass = 'sticky-top';
            if (window.scrollY === 0) {
                activeClass = 'top';
            }
            setActiveClass(activeClass)
        });

        window.onclick = function (e) {
            var divToHide = document.getElementById('approve_sub_status');
            if (divToHide) {
                document.onclick = function (e) {
                    if (e.target.id !== 'approve_sub_status' && e.target.id !== 'is_call_verified' && e.target.id !== 'is_docs_verified') {
                        divToHide.style.display = 'none';
                    }
                };
            }
            var divToshow = document.getElementById('approve_sub_status');
            var clickedButton = document.getElementById('show_sub_status');
            if (clickedButton) {
                if (e.target.id === 'show_sub_status') {
                    divToshow.style.display = 'block';
                }
            }

            var divToHideOther = document.getElementById('other_reason_tooltip');
            if (divToHideOther) {
                if (e.target.id !== 'other_reason_tooltip') {
                    handleClickAway()
                }
            }
        };

        getStateList();
        getCityList();
        getUserDetail(user_id);
        getUserLocationDetail(user_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // useEffect(() => {
    //     if (userDetail?.password_text) {
    //         if (showPassword) {
    //             setPasswordToShow(userDetail.password_text)
    //         } else {
    //             setPasswordToShow(userDetail.password_text.replace(/./g, '*'))
    //         }
    //     }

    // }, [showPassword, userDetail])

    const getStateList = async () => {
        MasterService.post('/sfa/user/state_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var state_list = response.data.data.map(elm => ({ id: elm.id, label: elm.state_name, value: elm.id }));
                    setStateList(state_list);
                }
            })
            .catch(function (response) {
            });
    }

    const getCityList = async () => {
        MasterService.post('/sfa/user/city_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var city_list = response.data.data.map(elm => ({ id: elm.id, label: elm.city_name, value: elm.id }));
                    setCityList(city_list);
                }
            })
            .catch(function (response) {
            });
    }
    const getUserLocationDetail = async (user_id) => {
        await MasterService.post('/sfa/user/get-user_location', { user_id }).then(function (response) {
            if (response.data.status === 200) {
                let data = response.data.data
                let tempuUserDetail = { ...userLocationDetail };
                let state_data = data.location_response.map(({ state_id }) => state_id)
                state_data = state_data.filter((item, i, ar) => ar.indexOf(item) === i);

                let city_data = data.location_response.map(({ city_id }) => city_id)
                city_data = city_data.filter((item, i, ar) => ar.indexOf(item) === i);
                tempuUserDetail.state_data = state_data
                tempuUserDetail.city_data = city_data
                setUserLocationDetail(tempuUserDetail)
            }
        })
    }
    const getUserDetail = async (user_id) => {
        setLoading(true)
        await MasterService.post('/sfa/user/get-view-detail', { user_id }).then(async function (response) {
            if (response.data.status === 200 && response.data.data) {
                getReferralUserList()
                getRoleList(response.data.data)
                let config_list = await getConfigList(response.data.data)
                getReportingRole(response.data.data)
                getUsersByRole(response.data.data)
                getBankList(response.data.data)
                getIfDocList(response.data.data);
                getSources(response.data.data.role_id);

                let data = response.data.data


                if (data.document_response && data.document_response.length > 0) {
                    let documents = []
                    for (let index = 0; index < config_list.document.length; index++) {
                        const elm = config_list.document[index];
                        let doc_url = ""
                        let doc_original_url = ""
                        let is_doc_verified = 0
                        let edit_verify_status = 1
                        let file_index = data.document_response.findIndex(e => parseInt(e.document_id) === parseInt(elm.id))
                        if (file_index >= 0) {
                            setShowDoc(true)
                            doc_url = data.document_response[file_index].doc_url
                            doc_original_url = data.document_response[file_index].doc_original_url ? data.document_response[file_index].doc_original_url : ""
                            is_doc_verified = data.document_response[file_index].is_doc_verified
                            edit_verify_status = data.document_response[file_index].is_doc_verified ? 0 : 1
                        }
                        let new_doc = {
                            doc_bg_color: elm.doc_bg_color,
                            doc_logo_url: elm.doc_logo_url,
                            doc_max: elm.doc_max,
                            doc_min: elm.doc_min,
                            doc_required: elm.doc_required,
                            doc_title: elm.doc_title,
                            doc_original_url: doc_original_url,
                            doc_url: doc_url,
                            is_doc_verified: is_doc_verified,
                            edit_verify_status: edit_verify_status,
                            document_id: elm.id
                        }
                        documents.push(new_doc)
                    }
                    data.document_response = documents
                } else {
                    var documents = config_list.document.map(elm => ({
                        created_date: elm.created_date,
                        doc_bg_color: elm.doc_bg_color,
                        doc_logo_url: elm.doc_logo_url,
                        doc_max: elm.doc_max,
                        doc_min: elm.doc_min,
                        doc_required: elm.doc_required,
                        doc_title: elm.doc_title,
                        doc_original_url: "",
                        doc_url: "",
                        is_doc_verified: 0,
                        edit_verify_status: 1,
                        document_id: elm.id,
                    }));
                    data.document_response = documents
                }

                await MasterService.getLocalityList(data.user_city).then((res) => {
                    let locality_list = res.data.data && res.data.data.length ? res.data.data.map(elm => ({ id: elm.id, label: elm.value, value: elm.id })) : []
                    setLocalityList(locality_list)
                })

                let occupation_id_data = config_list.current_occupation.filter(e => e.value === data.current_occupation_id)
                let occupation_id = occupation_id_data && occupation_id_data.length ? occupation_id_data[0].id : 0
                if (occupation_id) {
                    await MasterService.post('/sfa/user/designation-list', { occupation_id })
                        .then(function (response) {
                            if (response.data.status === 200) {
                                var designation_list = response.data.data.map(elm => ({ id: elm.value, label: elm.label, value: elm.value }));
                                setDesignationList(designation_list);
                            }
                        })
                        .catch(function (response) {
                        });
                }

                if (data.bank_response && data.bank_response.id) {
                    data.bank_response.confirm_account_number = data.bank_response.account_number
                }

                data.mobile_data = data.mobile_response && data.mobile_response.length ? data.mobile_response : []

                if (data.status == '5') {
                    setshowEdit(false);
                } else {
                    setshowEdit(true);
                }
                data.npwp_disabled = ((data.id && data.id != '') && (data.status === '1' || data.status === '0') && (data.bank_response && data.bank_response.NPWP && data.bank_response.NPWP.replace(/[^0-9]/g, "").length === 15)) ? true : false;
                setUserDetail(data)
                // let tempEsignApprovedHTML = response?.data?.data?.esignHTMLData ? response?.data?.data?.esignHTMLData : [];//removed warning
                let tempEsignApprovedDoc = response?.data?.data?.esignUserDoc ? response?.data?.data?.esignUserDoc : [];
                let tempCommitmentForm = response?.data?.data?.commitmentForm ? response?.data?.data?.commitmentForm : [];
                let tempTerminationForm = response?.data?.data?.terminationForm ? response?.data?.data?.terminationForm : [];
                // setEsignApprovedHTML(tempEsignApprovedHTML);//removed warning
                setEsignUserDoc(tempEsignApprovedDoc)
                setCommitmentForm(tempCommitmentForm);
                setTerminationForm(tempTerminationForm);
                if (validationForFields.agent_wallet_view.includes(parseInt(data.role_id))) {
                    await CheckPendingApplication(data, false)
                }
                if(data.reason_for_rejection && data.reason_for_rejection.length){
                    setRejectionData(data.reason_for_rejection)
                }
                if(data.reason_for_inactive && data.reason_for_inactive.length){
                    setInactiveData(data.reason_for_inactive)
                }
                setLoading(false)
            } else {
                if (response.data.status === 400 && response.data.message) {
                    toast.error(response.data.message)
                } else {
                    toast.error("Invalid request")
                }
                historyLead("/user-management-detail")
            }
        })
    }

    const getSources = async (role_id) => {
        try {
            let response = await MasterService.post('/sfa/user/get_sources_config', { role_id: role_id});
            if (response && response.data && response.data.status === 200) {
                if (response.data.data) {
                    setSources(response.data.data);
                }
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error)
        }
    }

    const CheckPendingApplication = async (user_detail, show_error) => {
        let params = {
            user_id: user_detail.id,
            role_id: user_detail.role_id
        }
        let check_open_application = false
        let open_lead_and_reportee_data = user_detail && user_detail.open_lead_and_reportee_data ? user_detail.open_lead_and_reportee_data : {}

        let pending_loan_res
        let pending_lead_res
        let wallet_balance = 0
        params.user_id = parseInt(params.user_id);
        pending_loan_res = open_lead_and_reportee_data && open_lead_and_reportee_data.pendingRefinanceLeads ? open_lead_and_reportee_data.pendingRefinanceLeads : []
        pending_lead_res = open_lead_and_reportee_data && open_lead_and_reportee_data.pendingLoanboxLeads ? open_lead_and_reportee_data.pendingLoanboxLeads : []

        let errArr = {
            ucrf: [],
            ubrf: [],
            ucf: []
        };
        let errorLoan = false;
        let errorLead = false;
        let isCheckedLoan = false;
        let isCheckedLead = false;
        let freeze_account = 0

        if (pending_loan_res && pending_loan_res.data && pending_loan_res.status === 200) {
            isCheckedLoan = true;
            for (const key of ['UCRF', 'UBRF']) {
                if (pending_loan_res && pending_loan_res.data && pending_loan_res.data[key]) {
                    for (const item of pending_loan_res.data[key]) {
                        if(item.is_account_freeze){
                            freeze_account = 1
                        }
                        for (const [k, v] of Object.entries(item['validation'])) {
                            if (v === 1) {
                                errorLoan = true;
                                errArr[`${key.toLowerCase()}`].push(k);
                            }
                        }
                    }
                }
            }
        }

        if (pending_lead_res && pending_lead_res.data && pending_lead_res.status === 200) {
            isCheckedLead = true;
            if (pending_lead_res && pending_lead_res.data && pending_lead_res.data) {
                for (const item of pending_lead_res.data) {                    
                    if (item.is_wallet_amount) {
                        wallet_balance = item.is_wallet_amount
                    }
                    if(item.is_account_freeze){
                        freeze_account = 1
                    }
                    for (const [k, v] of Object.entries(item['validation'])) {
                        if (v === 1) {                            
                            errorLead = true;
                            errArr['ucf'].push(k);
                        }
                    }
                }
            }
        }

        // if (!wallet_balance && !errorLead && !errorLoan && isCheckedLead && isCheckedLoan) {
        //     check_open_application = true
        // }
        if(!freeze_account){
            check_open_application = true
        }
        setEnableBankInfo(check_open_application)
        return check_open_application
    }

    const getReferralUserList = async () => {
        MasterService.post('/sfa/user/get_referral_user', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var referral_list = response.data.data.map(elm => ({ id: elm.id, label: elm.name + " (" + elm.id + ")", value: elm.id }));
                    setReferralName(referral_list);
                }
            })
            .catch(function (response) {
            });
    }

    const getBankList = async (data) => {
        MasterService.post('/sfa/user/bank-list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var bank_list = response.data.data.filter(elm => elm.bankId === data.bank_response.sfa_bank_id);
                    bank_list = bank_list.map(elm => ({ id: elm.bankId, label: elm.bankName, value: elm.bankId, valid_length: elm.valid_length }));
                    var bank_list_option = response.data.data.map(elm => ({ id: elm.bankId, label: elm.bankName, value: elm.bankId, valid_length: elm.valid_length }));
                    setBankOption(bank_list_option)
                    setBankList(bank_list);
                    setAccountLength(bank_list[0].valid_length)
                }
            })
            .catch(function (response) {
            });
    }

    const getRoleList = async (data) => {
        MasterService.post('/sfa/user/role_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    let role_list = response.data.data.filter(elm => elm.id === data.role_id);
                    setRoleDetails(role_list)
                }
            })
            .catch(function (response) {
            });
    }

    const getConfigList = async (data) => {
        let config_list
        await MasterService.post('/sfa/user/get-config', { role_id: data.role_id, send_bm_grading: true })
            .then(function (response) {
                if (response.data.status === 200) {
                    setConfigList(response.data.data)
                    config_list = response.data.data
                }
            })
            .catch(function (response) {
            });
        return config_list
    }

    const getUsersByRole = async (data) => {
        if (data.reporting_response.length > 0) {
            MasterService.post('/sfa/user/get-users', { role_id: data.reporting_response[0].manager_role_id })
                .then(function (response) {
                    if (response.data.status === 200) {
                        let reporting_manager_list = response.data.data.user_list.filter(elm => parseInt(elm.id) === parseInt(data.reporting_response[0].manager_id));
                        setReportingManagerList(reporting_manager_list)
                    }
                })
                .catch(function (response) {
                });
        } else {
            setReportingManagerList([])
        }
        if (validationForFields.secondary_reporting_div.includes(parseInt(data.role_id)) &&
            validationForFields.secondary_reporting_div_business_line.includes(data.business_line)) {
            if (data.secondary_reporting_response.length > 0) {
                MasterService.post('/sfa/user/get-users', { role_id: data.secondary_reporting_response[0].manager_role_id })
                    .then(function (response) {
                        if (response.data.status === 200) {
                            let reporting_manager_list = response.data.data.user_list.filter(elm => parseInt(elm.id) === parseInt(data.secondary_reporting_response[0].manager_id));
                            setSecondaryReportingManagerList(reporting_manager_list)
                        }
                    })
                    .catch(function (response) {
                    });
            } else {
                setSecondaryReportingManagerList([])
            }
        }
    }
    
    const getReportingRole = async (data) => {
        if (data.reporting_response.length > 0) {
            MasterService.post('/sfa/user/reporting_role_list', { role_id: data.role_id })
                .then(function (response) {
                    if (response.data.status === 200) {
                        let reporting_role_list = response.data.data.filter(elm => elm.id === data.reporting_response[0].manager_role_id);
                        setReportingRoleList(reporting_role_list)
                    }
                })
                .catch(function (response) {
                });
        } else {
            setReportingRoleList([])
        }
        if (validationForFields.secondary_reporting_div.includes(parseInt(data.role_id)) &&
            validationForFields.secondary_reporting_div_business_line.includes(data.business_line)) {
            if (data.secondary_reporting_response.length > 0) {
                MasterService.post('/sfa/user/reporting_role_list', { role_id: data.role_id })
                    .then(function (response) {
                        if (response.data.status === 200) {
                            let reporting_role_list = response.data.data.filter(elm => parseInt(elm.id) === parseInt(data.secondary_reporting_response[0].manager_role_id));
                            setSecondaryReportingRoleList(reporting_role_list)
                        }
                    })
                    .catch(function (response) {
                    });
            } else {
                setSecondaryReportingRoleList([])
            }
        }
    }


    const getIfDocList = async (data) => {
        try {
            let response = await MasterService.post("/sfa/user/get_if_docs", { user_id: data.id, role_id: data.role_id });
            if (response?.data?.status === 200) {
                let resposneData = response?.data?.data || [];
                setIfDocList(resposneData);
            } else {
                toast.error(response?.data?.message || response?.message)
            }
        } catch (error) {
            toast.error(error);
        }
    }


    const handleBack = async () => {
        historyLead('/user-management-detail');
    }

    const handleEdit = (pathname) => {
        historyLead(pathname, { user_id });
    }

    const showHierarychyDetails = () => {
        setShowHierarchyDetailsModal(true);
        document.body.classList.add("overflow-hidden");
    }
    const hideHierarychyDetails = () => {
        setShowHierarchyDetailsModal(false);
        document.body.classList.remove("overflow-hidden");
    }

    const showAgentHierarchyECAPopup = () => {
        setShowAgentHierarchyECA(true)
        document.body.classList.add("overflow-hidden");
    }

    const hideAgentHierarchyECAPopup = () => {
        setShowAgentHierarchyECA(false)
        document.body.classList.add("overflow-hidden");
    }

    const handleDownload = async (ele) => {
        setLoading(true);
        let pdfData = await MasterService.download('/sfa/user/download_esign', { user_id: ele.user_id_hash }, 'arraybuffer')
        const blob = new Blob([pdfData.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Esign_Agreement.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoading(false);
    }

    const handleDownloadCommitmentForm = async (ele) => {
        setLoading(true);
        let pdfData = await MasterService.download('/sfa/user/download_commitment_form', { user_id: ele.user_id_hash }, 'arraybuffer')
        const blob = new Blob([pdfData.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Employee_Commitment_Form.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoading(false);
    }

    const handleDownloadTerminationForm = async (ele) => {
        setLoading(true);
        let pdfData = await MasterService.download('/sfa/user/download_termination_form', { user_id: ele.user_id_hash }, 'arraybuffer')
        const blob = new Blob([pdfData.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Termination_Form.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoading(false);
    }



    const isValidate = (action) => {
        let tempUserDetail = { ...userDetail };
        let mobileRule = MOBILE_VALIDATION;
        let tempUserLocationDetail = { ...userLocationDetail };
        let formIsValid = true;
        let tempErrors = {};
        let role_error = "false"
        let basic_detail_error = "false"
        let document_error = "false"
        if (!tempUserLocationDetail["state_data"] || !tempUserLocationDetail["state_data"].length > 0) {
            formIsValid = false;
            role_error = "true";
            tempErrors["state"] = 'State is required';
        }
        if (!tempUserLocationDetail["city_data"] || !tempUserLocationDetail["city_data"].length > 0) {
            formIsValid = false;
            role_error = "true";
            tempErrors["city"] = 'City is required';
        }
        if (!tempUserDetail["role_id"]) {
            formIsValid = false;
            role_error = "true";
            tempErrors["role"] = 'Role is required';
        }
        if (reportingRoleList.length === 0 || !reportingRoleList[0].name) {
            formIsValid = false;
            role_error = "true";
            tempErrors["reporting_role"] = 'Reporting Role is required';
        }
        if (reportingManagerList.length === 0 || !reportingManagerList[0].name) {
            formIsValid = false;
            role_error = "true";
            tempErrors["reporting_manager"] = 'Reporting Manager is required';
        }
        // if (validationForFields.secondary_reporting_div.includes(parseInt(tempUserDetail.role_id)) &&
        //     validationForFields.secondary_reporting_div_business_line.includes(tempUserDetail.business_line)) {
        //     if (secondaryReportingRoleList.length === 0 || !secondaryReportingRoleList[0].name) {
        //         formIsValid = false;
        //         role_error = "true";
        //         tempErrors["secondary_reporting_role"] = 'Secondary Reporting Role is required';
        //     }
        //     if (secondaryReportingManagerList.length === 0 || !secondaryReportingManagerList[0].name) {
        //         formIsValid = false;
        //         role_error = "true";
        //         tempErrors["secondary_reporting_manager"] = 'Secondary Reporting Manager is required';
        //     }
        // }
        
        if (!tempUserDetail["business_line"]) {
            formIsValid = false;
            role_error = "true";
            tempErrors["business_line"] = 'Business Line is required';
        }

        if (!tempUserDetail["name"]) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["username"] = 'Username is required';
        }
        if (!tempUserDetail["mobile"]) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["mobile_no"] = 'Mobile is required';
        } else if (tempUserDetail["mobile"] && !mobileRule.test(tempUserDetail["mobile"])) {
            formIsValid = false;            
            tempErrors["mobile_no"] = 'Please enter a number between 8 - 12 characters and starting with 8';
        }        
        if(tempUserDetail["alternative_number"] && tempUserDetail["alternative_number"].trim().length > 0){
            if (tempUserDetail["alternative_number"] && !mobileRule.test(tempUserDetail["alternative_number"])) {
                formIsValid = false;
                tempErrors["alternative_number"] = 'Please enter a number between 8 - 12 characters and starting with 8';
            }            
        }
        if(tempUserDetail["whatsapp_mobile_number"] && tempUserDetail["whatsapp_mobile_number"].trim().length > 0){
            if (tempUserDetail["whatsapp_mobile_number"] && !mobileRule.test(tempUserDetail["whatsapp_mobile_number"])) {
                formIsValid = false;
                tempErrors["whatsapp_mobile_number"] = 'Please enter a number between 8 - 12 characters and starting with 8';
            }           
        }
        if (!tempUserDetail["email"]) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["email_id"] = 'Email is required';
        } else if (tempUserDetail["email"].match(EMAIL_VALIDATION) == null) {
            tempErrors["email_id"] = 'Email is not valid';
        }


        if (userDetail.bank_response && userDetail.bank_response["NPWP"] && userDetail.bank_response["NPWP"].replace(/[^0-9]/g, "").length < 15) {
            tempErrors["NPWP"] = 'NPWP is not valid';
        }
        if (!tempUserDetail["ktp_id"]) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["ktp_id"] = 'KTP ID is required';
        } else if (isNaN(tempUserDetail["ktp_id"])) {
            formIsValid = false;
            tempErrors["ktp_id"] = 'KTP ID must be numeric';
        }

        if (!tempUserDetail["doj"]) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["joining_date"] = 'Joining Date is required';
        }
        if (!tempUserDetail["employe_type"]) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["employe_type"] = 'Employment Type is required';
        }
        if (parseInt(tempUserDetail["employe_type"]) === 1 && !tempUserDetail["employe_id"]) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["employe_id"] = 'Employee Id is required';
        }
        if (bankList.length === 0 || !bankList[0].label) {
            formIsValid = false;
            tempErrors["bank_id"] = 'Bank Name is required';
        }
        if (userDetail.bank_response && !userDetail.bank_response.branch_name) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["bank_branch_name"] = 'Branch is required';
        }
        if (userDetail.bank_response && !userDetail.bank_response.account_number) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["bank_account_number"] = 'Account Number is required';
        }
        if (userDetail.bank_response && !userDetail.bank_response.beneficiary_name) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["beneficiary_name"] = 'Beneficiary Name is required';
        }
        if (!tempUserDetail["dob"]) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["date_of_birth"] = 'DOB is required';
        }
        if (!tempUserDetail["user_state"]) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["state"] = 'State is required';
        }
        if (!tempUserDetail["user_city"]) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["city"] = 'City is required';
        }
        if (!tempUserDetail["pincode"]) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["postal_code"] = 'Postal Code is required';
        }
        if (!tempUserDetail["current_occupation_id"] && validationForFields.current_occupation.includes(tempUserDetail.role_id)) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["current_occupation"] = 'Current Occupation is required';
        }
        // if (!tempUserDetail["company_name"] && validationForFields.company_name.includes(tempUserDetail.role_id) && parseInt(tempUserDetail.role_id) === parseInt(Role.Agent)) {
        //     formIsValid = false;
        //     basic_detail_error = "true";
        //     tempErrors["company_name"] = 'Company Name is required';
        // }
        if (!tempUserDetail["designation_id"] && validationForFields.designation.includes(tempUserDetail.role_id)) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["designation"] = 'Designation is required';
        }
        if (!tempUserDetail["tagging_id"] && validationForFields.tagging.includes(tempUserDetail.role_id)) {
            formIsValid = false;
            tempErrors["tagging_id"] = 'Tagging is required';
        } else if(tempUserDetail["tagging_id"] == "eca" && (!tempUserDetail["eca_agent"] || Object.keys(tempUserDetail["eca_agent"]).length === 0)){
            tempErrors["eca_agent"] = 'Referral Agent is required';
        }
        if (!tempUserDetail["source_id"] && validationForFields.source.includes(tempUserDetail.role_id)) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["source"] = 'Source is required';
        }

        if (tempUserDetail.document_response.length > 0) {
            for (let index = 0; index < tempUserDetail.document_response.length; index++) {
                const element = tempUserDetail.document_response[index];
                let config_doc = ConfigList.document.filter(e => parseInt(e.id) === parseInt(element.document_id))
                if (element.doc_url === "" && config_doc[0].doc_required) {
                    formIsValid = false
                    document_error = "true"
                    let doc_title = config_doc[0].doc_title
                    tempErrors[doc_title] = doc_title + " is required"
                }
                if (element.doc_url !== "" && config_doc[0].doc_required && !element.is_doc_verified) {
                    formIsValid = false
                    document_error = "true"
                    let doc_title = config_doc[0].doc_title
                    tempErrors[doc_title] = doc_title + " verification is required"
                }
            }
        } else {
            formIsValid = false
            document_error = "true"
        }

        if (Object.keys(tempErrors).length > 0) {
            formIsValid = false
        }
        let response = {
            formIsValid: formIsValid,
            basic_detail_error: basic_detail_error,
            tempErrors: tempErrors,
            role_error: role_error,
            document_error: document_error
        }
        return response;
    }

    const handleUpdateUserStatus = async (action, status) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm(`Are you sure you want to ${action} ?`)) {
            setLoading(true)
            if (action === "approve") {
                let result = await isValidate(action)
                if (result.formIsValid) {
                    await approveUser()
                } else {
                    setLoading(false)
                    let pathname = ""
                    if (result.role_error === "true") {
                        pathname = '/edit-user/role_details'
                    } else if (result.basic_detail_error === "true") {
                        pathname = '/edit-user/basic_details'
                    } else if (result.document_error === "true") {
                        pathname = '/edit-user/documents'
                    } else {
                        pathname = '/edit-user/role_details'
                    }
                    historyLead(pathname + "?=" + userDetail.user_id_hash, { tempErrors: result.tempErrors });
                }
            } else if (action === "active") {
                let result = await isValidate(action)
                if (result.formIsValid) {
                    await UpdateStatus(status)
                } else {
                    setLoading(false)
                    let pathname = ""
                    if (result.role_error === "true") {
                        pathname = '/edit-user/role_details'
                    } else if (result.basic_detail_error === "true") {
                        pathname = '/edit-user/basic_details'
                    } else if (result.document_error === "true") {
                        pathname = '/edit-user/documents'
                    } else {
                        pathname = '/edit-user/role_details'
                    }
                    historyLead(pathname + "?=" + userDetail.user_id_hash, { tempErrors: result.tempErrors });
                }
            } else if (action === "inactive") {
                await UpdateStatus(status)
            } else {
                await UpdateStatus(status)
            }
        } else {
            console.log("cancel")
            if (action === "approve") {
                var divToHide = document.getElementById('approve_sub_status');
                if (divToHide) {
                    divToHide.style.display = 'none';
                }
            }
        }
    }

    const UpdateStatus = async (status) => {
        let params = {
            user_id,
            status: status
        }
        if(parseInt(status) === parseInt(REJECT_STATUS)){
            params.reason_for_rejection = reason_for_rejection ? reason_for_rejection : ''
            params.rejection_reason_comment = rejection_reason_comment ? rejection_reason_comment : ''
        }

        if(parseInt(status) === parseInt(INACTIVE_STATUS)){
            params.reason_for_inactive = reason_for_inactive ? reason_for_inactive : ''
            params.inactive_reason_comment = inactive_reason_comment ? inactive_reason_comment : ''
        }
        
        MasterService.post('/sfa/user/update-user-status', params)
            .then(function (response) {
                setLoading(false)
                if (response.data.status === 200 && response.data.message !== 'FAILED') {
                    getUserDetail(user_id)
                    let message = ""
                    if (status === "0") {
                        message = "User Inactivated Successfully"
                    }
                    if (status === "1") {
                        message = "User Activated Successfully"
                    }
                    if (status === "3") {
                        message = "User Rejected Successfully"
                    }
                    toast.success(message);
                } else if (response.data.message === 'FAILED') {
                    if (response.data.data && Object.keys(response.data.data).length > 0) {
                        for (const key in response.data.data) {
                            if (Object.hasOwnProperty.call(response.data.data, key)) {
                                const element = response.data.data[key];
                                toast.error(element.error_message);
                            }
                        }
                    }
                }
            })
            .catch(function (response) {
            });
    }
    const approveUser = async () => {
        setLoading(true)
        let params = {
            user_id
        }
        // let logged_data = localStorage.getItem("logged_data") ? JSON.parse(localStorage.getItem("logged_data")) : {}
        // if (logged_data.id) {
        //     params.userId = logged_data.id
        // }
        MasterService.post('/sfa/user/approve-user-status', params)
            .then(function (response) {
                setLoading(false)
                if (response.data.status === 200 && response.data.message !== 'FAILED') {
                    getUserDetail(user_id)
                    toast.success(response.data.message);
                } else if (response.data.message === 'FAILED') {
                    if (response.data.data && Object.keys(response.data.data).length > 0) {
                        for (const key in response.data.data) {
                            if (Object.hasOwnProperty.call(response.data.data, key)) {
                                const element = response.data.data[key];
                                toast.error(element.error_message);
                            }
                        }
                    }
                }
            })
            .catch(function (response) {
            });
    }
    const resetUserPin = async () => {
        setLoading(true)
        let params = {
            user_id:user_id
        }      
        MasterService.post('/sfa/user/clear_user_pin_question', params)
            .then(function (response) {
                setLoading(false)
                if (response.data.status === 200 && response.data.message !== 'FAILED') {    
                    getUserDetail(user_id);                
                    toast.success(response.data.message);
                } else if (response.data.message === 'FAILED') {
                    toast.error(response.data.error_message);
                }
            })
            .catch(function (response) {
            });
    }
    const showModalUserHistoryPopup = async () => {
        await MasterService.post('/sfa/user/user_history_list', { 'user_id': user_id })
            .then(function (response) {
                if (response.data.status === 200) {
                    let tempRoleHistoryList = (response.data && response.data.data && response.data.data.role_history) ? response.data.data.role_history : [];
                    let tempAccountHistoryList = (response.data && response.data.data && response.data.data.account_history) ? response.data.data.account_history : [];
                    let tempVendorHistoryList = (response.data && response.data.data && response.data.data.vendor_history) ? response.data.data.vendor_history : [];
                    let tempProgressionHistoryList = (response.data && response.data.data && response.data.data.progression_history) ? response.data.data.progression_history : [];
                    let tempBMGradingHistoryList = (response.data && response.data.data && response.data.data.bm_grading_history) ? response.data.data.bm_grading_history : [];
                    let tempAppUsageList = (response.data && response.data.data && response.data.data.app_usage_history) ? response.data.data.app_usage_history : [];
                    setUserRoleHistoryList(tempRoleHistoryList);
                    setUserAccountHistoryList(tempAccountHistoryList);
                    setVendorHistoryList(tempVendorHistoryList);
                    setProgressionHistoryList(tempProgressionHistoryList)
                    setBMGradingHistoryList(tempBMGradingHistoryList)
                    setAppUsageList(tempAppUsageList)
                    SetUserHistoryPopup(true);
                    document.body.classList.add("overflow-hidden");
                }
            })
            .catch(function (response) {
            });

    }

    const hideModalUserHistoryPopup = () => {
        SetUserHistoryPopup(false);
        document.body.classList.remove("overflow-hidden");
    }

    const handleMappedRegionsTab = () => {
        setShowMappedRegions(true);
    }

    const handleReporteesTab = () => {
        setShowReportees(true);
    }

    const showDetail = (index) => {
        setShowImageDetail(true)
        setActiveIndex(index)
        setRotateAngle(0)
        setImageWidth(imageWidth)
        document.body.classList.add("overflow-hidden");
    }

    const hideDetail = (value) => {
        setShowImageDetail(false);
        getUserDetail(user_id);
        SetErrors({});
        document.body.classList.remove("overflow-hidden");
    }

    const CustomValueContainer = ({ children, ...props }) => {
        return (
            <ValueContainer {...props}>
                <Placeholder {...props} isFocused={props.isFocused}>
                    {props.selectProps.placeholder}
                </Placeholder>
                {React.Children.map(children, child =>
                    child && child.type !== Placeholder ? child : null
                )}
            </ValueContainer>
        );
    };

    // const handleRemove = () => {
    //     let temp_user_detail = { ...userDetail }
    //     let data = temp_user_detail.document_response[activeIndex]
    //     data.doc_url = ""
    //     data.is_doc_verified = 0
    //     data.edit_verify_status = 1
    //     temp_user_detail.document_response[activeIndex] = data
    //     setUserDetail(temp_user_detail)
    // }

    const handleRotate = () => {
        let temp_rotated_angle = rotated_angle === 360 ? 0 : rotated_angle + 90
        setRotateAngle(temp_rotated_angle)
    }

    const zoomOut = () => {
        let temp_image_width = image_width >= 460 ? (image_width - 100) : image_width
        setImageWidth(temp_image_width)
    }

    const zoomIn = () => {
        let temp_image_width = image_width < 1260 ? (image_width + 100) : image_width
        setImageWidth(temp_image_width)
    }

    const handleChangeImage = (event) => {
        let { files, name } = event.target
        let file_index = ConfigList.document.findIndex(e => e.doc_title === name)
        let filesInfo = Array.from(files);
        if (filesInfo && filesInfo.length) {
            filesInfo.forEach((file, index) => {
                let browse_url = URL.createObjectURL(file);
                let temp_user_detail = { ...userDetail }
                let temp_added_documents = temp_user_detail.document_response[file_index]
                // if()
                temp_added_documents["doc_file"] = file;
                temp_added_documents["doc_url"] = browse_url;
                temp_added_documents["is_doc_verified"] = 0
                temp_added_documents["edit_verify_status"] = 1
                temp_user_detail.document_response[file_index] = temp_added_documents
                temp_user_detail.bank_document = temp_user_detail.document_response.filter(e => parseInt(e.document_id) === 3 || parseInt(e.document_id) === 4)
                temp_user_detail.ktp_document = temp_user_detail.document_response.filter(e => parseInt(e.document_id) === 1 || parseInt(e.document_id) === 2)
                setUserDetail(temp_user_detail);
            });
        }
    }

    const handleReUpload = (value) => {
        let element = document.getElementById("upload-image-doc_" + activeIndex)
        element.click()
    }

    const changeSlide = (cur) => {
        setActiveIndex(cur)
        setRotateAngle(0)
        setImageWidth(imageWidth)
    }

    const showIfDetail = (index) => {
        setShowIfDocDetails(true)
        setActiveIfIndex(index)
        setRotateAngle(0)
        setImageWidth(imageWidth)
        document.body.classList.add("overflow-hidden");
    }

    const hideIfDetail = (value) => {
        setShowIfDocDetails(false);
        getUserDetail(user_id);
        SetErrors({});
        document.body.classList.remove("overflow-hidden");
    }

    const changeIfSlide = (cur) => {
        setActiveIfIndex(cur)
        setRotateAngle(0)
        setImageWidth(imageWidth)
    }

    const handleIfDownload = async () => {
        if (ifDocList && ifDocList[activeIfIndex] && ifDocList[activeIfIndex].doc_url) {
            try {
                const response = await fetch(ifDocList[activeIfIndex].doc_url);
                const blob = await response.blob();
                const blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;
                const fileName = `IF_${ifDocList?.[activeIfIndex]?.doc_title?.replace(" ", "_")}_${activeIfIndex + 1}` || `document_${activeIfIndex + 1}`;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(blobUrl);
            } catch (error) {
                console.error('Error downloading the file:', error);
            }
        } else {
            console.error('No document URL available for download');
        }
    };

    const handleApproveClick = (action, status) => {
        let agent = (TENANT=="id" || TENANT=="tri-id") ? parseInt(Role.Agent): parseInt(Role.FA)
        console.log("agent", agent)
        if (parseInt(userDetail.role_id) === agent) {
            if (action == "approve") {
                if (userDetail.is_call_verified && userDetail.is_docs_verified) {
                    handleUpdateUserStatus(action, status)
                }
            } else {
                let documents = userDetail.document_response
                let not_verified_document = documents.filter(e => e.doc_url && !e.is_doc_verified)
                if (not_verified_document.length) {
                    handleShowDocumentNotVerified(0)
                } else {
                    handleUpdateUserStatus(action, status)
                }
            }
        } else {
            let documents = userDetail.document_response
            let not_verified_document = documents.filter(e=>e.doc_url && !e.is_doc_verified)
            if(not_verified_document.length){
                handleShowDocumentNotVerified(0)
            } else {
                handleUpdateUserStatus(action, status)
            }
        }
    }

    const saveSubStatus = (name, value) => {
        let params = {
            name, value: value ? 1 : 0,
            user_id: parseInt(userDetail.id)
        }
        MasterService.post('/sfa/user/save_sub_status', params).then(function (response) {
            if (response.data.status === 200) {
                if (response.data.message === 'FAILED') {
                    if (response.data.data && Object.keys(response.data.data).length > 0) {
                        for (const key in response.data.data) {
                            if (Object.hasOwnProperty.call(response.data.data, key)) {
                                const element = response.data.data[key];
                                toast.error(element.error_message);
                            }
                        }
                    }
                }
            } else {
                toast.error(response.data.message)
            }
            if (response.data.error && response.data.error.length > 0) {
                response.data.error.forEach(element => {
                    toast.error(element);
                });
            }
        })
    }

    const handleRejectSelect = (event) => {
        if(event.value){
            setReasonForRejection(event.value)
            setRejectionReasonComment('')
            SetErrors({})
        }
    }

    const handleInactiveSelect = (event) => {
        if(event.value){
            setReasonForInactive(event.value)
            setInactiveReasonComment('')
            SetErrors({})
        }
    }

    const handleInputChange = async (target_name, type, event) => {
        let temp_basic_details = { ...userDetail };
        let tempErrors = { ...errors };

        if (target_name === "confirm_account_number") {
            if(account_length == 0 || event.target.value.length <= account_length){
                temp_basic_details.bank_response[target_name] = event.target.value;
            }
        } else if (target_name === "ktp_id") {
            temp_basic_details[target_name] = event.target.value;
        } else if (type === "checkbox" || type === "select") {
            if (target_name === "sfa_bank_id") {
                setAccountLength(event.valid_length)
                temp_basic_details.bank_response["account_number"] = "";
                temp_basic_details.bank_response["confirm_account_number"] = "";
                temp_basic_details.bank_response["branch_name"] = "";
                temp_basic_details.bank_response["beneficiary_name"] = "";
                // temp_basic_details.bank_response["NPWP"] = "";

                tempErrors["account_number"] = ""
                tempErrors["confirm_account_number"] = ""
                tempErrors["branch_name"] = ""
                tempErrors["beneficiary_name"] = ""
                // tempErrors["NPWP"] = ""
            }
            temp_basic_details.bank_response[target_name] = event.value;
        } else if (type === "toggle") {
            temp_basic_details.bank_response[target_name] = event;
        } else if (type === "number") {
            tempErrors["NPWP"] = ""
            temp_basic_details.bank_response[target_name] = event.formattedValue;
        } else if (target_name === "beneficiary_name") {
            temp_basic_details.bank_response[target_name] = event.target.value;
        } else if (target_name === "is_call_verified" || target_name === "is_docs_verified") {
            let hide_substatus_div = false
            temp_basic_details[target_name] = event.target.checked ? 1 : 0;
            if (target_name === "is_call_verified" && event.target.checked && temp_basic_details.is_docs_verified) {
                hide_substatus_div = true
            }
            if (target_name === "is_docs_verified" && event.target.checked && temp_basic_details.is_call_verified) {
                hide_substatus_div = true
            }
            if (hide_substatus_div) {
                setTimeout(() => {
                    var divToHide = document.getElementById('approve_sub_status');
                    if (divToHide) {
                        divToHide.style.display = 'none';
                    }
                }, 500);
            }
            saveSubStatus(target_name, event.target.checked)
        } else {
            temp_basic_details.bank_response[target_name] = event.target.value;
        }
        if (target_name === "ktp_id") {
            if (isNaN(event.target.value)) {
                tempErrors["ktp_id"] = 'KTP ID must be numeric';
            } else if (event.target.value.length !== 16) {
                tempErrors["ktp_id"] = 'KTP ID must be of 16 digits';
            } else {
                tempErrors[target_name] = ""
            }
        }
        setUserDetail(temp_basic_details);
        SetErrors(tempErrors)
    }

    const handleDocVerifiedStatus = async (index) => {
        let temp_basic_details = { ...userDetail };
        if(temp_basic_details.document_response[index].doc_url != ""){
            temp_basic_details.document_response[index].is_doc_verified = !temp_basic_details.document_response[index].is_doc_verified
            setUserDetail(temp_basic_details)
        }
    }

    const uploadImages = async (added_documents) => {
        let document_info = []
        for (let index = 0; index < added_documents.length; index++) {
            let element = added_documents[index]
            let doc_object = {}
            if (element.doc_url.includes("blob")) {
                var formData = new FormData();
                formData.append("upload_type", "sfa_docs");
                formData.append("visit_id", `user/${user_id}`);
                formData.append("images", element.doc_file);
                await MasterService.uploadFilesToS3(formData, {
                    "Content-Type": "multipart/form-data",
                }).then((result) => {
                    if (result.data.status === 200) {
                        doc_object.document_id = element.document_id
                        doc_object.doc_url = result.data.data[0].file_url;
                        doc_object.is_doc_verified = element.is_doc_verified ? element.is_doc_verified : 0
                    }
                    document_info.push(doc_object)
                })
            } else {
                doc_object.document_id = element.document_id
                doc_object.doc_url = element.doc_original_url?element.doc_original_url:element.doc_url
                doc_object.is_doc_verified = element.is_doc_verified ? element.is_doc_verified : 0
                document_info.push(doc_object)
            }
        }
        return document_info
    }

    const handleUpdate = async () => {
        // let value = activeIndex === 0 || activeIndex === 1 ? "ktp" : "bank"
        let value = '';
        if (activeIndex === 0 || activeIndex === 1) {
            value = 'ktp'
        } else if (activeIndex === 2) {
            value = 'bank'
        } else if (activeIndex === 3) {
            value = 'npwp';
        } else if (activeIndex === 4) {
            value = 'familycard';
        }
        let res = await checkValidation(value,activeIndex)
        if (res) {
            if (!is_existing) {
                setLoading(true)
                let params = {}
                let pathname = ""
                if (value === "ktp") {
                    params.ktp_id = userDetail.ktp_id
                    params.user_id = userDetail.id
                    pathname = "/sfa/user/update_ktp"

                } else if (value === "bank") {
                    params.bank_id = userDetail.bank_response.sfa_bank_id
                    params.user_id = userDetail.id
                    params.bank_account_number = userDetail.bank_response.account_number
                    params.beneficiary_name = userDetail.bank_response.beneficiary_name
                    params.bank_branch_name = userDetail.bank_response.branch_name
                    // params.npwp = userDetail.bank_response.NPWP
                    pathname = "/sfa/user/update_bank_details"

                }else if(value === 'npwp'){
                    params.user_id = userDetail.id
                    params.npwp = userDetail.bank_response.NPWP
                    pathname = "/sfa/user/update_user_npwp"
                } else if(value === 'familycard'){
                    params.user_id = userDetail.id
                    pathname = "/sfa/user/update_docs"
                }
                let modified_documents = await uploadImages(userDetail.document_response)
                params.document_info = modified_documents
                // let logged_data = localStorage.getItem("logged_data") ? JSON.parse(localStorage.getItem("logged_data")) : {}
                // if (logged_data.id) {
                //     params.userId = logged_data.id
                // }
                MasterService.post(pathname, params, {}).then(function (response) {
                    if (response.data.status === 200) {
                        if (response.data.message === 'FAILED') {
                            if (response.data.data && Object.keys(response.data.data).length > 0) {
                                for (const key in response.data.data) {
                                    if (Object.hasOwnProperty.call(response.data.data, key)) {
                                        const element = response.data.data[key];
                                        toast.error(element.error_message);
                                    }
                                }
                            }
                            hideDetail(value)
                            setLoading(false)
                        } else {
                            toast.success("Data Updated Successfully")
                            hideDetail(value)
                            getUserDetail(userDetail.user_id_hash)
                        }
                    } else {
                        toast.error(response.data.message)
                        hideDetail(value)
                        setLoading(false)
                    }
                    if (response.data.error && response.data.error.length > 0) {
                        response.data.error.forEach(element => {
                            toast.error(element);
                            hideDetail(value)
                            setLoading(false)
                        });
                    }
                })
            } else {
                let tempErrors = { ...existing_errors }
                SetErrors(tempErrors);
            }
        } else {
            if (is_existing) {
                let tempErrors = { ...existing_errors }
                SetErrors(tempErrors);
            }
        }
    }

    const checkValidation = async (value, activeIndex) => {
        let tempUserDetail = { ...userDetail };
        let formIsValid = true;
        let tempErrors = {};
        let res = await checkExisting("", true, value)
        if (value === "ktp") {
            if (!tempUserDetail["ktp_id"]) {
                formIsValid = false;
                tempErrors["ktp_id"] = 'KTP ID is required';
            } else if (isNaN(tempUserDetail["ktp_id"])) {
                formIsValid = false;
                tempErrors["ktp_id"] = 'KTP ID must be numeric';
            } else if (tempUserDetail["ktp_id"].length !== 16) {
                formIsValid = false;
                tempErrors["ktp_id"] = 'KTP ID must be of 16 digits';
            }
        } else if (value === 'bank') {
            if (userDetail.bank_response && !userDetail.bank_response.sfa_bank_id) {
                formIsValid = false;
                tempErrors["sfa_bank_id"] = 'Bank Name is required';
            }
            if (userDetail.bank_response && !userDetail.bank_response.branch_name) {
                formIsValid = false;
                tempErrors["branch_name"] = 'Branch is required';
            }
            if (userDetail.bank_response && !userDetail.bank_response.account_number) {
                formIsValid = false;
                tempErrors["account_number"] = 'Account Number is required';
            } else if (userDetail.bank_response.account_number.length < account_length && account_length > 0) {
                formIsValid = false;
                tempErrors["account_number"] = 'Format of Account Number must ' + account_length + ' digits';
            }
            if (userDetail.bank_response && !userDetail.bank_response.confirm_account_number) {
                formIsValid = false;
                tempErrors["confirm_account_number"] = 'Confirm Account Number is required';
            }
            else if (userDetail.bank_response.confirm_account_number.length < account_length && account_length > 0) {
                formIsValid = false;
                tempErrors["confirm_account_number"] = 'Format of Account Number must ' + account_length + ' digits';
            } else if (!(userDetail.bank_response.account_number.trim() === "" && userDetail.bank_response.confirm_account_number.trim() === "") && userDetail.bank_response.confirm_account_number !== userDetail.bank_response.account_number) {
                formIsValid = false;
                tempErrors["confirm_account_number"] = 'Account Number not matching';
            }
            if (userDetail.bank_response && !userDetail.bank_response.beneficiary_name) {
                formIsValid = false;
                tempErrors["beneficiary_name"] = 'Beneficiary Name is required';
            }
        } else if (value === 'npwp') {
            if (userDetail.bank_response && userDetail.bank_response.NPWP && userDetail.bank_response.NPWP.replace(/[^0-9]/g, "").length < 15) {
                formIsValid = false;
                tempErrors["NPWP"] = 'NPWP is not valid';
            }
        }


        if (tempUserDetail.document_response.length > 0) {
            for (let index = 0; index < tempUserDetail.document_response.length; index++) {
                const element = tempUserDetail.document_response[index];
                let config_doc = ConfigList.document.filter(e => parseInt(e.id) === parseInt(element.document_id))
                if (element.doc_url === "" && config_doc[0].doc_required) {
                    formIsValid = false
                    let doc_title = config_doc[0].doc_title
                    tempErrors[doc_title] = doc_title + " is required"
                }
                if (element.doc_url !== "" && !element.is_doc_verified && index === activeIndex) {
                    formIsValid = false
                    let doc_title = config_doc[0].doc_title
                    tempErrors[doc_title] = doc_title + " verification is required"
                }
            }
        } else {
            formIsValid = false
            tempErrors["Selfie with KTP"] = "Selfie with KTP is required"
            tempErrors["KTP"] = "KTP is required"
            tempErrors["Bank Statement"] = "Bank Statement is required"
            tempErrors["NPWP"] = "NPWP is required"
        }
        if (Object.keys(tempErrors).length > 0) {
            formIsValid = false
        }
        if (formIsValid) {
            formIsValid = res
        } else {
            SetErrors(tempErrors);
        }
        return formIsValid;
    }

    const checkExisting = async (event, without_event, value) => {
        let tempErrors = { ...errors };
        let tempExistingErrors = { ...existing_errors };
        let data_exist = false
        let formIsValid = true;
        let call_api = true

        let params = {}
        if (userDetail.id) {
            params.user_id = userDetail.user_id_hash;
        }
        if (without_event) {
            params.ktp_id = userDetail.ktp_id ? userDetail.ktp_id : ""
            params.npwp = userDetail.bank_response && userDetail.bank_response.NPWP ? userDetail.bank_response.NPWP : ""
            params.bank_account_number = userDetail.bank_response && userDetail.bank_response.account_number ? userDetail.bank_response.account_number : 0
            params.bank_id = userDetail.bank_response && userDetail.bank_response.bank_id ? userDetail.bank_response.bank_id : 0
        } else {
            if (event.target.name === "ktp_id") {
                params["ktp_id"] = event.target.value ? event.target.value : event.target.defaultValue
            } else if (event.target.name === "NPWP") {
                params["npwp"] = event.target.value ? event.target.value : event.target.defaultValue
            } else if ((event.target.name === "account_number" || event.target.name === "confirm_account_number") && userDetail.bank_response.account_number && userDetail.bank_response.confirm_account_number) {
                if (userDetail.bank_response.account_number.trim() === userDetail.bank_response.confirm_account_number.trim()) {
                    params.bank_account_number = userDetail.bank_response && userDetail.bank_response.account_number ? userDetail.bank_response.account_number : ""
                    params.bank_id = userDetail.bank_response && userDetail.bank_response.bank_id ? userDetail.bank_response.bank_id : 0
                } else {
                    call_api = false
                }
            } else {
                params[event.target.name] = event.target.value ? event.target.value : event.target.defaultValue
            }
        }
        if (call_api) {
            await MasterService.post('/sfa/user/check_existing', params, {}).then(function (response) {
                if (response.data.status === 200) {
                    let response_data = response.data.data
                    if (value === "ktp") {
                        if (response_data.ktp_id) {
                            if (response_data.ktp_id.is_exist === true) {
                                data_exist = true
                                formIsValid = false
                                tempErrors["ktp_id"] = response_data.ktp_id.error_message
                                tempExistingErrors["ktp_id"] = response_data.ktp_id.error_message
                            } else {
                                delete tempErrors["ktp_id"]
                                delete tempExistingErrors["ktp_id"]
                            }
                        }
                    } else {
                        if (response_data.npwp) {
                            if (response_data.npwp.is_exist === true) {
                                data_exist = true
                                formIsValid = false
                                tempErrors["NPWP"] = response_data.npwp.error_message
                                tempExistingErrors["NPWP"] = response_data.npwp.error_message
                            } else {
                                delete tempErrors["NPWP"]
                                delete tempExistingErrors["NPWP"]
                            }
                        }
                        if (response_data.bank_account_number) {
                            if (response_data.bank_account_number.is_exist === true) {
                                data_exist = true
                                formIsValid = false
                                tempErrors["account_number"] = response_data.bank_account_number.error_message
                                tempExistingErrors["account_number"] = response_data.bank_account_number.error_message
                            } else {
                                delete tempErrors["account_number"]
                                delete tempExistingErrors["account_number"]
                            }
                        }
                    }
                    SetErrors(tempErrors);
                    SetExistingErrors(tempExistingErrors)
                    setIsExisting(data_exist)
                }
            }).catch(function (response) {
            });
            return formIsValid
        } else {
            return formIsValid
        }
    }

    const isActionAllowed = (rightKey, restrictCondition) => {
        return rightsStatus[rightKey] && !restrictCondition;
    };

    const restrictAgentEditing = () => {
        const isSalesSupportUser = adminRoleId === ADMIN_ROLES['sales_support'];
        const isOnboardingSpecialist = adminRoleId === ADMIN_ROLES['onboarding_specialist'];
        const isAgentUser = userDetail.role_id === parseInt(Role['Agent']);

        if (isSalesSupportUser) {
            return isAgentUser && ['0', '3'].includes(userDetail.status);
        } else if (isOnboardingSpecialist) {
            return userDetail.status !== '4';
        }

        return false; 
    };

    const handleUserReject = () => {
        setRejectionReasonModal(true)
    }

    const hideRejectionReasonModal = () => {
        setRejectionReasonModal(false)
        setReasonForRejection(null)
        setRejectionReasonComment('')
        SetErrors({})
    }

    const handleUserInactive = () => {
        setInactiveReasonModal(true)
    }

    const hideInactiveReasonModal = () => {
        setInactiveReasonModal(false)
        setReasonForInactive(null)
        setInactiveReasonComment('')
        SetErrors({})
    }

    const handleChangetext = (event, type) => {
        let tempError = {...errors}
        if(type == 'reject'){
            setRejectionReasonComment(event.target.value)
            tempError.rejection_reason_comment = ''
        }
        if(type == 'inactive'){
            setInactiveReasonComment(event.target.value)
            tempError.inactive_reason_comment = ''
        }
        SetErrors(tempError)
    }

    const saveRejectionReasons = () => {
        let tempError = { ...errors }
        let is_valid = true
        if (!reason_for_rejection) {
            tempError.reason_for_rejection = 'Reason For Rejection is Required'
            is_valid = false
        } else if (reason_for_rejection === "others") {
            if (rejection_reason_comment.trim() === "") {
                is_valid = false
                tempError.rejection_reason_comment = 'Comment is Required'
            }
        }
        if (!is_valid) {
            SetErrors(tempError)
        } else {
            hideRejectionReasonModal()
            handleUpdateUserStatus("reject", REJECT_STATUS)
        }
    }

    const saveInactiveReasons = () => {
        let tempError = { ...errors }
        let is_valid = true
        if (!reason_for_inactive) {
            tempError.reason_for_inactive = 'Reason For Inactive is Required'
            is_valid = false
        } if (inactive_reason_comment.trim() === "") {
            is_valid = false
            tempError.inactive_reason_comment = 'Comment is Required'
        }
        if (!is_valid) {
            SetErrors(tempError)
        } else {
            hideInactiveReasonModal()
            handleUpdateUserStatus("inactive", INACTIVE_STATUS)
        }
    }

    const toggleTip = (id, state) => {
        if (state === 'close') {
            setTipOpen(false)
            id = ''
        } else {
            setTipOpen(true)
        }
        setTipOpenId(id)
    }

    const handleClickAway = () => {
        toggleTip('', 'close')
    }

    const handleShowDocumentNotVerified = (doc_status) => {
        if (!doc_status) {
            var divToHide = document.getElementById('approve_sub_status');
            if (divToHide) {
                divToHide.style.display = 'none';
            }
            setTimeout(() => {
                alert("Documents not verified")
            }, 100);
        }
    }

    const guessFileType = (url) => {
        const lowerUrl = url.toLowerCase();

        if (lowerUrl.includes('.pdf')) {
            return 'PDF';
        } else if (lowerUrl.includes('.jpg') || lowerUrl.includes('.jpeg') || lowerUrl.includes('.png') || lowerUrl.includes('.gif') || lowerUrl.includes('.bmp')) {
            return 'Image';
        } else {
            return 'Unknown';
        }
    }

    console.log(ifDocList, 'asdfasdf')
    
    return (
        <div className='container-fluid'>
            {loading ? <Loader /> : null}
            <div className={`top-bar ${activeClass}`}>
                <div className="top-heading d-flex">
                    <div><h1>{userDetail?.name}</h1><span>({roleList[0]?.name})</span></div>
                    <div className="right-btn btn-agent-detail">
                        <button className='btn-line m-xs-l' onClick={handleBack.bind(this)}>
                            Back
                        </button>
                        {(TENANT == "id") && (([53].includes(userDetail.role_id)) && (userDetail.status === '0' || userDetail.status === '1')) &&
                            <button className="m-xs-l btn_vendor">Vendor Code : {(userDetail.vendor_id) ? userDetail.vendor_id : <i className="ic-otp" title={(userDetail.add_vendor_failure && userDetail.add_vendor_failure.vendor_id) ? userDetail.add_vendor_failure.vendor_id : 'Vendor Code Not Generated'}>
                                <i className='path1'></i><i className='path2'></i><i className='path3'></i><i className='path4'></i>
                            </i>} </button>
                        }
                        <button onClick={() => showModalUserHistoryPopup(userDetail.id)} className="btn-line m-xs-l"> <i className="ic-history"></i> History</button>
                        {isActionAllowed('edit_user', restrictAgentEditing()) && showEdit &&
                            <button onClick={() => handleEdit("/edit-user/role_details?id=" + user_id)} className="btn-line m-xs-l"><i className="ic-edit"></i> Edit</button>
                        }
                        {isActionAllowed('activate_user', restrictAgentEditing()) && (userDetail.status === '0') &&
                            <button onClick={() => handleApproveClick("active", "1")} className="btn-line m-xs-l">Active</button>
                        }
                        <WithRights rightKey="deactivate_user">
                            {userDetail.status === '1' &&
                                <button onClick={() => handleUserInactive()} className="btn-line m-xs-l">Inactive</button>
                            }
                        </WithRights>
                        {isActionAllowed('approve_user', restrictAgentEditing()) && (userDetail.status === '4' || userDetail.status === '3') &&
                            <div  className='p-rel'>
                                <button onClick={() => handleApproveClick("approve", "1")} className="btn-line m-xs-l" id={((TENANT=="id" || TENANT=="tri-id") ? (parseInt(userDetail.role_id) === parseInt(Role.Agent)) : (parseInt(userDetail.role_id) === parseInt(Role.FA))) ? userDetail.is_call_verified && userDetail.is_docs_verified ? "" : "show_sub_status" : ""}>Approve</button>
                                {/* {show_substatus_popup === true && */}
                                <div id='approve_sub_status' className='approve_sub_status'>
                                    <ul>
                                        <li className="custom-control custom-checkbox">
                                            <input
                                                id={`is_call_verified`}
                                                type="checkbox"
                                                name="is_call_verified"
                                                className="custom-control-input"
                                                onChange={handleInputChange.bind(null, 'is_call_verified', 'check')}
                                                checked={userDetail.is_call_verified ? true : false}
                                            />
                                            <label htmlFor={`is_call_verified`} className="custom-control-label">
                                            </label>
                                            <span>Call Verified</span>
                                        </li>
                                        <li className="custom-control custom-checkbox">
                                            <input
                                                id={`is_docs_verified`}
                                                type="checkbox"
                                                name="is_docs_verified"
                                                className="custom-control-input"
                                                onClick={()=>handleShowDocumentNotVerified(userDetail.is_docs_verified)}
                                                checked={userDetail.is_docs_verified ? true : false}
                                            />
                                            <label htmlFor={`is_docs_verified`} className="custom-control-label">
                                            </label>
                                            <span>Docs Verified</span>
                                        </li>
                                    </ul>
                                </div>
                                {/* } */}
                            </div>

                        }
                        <WithRights rightKey="reject_user">
                            {userDetail.status === '4' &&
                                <button onClick={() => handleUserReject()} className="btn-line m-xs-l">Reject</button>
                            }
                        </WithRights>
                    </div>
                </div>
            </div>
            <div className='agent-detail-profile'>
                <div className='d-flex d-flex-center'>
                    <div className='role-detail-preview preview-confirmation'>
                        <div className='profile-photo'>
                            {userDetail.profile_image_path !== "" ?
                                <img src={userDetail.profile_image_path} className="" alt="no pic" /> :
                                <label>No Image</label>
                            }
                        </div>

                    </div>
                    <div className='role-detail-preview d-flex'>
                        <ul>
                            <li>
                                <span>Username</span>
                                <label>{userDetail.name}</label>
                            </li>
                            <li>
                                <span>SFA ID</span>
                                <label>{userDetail.id}</label>
                            </li>
                            <li>
                                <span>Status</span>
                                <label>{(userDetail.status === '0') ? 'In-active' : (userDetail.status == '1') ? 'Active' : (userDetail.status == '3') ? 'Rejected' : (userDetail.status == '4') ? 'In-process' : (userDetail.status == '5') ? 'Approval Pending' : ''}</label>
                            </li>
                            {(userDetail.role_id == Role.Agent) && <li>
                                <span>Current Level</span>
                                <label>{(userDetail.agent_level) ? userDetail.agent_level : 'NA'}</label>
                            </li>
                            }
                            {!([Role.VP, Role.RBH].includes(userDetail?.role_id)) && userDetail.allManagerList?.length ? <li>
                                <span>Hierarchy</span>
                                <span className="truncate" title="">{userDetail.bm_name}</span>
                                <span onClick={showHierarychyDetails} title={"Show Hierarchy"}>
                                    <img src={HierarchyIcon} className="hierarchy-icon" alt="" />
                                </span>
                            </li> : null}

                            {([Role.Agent].includes(userDetail?.role_id)) && userDetail.eca_agent_hierarchy?.length ? <li>
                                <span>ECA Agent Hierarchy</span>
                                <span className="truncate" title="">{userDetail.bm_name}</span>
                                <span onClick={showAgentHierarchyECAPopup} title={"ECA Agent Hierarchy"}>
                                    <img src={HierarchyIcon} className="hierarchy-icon" alt="" />
                                </span>
                            </li> : null}


                            {
                                showHierarchyDetailsModal === false ? '' : (
                                    <div className='view-timeline-popup image-timeline-popup reporting-line-popup'>
                                        <Modal show={showHierarchyDetailsModal} handleClose={hideHierarychyDetails} style={{ width: "500px", borderRadius: "15px" }}>
                                        <HierarchyDetails allManagerList={userDetail.allManagerList} secondaryManagersList={userDetail.secondaryManagersList} />
                                        </Modal>
                                    </div>
                                )
                            }
                            {
                                showAgentHierarchyECA === false ? '' : (
                                    <div className='view-timeline-popup image-timeline-popup reporting-line-popup'>
                                        <Modal show={showAgentHierarchyECA} handleClose={hideAgentHierarchyECAPopup} style={{ width: "500px", borderRadius: "15px" }}>
                                            <AgentHierarchyDetails agentHierarchy={userDetail.eca_agent_hierarchy} />
                                        </Modal>
                                    </div>
                                )
                            }                            
                        </ul>
                    </div>
                </div>
                {(TENANT=="id" || TENANT=="fav-id") && validationForFields.agent_wallet_view.includes(parseInt(userDetail.role_id)) && userDetail.status == '1' &&
                    <div className='role-detail-preview user-wallet-view'>
                        <ul className='p-sm-b'>
                            <li className="custom-control custom-checkbox">
                                <span>Subscribe To : </span>
                                <input
                                    id={`agent_wallet`}
                                    type="checkbox"
                                    disabled={true}
                                    className="custom-control-input"
                                    checked={userDetail.agent_wallet}
                                />

                                <label htmlFor={`agent_wallet`} className="custom-control-label">
                                    Sales Wallet
                                </label>
                            </li>
                        </ul>
                       { (TENANT=='id' && userDetail.is_pin_set)?<div className='role-detail-preview'>                     
                    <a className="underline" href={() => false} onClick={resetUserPin}>Reset PIN</a>
                    </div>:''}
                    </div>
                }
                
                
            </div>
            <div className="agent-detail-tads">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Nav variant="pills" className="flex-column tab-line">
                        <div className="tab-list">
                            <Nav.Item onClick={() => { }}>
                                <Nav.Link eventKey="first">User Details</Nav.Link>
                            </Nav.Item>
                            <Nav.Item onClick={handleMappedRegionsTab}>
                                <Nav.Link eventKey="second"> Mapped Regions</Nav.Link>
                            </Nav.Item>

                            {!([Role.Agent, Role.BRO].includes(userDetail?.role_id)) ? <Nav.Item onClick={handleReporteesTab}>
                                <Nav.Link eventKey="third">Reportees</Nav.Link>
                            </Nav.Item> : null}

                        </div>

                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey={"first"}>
                            <div className='preview-confirmation add-user-detail-filed'>
                                <div className='top-heading d-flex'>
                                    <h2>Role Details</h2>
                                    {isActionAllowed('edit_user', restrictAgentEditing()) && showEdit &&
                                        <a onClick={() => handleEdit("/edit-user/role_details?id=" + user_id)} className='edit-opt'>Edit</a>
                                    }
                                </div>

                                <div className='d-flex'>
                                    <div className='role-detail-preview'>
                                        <h3>Business Vertical</h3>
                                        <ul>
                                            <li>
                                                <span>Business</span>
                                                <label>{(TENANT=="tri-id") ? 'TriiVCO' :(TENANT=="fav-id")? 'Favorite':'OTO'}</label>
                                            </li>
                                            <li>
                                                <span>Vertical</span>
                                                <label>Used Car</label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='role-detail-preview'>
                                        <h3>Map Region</h3>
                                        <ul>
                                            <li>
                                                <span>Zone</span>
                                                <label>{Zone.filter(e => e.id === defaultZone)[0].label}</label>

                                            </li>
                                            {userLocationDetail.state_data &&
                                                <li>
                                                    <span>State</span>
                                                    <label>{userLocationDetail.state_data ? userLocationDetail.state_data.length : "No"} States</label>
                                                </li>
                                            }
                                            {userLocationDetail.city_data &&
                                                <li>
                                                    <span>City</span>
                                                    <label>{userLocationDetail.city_data ? userLocationDetail.city_data.length : "No"} Cities</label>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>

                                    <div className='d-flex m-xl-t p-sm-t m-xl-b p-md-b preview-basic-details'>
                                        <div className='role-detail-preview'>
                                            <h3>Role and Reporting</h3>
                                            <ul>
                                                <li>
                                                    <span>Role</span>
                                                    {roleList && roleList.length > 0 ?
                                                        <label>{roleList[0].name}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                                {ConfigList.business_line &&
                                                    <li>
                                                        <span>Business Line</span>
                                                        <label>{ConfigList.business_line.findIndex(e => e.value === userDetail.business_line) > -1 ? ConfigList.business_line.filter(e => e.value === userDetail.business_line)[0].label : "-"}</label>
                                                    </li>
                                                }
                                                <li>
                                                    <span>Primary Reporting Role</span>
                                                    {reportingRoleList && reportingRoleList.length > 0 ?
                                                        <label>{reportingRoleList[0].name}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                                <li>
                                                    <span>Primary Reporting Manager</span>
                                                    {reportingManagerList && reportingManagerList.length > 0 ?
                                                        <label>{reportingManagerList[0].name}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                                {(TENANT=="id" || TENANT=="tri-id") && secondaryReportingRoleList && secondaryReportingRoleList.length > 0 &&
                                                    <li>
                                                        <span>Secondary Reporting Role</span>
                                                        {secondaryReportingRoleList[0].name ?
                                                            <label>{secondaryReportingRoleList[0].name}</label> :
                                                            <label>-</label>
                                                        }
                                                    </li>
                                                }
                                                {(TENANT=="id" || TENANT=="tri-id") && secondaryReportingManagerList && secondaryReportingManagerList.length > 0 &&
                                                    <li>
                                                        <span>Secondary Reporting Manager</span>
                                                        {secondaryReportingManagerList[0].name ?
                                                            <label>{secondaryReportingManagerList[0].name}</label> :
                                                            <label>-</label>
                                                        }
                                                    </li>
                                                }                                        
                                                {userDetail.status === REJECT_STATUS &&
                                                    <li>
                                                        <span>Reason For Rejection</span>
                                                        {rejection_data && rejection_data.length > 0 ?
                                                            <label className='d-flex'>
                                                                {rejection_data[0].label}
                                                                {rejection_data[0].label === "Others" &&
                                                                    <div id='other_reason_tooltip'>
                                                                        <Tooltip
                                                                        useContext
                                                                        html={(
                                                                            <div className="controlled-example tooltip-main">
                                                                                <div className="tooltip-data">
                                                                                    {/* <span className="controlled-example_close-button" onClick={() => toggleTip(user_id, 'close')}><i className="ic-clearclose"></i></span> */}
                                                                                    <span>{rejection_data[0].rejection_reason_comment}</span>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        className="tootip-outer"
                                                                        // open="true"
                                                                        theme="light"
                                                                        size="regular"
                                                                        position="bottom"
                                                                        trigger="click"
                                                                    >
                                                                        <span title="" className="tooltio-ic"> <i className='ic-info-icon1'></i>
                                                                        </span>
                                                                    </Tooltip>
                                                                    </div>
                                                                }

                                                            </label>:
                                                            <label>-</label>
                                                        }
                                                    </li>
                                                }
                                        </ul>
                                    </div>
                                </div>


                                <div className='top-heading d-flex'>
                                    <h2>Basic Details</h2>
                                    {isActionAllowed('edit_user', restrictAgentEditing()) && userDetail.status != '5' &&
                                        <a onClick={() => handleEdit("/edit-user/basic_details?id=" + user_id)} className='edit-opt'>Edit</a>
                                    }
                                </div>

                                <div className='d-flex'>

                                    <div className='role-detail-preview preview-basic-details'>
                                        <h3>Personal Detail</h3>
                                        <ul>
                                            <li>
                                                <span>Username</span>
                                                <label>{userDetail.name ? userDetail.name : "-"}</label>
                                            </li>
                                            <li>
                                                <span >Mobile</span>
                                                <label style={{ display: "flex" }}>{userDetail.mobile ? userDetail.mobile : "-"}
                                                    {userDetail.is_mobile_no_verified === "1" ?
                                                        <span className='verify-txt'>Verified</span> : null}
                                                </label>
                                            </li>
                                            <li>
                                                <span>Email</span>
                                                <label title={userDetail.email ? userDetail.email : "-"}>{userDetail.email ? userDetail.email : "-"}</label>
                                            </li>

                                            <li>
                                                <div>
                                                    <span>KTP ID</span>
                                                </div>
                                                <label style={{ display: "flex" }}>{userDetail.ktp_id ? userDetail.ktp_id : "-"}
                                                    {userDetail.ktp_id && showEdit &&
                                                        <span onClick={() => showDetail(0)} className="verify-txt">Verify</span>
                                                    }</label>
                                            </li>
                                            <li>
                                                <span>Employment Type</span>
                                                <label>{EmploymentType.findIndex(e => e.value === userDetail.employe_type) > -1 ? EmploymentType.filter(e => e.value === userDetail.employe_type)[0].label : "-"}</label>
                                            </li>
                                            <li>
                                                <span>Employee ID</span>
                                                <label title={userDetail.employe_id ? userDetail.employe_id : "-"}>{userDetail.employe_id ? userDetail.employe_id : "-"}</label>
                                            </li>

                                            <li>
                                                <span>Joining Date</span>
                                                {userDetail.doj && isNaN(Date.parse(userDetail.doj)) === false ?
                                                    <label>{new Date(userDetail.doj).toISOString().split('T')[0]}</label> :
                                                    <label>-</label>
                                                }
                                            </li>

                                            <li>
                                                <span>Anniversary</span>
                                                {userDetail.anniversary_date && isNaN(Date.parse(userDetail.anniversary_date)) === false ?
                                                    <label>{new Date(userDetail.anniversary_date).toISOString().split('T')[0]}</label> :
                                                    <label>-</label>
                                                }
                                            </li>  
                                            {(TENANT=="id" || TENANT=="fav-id") &&                                          
                                            <li>
                                                <span>Whatsapp Number</span>
                                                <label style={{ display: "flex" }}>{userDetail.whatsapp_mobile_number ? userDetail.whatsapp_mobile_number : "-"}
                                                    {parseInt(userDetail.whatsapp_number_verified) === 1 ?
                                                        <span className='verify-txt'>Verified</span> : null}
                                                </label>
                                            </li>
                                            }
                                            {(validationForFields.alternative_number.includes(userDetail.role_id)) &&
                                                <li>
                                                    <span>Alternative Number</span>
                                                    {userDetail.alternative_number ?
                                                        <label>{userDetail.alternative_number}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                            }
                                        </ul>
                                        {userDetail.mobile_data && userDetail.mobile_data.length > 0 &&
                                            <ul>
                                                {userDetail.mobile_data.map((element, index) => (
                                                    <li key={index}>
                                                        <span>{"ASLI RI Mobile Number " + element.serial}</span>
                                                        <label>{element.mobile ? element.mobile : "-"}</label>
                                                    </li>
                                                ))}
                                            </ul>
                                        }
                                    </div>
                                </div>

                                <div className='d-flex  p-sm-t'>
                                    <div className='role-detail-preview preview-basic-details'>
                                        <div >
                                            <h3 style={{ display: "flex" }}>Bank Details
                                                {bankList.length > 0 && showEdit &&
                                                    <span onClick={() => showDetail(1)} className="verify-txt">Verify</span>
                                                }</h3>

                                        </div>
                                        <ul>
                                            <li>
                                                <span>Bank Name</span>
                                                {bankList.length > 0 ?
                                                    <label>{bankList[0].label}</label> :
                                                    <label>-</label>
                                                }
                                            </li>


                                            <li>
                                                <span>Branch</span>
                                                {userDetail.bank_response && userDetail.bank_response.branch_name ?
                                                    <label>{userDetail.bank_response.branch_name}</label> :
                                                    <label>-</label>
                                                }
                                            </li>

                                            <li>
                                                    <span>Account Number</span>
                                                    <label style={{ display: "flex" }}>{userDetail.bank_response && userDetail.bank_response.account_number ? userDetail.bank_response.account_number : "-"}
                                                        {(userDetail.bank_response && userDetail.bank_response.is_illuma_verified==1) ?
                                                            <span className="verify-txt">Verified</span>:null
                                                        }</label>                                                    
                                            </li>

                                            <li>
                                                <span>Beneficiary Name</span>
                                                {userDetail.bank_response && userDetail.bank_response.beneficiary_name ?
                                                    <label>{userDetail.bank_response.beneficiary_name}</label> :
                                                    <label>-</label>
                                                }
                                            </li>

                                            <li>
                                                <span>NPWP</span>
                                                {userDetail.bank_response && userDetail.bank_response.NPWP ?
                                                    <label>{userDetail.bank_response.NPWP}</label> :
                                                    <label>-</label>
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className='d-flex  p-sm-t'>
                                    <div className='role-detail-preview preview-basic-details'>
                                        <h3>Other Details</h3>
                                        <ul>
                                            <li>
                                                <span>DOB</span>
                                                {userDetail.dob && isNaN(Date.parse(userDetail.dob)) === false ?
                                                    <label>{new Date(userDetail.dob).toISOString().split('T')[0]}</label> :
                                                    <label>-</label>
                                                }
                                            </li>
                                            <li>
                                                <span>State</span>
                                                <label>{stateList.findIndex(e => e.id === userDetail.user_state) > -1 ? stateList.filter(e => e.id === userDetail.user_state)[0].label : "-"}</label>
                                            </li>
                                            <li>
                                                <span>City</span>
                                                <label>{cityList.findIndex(e => e.id === userDetail.user_city) > -1 ? cityList.filter(e => e.id === userDetail.user_city)[0].label : "-"}</label>
                                            </li>
                                            <li>
                                                <span>Locality</span>
                                                <label>{localityList.findIndex(e => e.id === userDetail.user_location) > -1 ? localityList.filter(e => e.id === userDetail.user_location)[0].label : "-"}</label>
                                            </li>
                                            {validationForFields.current_occupation.includes(userDetail.role_id) &&
                                                <li>
                                                    <span>Current Occupation</span>
                                                    <label>{ConfigList.current_occupation.findIndex(e => e.value === userDetail.current_occupation_id) > -1 ? ConfigList.current_occupation.filter(e => e.value === userDetail.current_occupation_id)[0].label : "-"}</label>
                                                </li>
                                            }
                                            {validationForFields.designation.includes(userDetail.role_id) &&
                                                <li>
                                                    <span>Designation</span>
                                                    <label>{designationList.findIndex(e => e.value === userDetail.designation_id) > -1 ? designationList.filter(e => e.value === userDetail.designation_id)[0].label : "-"}</label>
                                                </li>
                                            }
                                            {ConfigList.tagging_roles && validationForFields.tagging.includes(userDetail.role_id) &&
                                                <li>
                                                    <span>Tagging</span>
                                                    <label>{ConfigList.tagging_roles.findIndex(e => e.value === userDetail.tagging_id) > -1 ? ConfigList.tagging_roles.filter(e => e.value === userDetail.tagging_id)[0].label : "-"}</label>
                                                </li>
                                            }
                                            {/* {userDetail.tagging_id == "eca" && ConfigList.tagging_level && validationForFields.tagging.includes(userDetail.role_id) &&
                                                <li>
                                                    <span>Level</span>
                                                    <label>{ConfigList.tagging_level.findIndex(e => e.value === userDetail.tagging_level_id) > -1 ? ConfigList.tagging_level.filter(e => e.value === userDetail.tagging_level_id)[0].label : "-"}</label>
                                                </li>
                                            } */}
                                            {ConfigList.tagging_roles && validationForFields.tagging.includes(userDetail.role_id) &&
                                                <li>
                                                    <span>Tagging Change Date</span>
                                                    {userDetail.business_line_change_date && isNaN(Date.parse(userDetail.business_line_change_date)) === false ?
                                                        <label>{DateFormat(userDetail.business_line_change_date, "yyyy-mm-dd")}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                            }
                                             {validationForFields.source.includes(userDetail.role_id) &&
                                                    <li>
                                                        <span>Source</span>
                                                        <label>{sources.findIndex(e => e.value === userDetail.source_id) > -1 ? sources.filter(e => e.value === userDetail.source_id)[0].label : "-"}</label>
                                                    </li>
                                            }
                                            <li>
                                                <span>Postal Code</span>
                                                <label>{userDetail.pincode ? userDetail.pincode : "-"}</label>
                                            </li>
                                            <li>
                                                <span>House No.</span>
                                                <label>{userDetail.house_no ? userDetail.house_no : "-"}</label>
                                            </li>
                                            <li>
                                                <span>RT</span>
                                                <label>{userDetail.rt ? userDetail.rt : "-"}</label>
                                            </li>
                                            <li>
                                                <span>RW</span>
                                                <label>{userDetail.rw ? userDetail.rw : "-"}</label>
                                            </li>
                                            {validationForFields.incentive_percentage.includes(userDetail.role_id) &&
                                                <li>
                                                    <span>Incentive%</span>
                                                    {userDetail.incentive_percentage > 0 ?
                                                        <label>{userDetail.incentive_percentage}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                            }
                                            <li className='address-txt'>
                                                <span>Address</span>
                                                <label>{userDetail.address ? userDetail.address : "-"}</label>
                                            </li>
                                            {validationForFields.is_tax_booster_enable.includes(userDetail.role_id) &&
                                                <li>
                                                    <span>Tax Booster Enable</span>
                                                    <label>{userDetail.is_tax_booster_enable == true ? "Yes" : "No"}</label>
                                                </li>
                                            }
                                            {validationForFields.bm_grading.includes(userDetail.role_id) &&
                                                <li>
                                                    <span>{(TENANT=="id" || TENANT=="tri-id") ? "BM Grading" : "MM Grading" }</span>
                                                    <label>{ConfigList.bm_grading.findIndex(e => e.value === userDetail.bm_grading) > -1 ? ConfigList.bm_grading.filter(e => e.value === userDetail.bm_grading)[0].label : "-"}</label>
                                                </li>
                                            }
                                            {validationForFields.company_name.includes(userDetail.role_id) &&
                                                <li>
                                                    <span>Company Name</span>
                                                    <label title={userDetail.company_name ? userDetail.company_name : ""}>{userDetail.company_name ? userDetail.company_name : "-"}</label>
                                                </li>
                                            }
                                            {validationForFields.company_address.includes(userDetail.role_id) &&
                                                <li>
                                                    <span>Company Address</span>
                                                    <label title={userDetail.company_address ? userDetail.company_address : ""}>{userDetail.company_address ? userDetail.company_address : "-"}</label>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>

                                <div className='d-flex  p-sm-t'>
                                    {/* {userDetail.password_text &&
                                            <div className='role-detail-preview preview-basic-details'>
                                                <h3>Password</h3>
                                                <ul>
                                                    <li>
                                                        <span>Password&nbsp;&nbsp;<i className="ic-remove_red_eyevisibility" onClick={() => setShowPassword(!showPassword)}></i></span>
                                                        <label>{passwordToShow}</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        } */}
                                    {validationForFields.referral_name.includes(userDetail.role_id) && ReferralName && ReferralName?.length > 0 && userDetail.referral_user_id > 0 &&
                                        <div className='role-detail-preview'>
                                            <h3>Reference</h3>
                                            <ul>
                                                <li>
                                                    <span>Reference Name & ID</span>
                                                    <label>{ReferralName.findIndex(e => e.value === userDetail.referral_user_id) > -1 ? ReferralName.filter(e => e.id === userDetail.referral_user_id)[0].label : ""}</label>
                                                </li>
                                            </ul>
                                        </div>
                                    }


                                </div>
                                {(TENANT=="id") &&
                                    <div className='d-flex  p-sm-t m-xl-b p-md-b'>
                                        <div className='role-detail-preview'>
                                            <h3>Dealer Inventory Funding</h3>
                                            <li className="custom-control custom-checkbox">
                                                <span>Dealer Inventory Funding Subscribe: </span>
                                                <input
                                                    id={`is_subscribe_dif`}
                                                    type="checkbox"
                                                    name="is_subscribe_dif"
                                                    className="custom-control-input"
                                                    checked={userDetail.is_subscribe_dif ? true : false}
                                                />

                                                <label htmlFor={`is_subscribe_dif`} className="custom-control-label">
                                                </label>
                                            </li>
                                        </div>
                                    </div>
                                }

                                <div className='d-flex  p-sm-t m-xl-b p-md-b'>
                                    <div className='role-detail-preview preview-basic-details'>
                                        <h3>Additional Details</h3>
                                        <ul>
                                            {userDetail.created_date && isNaN(Date.parse(userDetail.created_date)) === false &&
                                                <li>
                                                    <span>Created Date</span>
                                                    <label>{new Date(userDetail.created_date).toISOString().split('T')[0]}</label>
                                                </li>
                                            }
                                            {userDetail.approval_date && isNaN(Date.parse(userDetail.approval_date)) === false &&
                                                <li>
                                                    <span>Approval Date</span>
                                                    <label>{new Date(userDetail.approval_date).toISOString().split('T')[0]}</label>
                                                </li>
                                            }
                                            {userDetail.approval_date && isNaN(Date.parse(userDetail.approval_date)) === false &&
                                                <li>
                                                    <span>Approved By</span>
                                                    <label>{userDetail.approved_by_admin_name ? userDetail.approved_by_admin_name : "-"}</label>
                                                </li>
                                            }
                                            <li>
                                                <span>Onboarded From</span>
                                                <label>{OnboardedFrom.findIndex(e => e.value === userDetail.user_source) > -1 ? OnboardedFrom.filter(e => e.value === userDetail.user_source)[0].label : "-"}</label>
                                            </li>
                                            <li>
                                                <span>Onboarded By</span>
                                                <label>{(userDetail.user_source && userDetail.user_source == 'admin') ? userDetail.created_by_admin_name : userDetail.created_by_user_name}</label>
                                            </li>
                                            <li>
                                                <span>Resign Date 1</span>
                                                {userDetail.resign_date_1 && isNaN(Date.parse(userDetail.resign_date_1)) === false ?
                                                    <label>{new Date(userDetail.resign_date_1).toISOString().split('T')[0]}</label> :
                                                    <label>-</label>
                                                }
                                            </li>

                                            <li>
                                                <span>Resign Date 2</span>
                                                {userDetail.resign_date_2 && isNaN(Date.parse(userDetail.resign_date_2)) === false ?
                                                    <label>{new Date(userDetail.resign_date_2).toISOString().split('T')[0]}</label> :
                                                    <label>-</label>
                                                }
                                            </li>
                                            <li>
                                                <span>Rejoin Date 1</span>
                                                {userDetail.rejoin_date_1 && isNaN(Date.parse(userDetail.rejoin_date_1)) === false ?
                                                    <label>{new Date(userDetail.rejoin_date_1).toISOString().split('T')[0]}</label> :
                                                    <label>-</label>
                                                }
                                            </li>
                                            <li>
                                                <span>Rejoin Date 2</span>
                                                {userDetail.rejoin_date_2 && isNaN(Date.parse(userDetail.rejoin_date_2)) === false ?
                                                    <label>{new Date(userDetail.rejoin_date_2).toISOString().split('T')[0]}</label> :
                                                    <label>-</label>
                                                }
                                            </li>

                                            <li>
                                                <span>Hiring Source</span>
                                                <label>{BasicDetailConfig.hiring_source.findIndex(e => e.value === userDetail.hiring_source) > -1 ? BasicDetailConfig.hiring_source.filter(e => e.value === userDetail.hiring_source)[0].label : "-"}</label>
                                            </li>

                                            <li className='address-txt'>
                                                <span>Comments</span>
                                                <label>{userDetail.comments ? userDetail.comments : "-"}</label>
                                            </li>
                                            <li className='address-txt'>
                                                <span>Inactivated By</span>
                                                <label>{userDetail.inactive_by_name ? userDetail.inactive_by_name : "-"}</label>
                                            </li>
                                            {userDetail.status == 0 && userDetail.inactive_date && isNaN(Date.parse(userDetail.inactive_date)) === false &&
                                                <li>
                                                    <span>Inactive Date</span>
                                                    <label>{new Date(userDetail.inactive_date).toISOString().split('T')[0]}</label>
                                                </li>
                                            }
                                            {userDetail.role_id == Role.Agent &&
                                                <li className='address-txt'>
                                                    <span>Manager Status</span>
                                                    <label>{userDetail.manager_status ? userDetail.manager_status : "-"}</label>
                                                </li>
                                            }
                                            {userDetail.status === INACTIVE_STATUS &&
                                                <li>
                                                    <span>Reason For Inactive User</span>
                                                    {inactive_data && inactive_data.length > 0 ?
                                                        <label className='d-flex'>
                                                            {inactive_data[0].label}
                                                            <div id='other_reason_tooltip'>
                                                                <Tooltip
                                                                    useContext
                                                                    html={(
                                                                        <div className="controlled-example tooltip-main">
                                                                            <div className="tooltip-data">
                                                                                {/* <span className="controlled-example_close-button" onClick={() => toggleTip(user_id, 'close')}><i className="ic-clearclose"></i></span> */}
                                                                                <span>{inactive_data[0].inactive_reason_comment}</span>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    className="tootip-outer"
                                                                    // open="true"
                                                                    theme="light"
                                                                    size="regular"
                                                                    position="bottom"
                                                                    trigger="click"
                                                                >
                                                                    <span title="" className="tooltio-ic"> <i className='ic-info-icon1'></i>
                                                                    </span>
                                                                </Tooltip>
                                                            </div>
                                                        </label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>


                                {ConfigList && ConfigList.document && ConfigList.document?.length > 0 && show_doc && userDetail.document_response && userDetail.document_response?.length > 0 &&
                                    < div className='top-heading d-flex'>
                                        <h2>Documents</h2>
                                        {isActionAllowed('edit_user', restrictAgentEditing()) && showEdit &&
                                            <a onClick={() => handleEdit("/edit-user/documents?id=" + user_id)} className='edit-opt'>Edit</a>
                                        }
                                    </div>
                                }

                                <div className='d-flex'>
                                    <div className='role-detail-preview doc-preview-details'>

                                        <div className='upload-document-outer'>
                                            {ConfigList && ConfigList.document && ConfigList.document?.length > 0 && userDetail.document_response && userDetail.document_response?.length > 0 && userDetail.document_response.map((data, index) => (
                                                <div key={index}>
                                                    {data.doc_url !== "" ?
                                                        <div className="img-bx-select-opt img-doc-preview doc_status">
                                                            <div className="image-bx">
                                                                <img onClick={() => { showDetail(index) }} src={data.doc_url ? data.doc_url : ""} className="" alt="No Document" />
                                                            </div>
                                                            <span className='document-name'>{ConfigList.document.filter(e => parseInt(e.id) === parseInt(data.document_id))[0].doc_title}</span>
                                                            {/* {(userDetail.status === '4' || userDetail.status === '3') &&  */}
                                                            <div>{data.is_doc_verified ? <span className='document-name verified_text'>Verified</span> :
                                                                <span className='document-name not_verified_text'>Not Verified</span>}</div>
                                                            {/* } */}
                                                        </div> :
                                                        null}
                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                </div>

                                {
                                    ifDocList?.length ?
                                        <>
                                            <div className='top-heading d-flex'>
                                                <h2>IF-Bank Statement</h2>
                                            </div>
                                            <div className='d-flex'>
                                                <div className='role-detail-preview doc-preview-details'>
                                                    <div className='upload-document-outer'>
                                                        {ifDocList.map((data, index) => (
                                                            <>
                                                                <div key={index}>
                                                                    <div className="img-bx-select-opt img-doc-preview doc_status">
                                                                        <div className="image-bx">
                                                                            {guessFileType(data.doc_url) === 'Image' ? (
                                                                                <img
                                                                                    onClick={() => showIfDetail(index)}
                                                                                    src={data.doc_url}
                                                                                    className=""
                                                                                    alt="Document Preview"
                                                                                />
                                                                            ) : (
                                                                                <i
                                                                                    style={{
                                                                                        fontSize: '48px',
                                                                                        width: '100%',  
                                                                                        height: '100%',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    className="ic-file-icon"
                                                                                    onClick={() => showIfDetail(index)}
                                                                                ></i>
                                                                            )}
                                                                        </div>
                                                                        <span className='document-name'>
                                                                            {`${data.doc_title} Updated on ${DateFormat(data.updated_date, "dd mmm, yyyy")}`}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </> : null
                                }

                                { /* esignApprovedHTML?.length || */ esignUserDoc?.length ?
                                    <>
                                        <div className='top-heading d-flex'>
                                            <h2>Esign Agreement</h2>
                                        </div>

                                        {esignUserDoc?.length ? (
                                            <div className='d-flex' style={{ marginBottom: "15px" }}>
                                                <div className='role-detail-preview'>
                                                    {esignUserDoc.map((ele) => {
                                                        return (
                                                            <div className='row' style={{ marginBottom: "5px" }}>
                                                                <label class="col-sm-12">{`Esign Doc\t:\t`}<a href={() => false} onClick={() => { handleDownload(ele) }} title="Document" rel="noopener noreferrer">View ({DateFormat(ele.esign_approval_date, "yyyy-mm-dd hh:MM TT")})</a></label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        ) : null}

                                        {/*  {esignApprovedHTML?.length ? (
                                            <div className='d-flex' style={{ marginBottom: "15px" }}>
                                                <div className='role-detail-preview'>
                                                    {esignApprovedHTML.map((ele) => {
                                                        let encodedID = Buffer.from(`${ele.id}`).toString('base64');
                                                        return (
                                                            <div className='row' style={{ marginBottom: "5px" }}>
                                                                <label class="col-sm-12">{`Esign Link\t:\t`}<a href={`${ESIGN_BASE_URL}?id=${encodedID}`} title="Document" target="_blank" rel="noreferrer">View ({DateFormat(ele.created_date, "yyyy-mm-dd hh:MM TT")})</a></label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        ) : null} */}

                                    </> : null}

                                {commitmentForm?.length ?
                                    <>
                                        <div className='top-heading d-flex'>
                                            <h2>Employee Commitment Form</h2>
                                        </div>

                                        {commitmentForm?.length ? (
                                            <div className='d-flex' style={{ marginBottom: "15px" }}>
                                                <div className='role-detail-preview'>
                                                    {commitmentForm.map((ele) => {
                                                        return (
                                                            <div className='row' style={{ marginBottom: "5px" }}>
                                                                <label class="col-sm-12">{`Employee Commitment Form\t:\t`}<a href={() => false} onClick={() => { handleDownloadCommitmentForm(ele) }} title="Document" rel="noopener noreferrer">View ({DateFormat(ele.commitment_approval_date, "yyyy-mm-dd hh:MM TT")})</a></label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        ) : null}
                                    </> : null}

                                {terminationForm?.length ?
                                    <>
                                        <div className='top-heading d-flex'>
                                            <h2>Termination Form</h2>
                                        </div>

                                        {terminationForm?.length ? (
                                            <div className='d-flex' style={{ marginBottom: "15px" }}>
                                                <div className='role-detail-preview'>
                                                    {terminationForm.map((ele) => {
                                                        return (
                                                            <div className='row' style={{ marginBottom: "5px" }}>
                                                                <label class="col-sm-12">{`Termination Form\t:\t`}<a href={() => false} onClick={() => { handleDownloadTerminationForm(ele) }} title="Document" rel="noopener noreferrer">View ({DateFormat(ele.termination_approval_date, "yyyy-mm-dd hh:MM TT")})</a></label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        ) : null}
                                    </> : null}

                                <div className="t-right text-right">
                                    <button className='btn-primary' onClick={handleBack.bind(this)}>
                                        Back
                                    </button>
                                </div>

                                <div className="vechile-document-popup model-popup-outer verify-doc-detail-popup">
                                    <Modal show={show_image_detail} handleClose={() => hideDetail()} >
                                        <div className="modal-header">
                                            <h2>Verify Details with Documents</h2>
                                            {/* <span className='subheading'>Bank Statement</span> */}
                                        </div>
                                        <div className="modal-body">
                                            <div className='slider-left-sec'>
                                                {userDetail && userDetail.document_response &&
                                                    <SimpleSlider ConfigList={ConfigList} errors={errors} image_width={image_width} rotated_angle={rotated_angle} activeIndex={activeIndex} changeSlide={changeSlide} doc={userDetail.document_response} />
                                                }                                      
                                            </div>


                                            <div className="slider-right-sec">

                                            {(activeIndex === 0 || activeIndex === 1)  &&
                                                    <fieldset className="">
                                                        <div className="material">
                                                            <input onBlur={checkExisting} type="number" name="ktp_id" id="ktp_id" label="KTP ID" placeholder=" " value={userDetail.ktp_id} onChange={handleInputChange.bind(null, 'ktp_id', 'text')} required={true} className="form-input" />
                                                            <label data-label="KTP ID*" className="form-label"></label>
                                                            {
                                                                (errors && errors.ktp_id) ? <span className="error show">{errors.ktp_id}</span> : ''
                                                            }
                                                        </div>
                                                    </fieldset>
                                                }

                                                {(activeIndex === 2) &&
                                                    <div>
                                                        <fieldset className="single-select ">
                                                            {bankOption && bankOption.length > 0 &&
                                                                <div className="material">
                                                                    <Select
                                                                        components={{ ValueContainer: CustomValueContainer }}
                                                                        // components={{ IndicatorSeparator: () => null }}
                                                                        options={bankOption}
                                                                        isDisabled={adminRoleId === ADMIN_ROLES['super_admin'] ? false : (userDetail && userDetail.bank_response && userDetail.bank_response.account_number && userDetail.bank_response.beneficiary_name) ? !enable_bank_info : false}
                                                                        placeholder="Bank Name*"
                                                                        value={bankOption.filter((e) => e.id === userDetail.bank_response.sfa_bank_id)}
                                                                        className="react-select"
                                                                        classNamePrefix="react-select"
                                                                        onChange={handleInputChange.bind(null, 'sfa_bank_id', "select")}
                                                                    />
                                                                    {
                                                                        (errors && errors.sfa_bank_id) ? <span className="error show">{errors.sfa_bank_id}</span> : ''
                                                                    }
                                                                </div>
                                                            }
                                                        </fieldset>
                                                        {userDetail.bank_response &&
                                                            <fieldset className="">
                                                                <div className="material">
                                                                    <InputField disabled={adminRoleId === ADMIN_ROLES['super_admin'] ? false : (userDetail && userDetail.bank_response && userDetail.bank_response.account_number && userDetail.bank_response.beneficiary_name) ? !enable_bank_info : false} type="text" error={(errors.branch_name) ? errors.branch_name : ''} name="branch_name" id="branch_name" label="Branch" placeholder=" " value={userDetail.bank_response.branch_name} onChange={handleInputChange.bind(null, 'branch_name', 'text')} required={true} />
                                                                    <label data-label="Branch*" className="form-label"></label>
                                                                </div>
                                                            </fieldset>
                                                        }
                                                        {userDetail.bank_response &&
                                                            <fieldset className="">
                                                                <div className="material">
                                                                    <InputField disabled={adminRoleId === ADMIN_ROLES['super_admin'] ? false : (userDetail && userDetail.bank_response && userDetail.bank_response.account_number && userDetail.bank_response.beneficiary_name) ? !enable_bank_info : false} type="text" error={(errors.beneficiary_name) ? errors.beneficiary_name : ''} name="beneficiary_name" id="beneficiary_name" label="Beneficiary Name" placeholder=" " value={userDetail.bank_response.beneficiary_name} onChange={handleInputChange.bind(null, 'beneficiary_name', 'text')} required={true} />
                                                                    <label data-label="Beneficiary Name*" className="form-label"></label>
                                                                </div>
                                                            </fieldset>
                                                        }
                                                        {userDetail.bank_response &&
                                                            <fieldset className="">
                                                                <div className="material">
                                                                    <input onBlur={checkExisting} disabled={adminRoleId === ADMIN_ROLES['super_admin'] ? false : (userDetail && userDetail.bank_response && userDetail.bank_response.account_number && userDetail.bank_response.beneficiary_name) ? !enable_bank_info : false} type="password" name="account_number" id="account_number" label="Account Number" placeholder=" " value={userDetail.bank_response.account_number} onChange={handleInputChange.bind(null, 'account_number', 'text')} required={true} className="form-input" maxLength={account_length > 0 ? account_length : ""} RestrictOnPaste={1} RestrictOnCopy={1} />
                                                                    <label data-label="Account Number*" className="form-label"></label>
                                                                    {
                                                                        (errors && errors.account_number) ? <span className="error show">{errors.account_number}</span> : ''
                                                                    }
                                                                </div>
                                                            </fieldset>
                                                        }
                                                        {userDetail.bank_response &&
                                                            <fieldset className="">
                                                                <div className="material">
                                                                    <input onBlur={checkExisting} disabled={adminRoleId === ADMIN_ROLES['super_admin'] ? false : (userDetail && userDetail.bank_response && userDetail.bank_response.account_number && userDetail.bank_response.beneficiary_name) ? !enable_bank_info : false} type="number" name="confirm_account_number" id="confirm_account_number" label="Confirm Account Number" placeholder=" " value={userDetail.bank_response.confirm_account_number} onChange={handleInputChange.bind(null, 'confirm_account_number', 'text')} required={true} className="form-input" maxLength={account_length > 0 ? account_length : ""} RestrictOnPaste={1} RestrictOnCopy={1} />
                                                                    <label data-label="Confirm Account Number*" className="form-label"></label>
                                                                    {
                                                                        (errors && errors.confirm_account_number) ? <span className="error show">{errors.confirm_account_number}</span> : ''
                                                                    }
                                                                </div>
                                                            </fieldset>
                                                        }                                                    
                                                    </div>
                                                }
                                                {(activeIndex === 3) &&
                                                    <div>
                                                        {userDetail.bank_response &&
                                                            <fieldset className="">
                                                                <div className="material">
                                                                    <NumberFormat
                                                                        className="form-input"
                                                                        format="##.###.###.#-###.###"
                                                                        mask="_"
                                                                        id="NPWP"
                                                                        name="NPWP"
                                                                        label="NPWP"
                                                                        placeholder="NPWP"
                                                                        // disabled={userDetail.npwp_disabled ? userDetail.npwp_disabled : false}
                                                                        value={userDetail.bank_response.NPWP || ''}
                                                                        onBlur={checkExisting}
                                                                        onValueChange={handleInputChange.bind(null, 'NPWP', 'number')}
                                                                    />
                                                                    <label data-label="NPWP" className="form-label"></label>
                                                                    {
                                                                        (errors && errors.NPWP) ? <span className="error show">{errors.NPWP}</span> : ''
                                                                    }
                                                                </div>
                                                            </fieldset>
                                                        }
                                                    </div>
                                                }                                        
                                            {ConfigList && ConfigList.document && ConfigList.document?.length > 0 && userDetail.document_response?.length > 0 &&
                                                        <div className='role-detail-preview d-flex user-wallet-view user_view_doc'>
                                                            <ul>
                                                                <li className="custom-control custom-checkbox">
                                                                    <input
                                                                        id={`agent_wallet_` + activeIndex}
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        onChange={() => handleDocVerifiedStatus(activeIndex)}
                                                                        disabled={userDetail.document_response[activeIndex].edit_verify_status ? false : true}
                                                                        checked={userDetail.document_response[activeIndex].is_doc_verified}
                                                                    />
                                                                    <label htmlFor={`agent_wallet_` + activeIndex} className="custom-control-label">
                                                                        Verified
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                            <button onClick={() => handleReUpload("")} className="btn-line btn-re-upload">Re-upload</button>
                                                        </div>
                                                    }
                                                {isActionAllowed('edit_user', restrictAgentEditing()) &&
                                                    <button className='btn-primary btn-view-update' onClick={() => handleUpdate()}>
                                                        Update
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                        <div className='modal-footer'>
                                        <div className="image-opt slider-action-btn">
                                                        {userDetail && userDetail.document_response && userDetail.document_response?.length > 0 &&
                                                            <ul>
                                                                {/* <li>
                                                                        <a onClick={() => handleRemove()}>
                                                                            <i className="ic-delete-icn"></i>
                                                                        </a>
                                                                    </li> */}
                                                                <li>
                                                                    <a href={() => false} onClick={handleRotate}>
                                                                        <i className="ic-refresh"></i>
                                                                    </a>
                                                                </li>
                                                                {/* <li>
                                                                        <a >
                                                                            <i className="ic-fullscreen"></i>
                                                                        </a>
                                                                    </li> */}
                                                                <li>
                                                                    <a href={() => false} onClick={zoomIn}>
                                                                        <i className="ic-add 1"></i>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a  href={() => false} onClick={zoomOut}>
                                                                        <i className="ic-remove"></i>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        }
                                                        {ConfigList && ConfigList.document && ConfigList.document?.length > 0 && userDetail.document_response?.length > 0 &&
                                                            <input
                                                                type="file"
                                                                name={ConfigList.document.filter(e => parseInt(e.id) === parseInt(userDetail.document_response[activeIndex].document_id))[0].doc_title}
                                                                accept="image/*"
                                                                id={"upload-image-doc_" + activeIndex}
                                                                className="upload"
                                                                style={{ display: 'none' }}
                                                                onChange={handleChangeImage}
                                                            />
                                                        }
                                                      {/* {ConfigList && ConfigList.document && ConfigList.document?.length > 0 && userDetail.document_response?.length > 0 &&
                                                                <div className='role-detail-preview d-flex user-wallet-view doc_verify_check'>
                                                                    <ul>
                                                                        <li className="custom-control custom-checkbox">
                                                                            <input
                                                                                id={`agent_wallet_` + activeIndex}
                                                                                type="checkbox"
                                                                                className="custom-control-input"
                                                                                onChange={() => handleDocVerifiedStatus(activeIndex)}
                                                                                disabled={userDetail.document_response[activeIndex].edit_verify_status ? false : true}
                                                                                checked={userDetail.document_response[activeIndex].is_doc_verified}
                                                                            />
                                                                            <label htmlFor={`agent_wallet_` + activeIndex} className="custom-control-label">
                                                                                Verified
                                                                            </label>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            } */}
                                                        {/* <button onClick={() => handleReUpload("")} className="btn-line btn-re-upload">Re-upload</button> */}
                                                    </div>
                                                </div>
                                    </Modal>
                                </div>

                                <div className="vechile-document-popup model-popup-outer">
                                    <Modal show={showIfDocDetails} handleClose={() => hideIfDetail()} >
                                        <div className="modal-body">
                                            <IfSimpleSlider ConfigList={ConfigList} errors={errors} image_width={image_width} rotated_angle={rotated_angle} activeIndex={activeIfIndex} changeSlide={changeIfSlide} doc={ifDocList} />
                                        </div>
                                        <div className='modal-footer'>
                                            <div className="image-opt p-rel">
                                                {ifDocList?.length &&
                                                    <ul>
                                                        <li>
                                                            <a href={() => false} onClick={handleRotate}>
                                                                <i className="ic-refresh"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href={() => false} onClick={zoomIn}>
                                                                <i className="ic-add 1"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href={() => false} onClick={zoomOut}>
                                                                <i className="ic-remove"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href={() => false} onClick={handleIfDownload}>
                                                                <img src={downloadIcon} alt='Download Icon' />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                }
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey={"second"}>
                            {showMappedRegions ? <ViewMappedRegions /> : null}
                        </Tab.Pane>
                        <Tab.Pane eventKey={"third"}>
                            {showReportees ? <ViewReportees /> : null}
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
            <div className="model-popup-outer role-history-popup">
                <Modal show={UserHistoryPopup} handleClose={hideModalUserHistoryPopup} >
                    <div className="modal-header">
                        <h2>User Timeline</h2>
                    </div>

                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Nav variant="pills" className="flex-column tab-line nav nav-pills">
                            <div className="tab-list">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Role History</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Account History</Nav.Link>
                                </Nav.Item>
                                {TENANT == "id" && parseInt(userDetail.role_id) === parseInt(Role.Agent) &&
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">Vendor History</Nav.Link>
                                    </Nav.Item>
                                }
                                {(TENANT=="id") &&
                                    <Nav.Item>
                                        <Nav.Link eventKey="fourth">Journey History</Nav.Link>
                                    </Nav.Item>
                                }
                                {userDetail.role_id == Role.BM &&
                                    <Nav.Item>
                                        <Nav.Link eventKey="fifth">{(TENANT=="id" || TENANT=="tri-id") ? "BM Grading History" : "MM Grading History" }</Nav.Link>
                                    </Nav.Item>
                                }
                                {parseInt(userDetail.role_id) === parseInt(Role.Agent) &&
                                        <Nav.Item>
                                            <Nav.Link eventKey="sixth">App History</Nav.Link>
                                        </Nav.Item>
                                }
                            </div>
                        </Nav>
                        <div className='modal-body' >
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className='data-table'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Created On</th>
                                                    <th>Role</th>
                                                    <th>Tagging</th>
                                                    <th>Primary Manager</th>
                                                    {/* {TENANT === 'id' &&<th>Secondary Manager</th>} */}
                                                    <th>Business Line</th>
                                                    <th>Status</th>
                                                    <th>Joining Date</th>
                                                    <th>Mobile</th>
                                                    <th>Created By</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {UserRoleHistoryList && UserRoleHistoryList.length === 0 ? <tr><td align="center" colSpan="8"><h6 className="text-center text-danger text-bold">No Records Found</h6></td></tr> : null}
                                                {
                                                    UserRoleHistoryList && UserRoleHistoryList.length !== 0 && UserRoleHistoryList.map((userhistorydata, k) => (
                                                        <tr key={k} >
                                                            <td><span>{DateFormat(new Date(userhistorydata.created_date), 'dd mmm, yyyy')}</span></td>
                                                            <td>{userhistorydata.role_name}</td>
                                                            <td>{userhistorydata.tagging_name}</td>
                                                            <td>{userhistorydata.manager_name?userhistorydata.manager_name + "("+userhistorydata.manager_id+") - "+RoleId[userhistorydata.manager_role_id]:""}</td>
                                                            {/* {TENANT === 'id' &&<td>{userhistorydata.secondary_manager_name?userhistorydata.secondary_manager_name + "("+userhistorydata.secondary_manager_id+") - "+RoleId[userhistorydata.secondary_manager_role_id]:""}</td>} */}
                                                            <td>{userhistorydata.business_line}</td>
                                                            <td>{userhistorydata.user_status_name}</td>
                                                            <td>{userhistorydata.doj}</td>
                                                            <td>{userhistorydata.mobile}</td>                                                            
                                                            <td>{(userhistorydata.action_via == 'admin' && userhistorydata.display_name && userhistorydata.created_by_id) ? "Admin-"+userhistorydata.display_name+"-"+userhistorydata.created_by_id :(userhistorydata.action_via == 'user' && userhistorydata.created_by && userhistorydata.created_by_id)? "SFA User-"+userhistorydata.created_by+"-"+userhistorydata.created_by_id:""}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className='data-table'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Created On</th>
                                                    <th>Account Number</th>
                                                    <th>Beneficiary Name</th>
                                                    <th>Branch Name</th>
                                                    <th>Bank Name</th>
                                                    <th>NPWP</th>
                                                    <th>Created By</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {UserAccountHistoryList && UserAccountHistoryList.length == 0 ? <tr><td align="center" colSpan="6"><h6 className="text-center text-danger text-bold">No Records Found</h6></td></tr> : null}
                                                {
                                                    UserAccountHistoryList && UserAccountHistoryList.length != 0 && UserAccountHistoryList.map((useraccountdata, k) => (
                                                        <tr key={k} >
                                                            <td><span>{DateFormat(new Date(useraccountdata.created_date), 'dd mmm, yyyy')}</span></td>
                                                            <td>{useraccountdata.account_number}</td>
                                                            <td className="truncate">{useraccountdata.beneficiary_name}</td>
                                                            <td>{useraccountdata.branch_name}</td>
                                                            <td>{useraccountdata.bank_name}</td>
                                                            <td>{useraccountdata.npwp}</td>
                                                            <td>{(useraccountdata.action_via == 'admin') ? useraccountdata.display_name : useraccountdata.created_by}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <div className='data-table'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Created On</th>
                                                    <th>Type</th>
                                                    <th>Status</th>
                                                    <th>Vendor ID</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {VendorHistoryList && VendorHistoryList.length == 0 ? <tr><td align="center" colSpan="4"><h6 className="text-center text-danger text-bold">No Records Found</h6></td></tr> : null}
                                                {
                                                    VendorHistoryList && VendorHistoryList.length != 0 && VendorHistoryList.map((uservendordata, k) => (
                                                        <tr key={k} >
                                                            <td><span>{DateFormat(new Date(uservendordata.created_date), 'dd mmm, yyyy')}</span></td>
                                                            <td>{uservendordata.show_type}</td>
                                                            <td>{uservendordata.show_response_status}</td>
                                                            <td>{uservendordata.vendor_id}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fourth">
                                    <div className='data-table'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Date & Time</th>
                                                    {/* <th>Current Role/Tagging</th> */}
                                                    <th>Recommended Role/Tagging</th>
                                                    <th>Recommended By</th>
                                                    <th>Comments</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {ProgressionHistoryList && ProgressionHistoryList.length == 0 ? <tr><td align="center" colSpan="4"><h6 className="text-center text-danger text-bold">No Records Found</h6></td></tr> : null}
                                                {
                                                    ProgressionHistoryList && ProgressionHistoryList.length != 0 && ProgressionHistoryList.map((userprogressiondata, k) => (
                                                        <tr key={k} >
                                                            <td><span>{DateFormat(new Date(userprogressiondata.created_date), 'dd mmm, yyyy h:MM TT')}</span></td>
                                                            {/* <td>{(userprogressiondata.current_role ? userprogressiondata.current_role : "") + "/" + (userprogressiondata.current_tagging ? userprogressiondata.current_tagging : "")}</td> */}
                                                            <td>{(userprogressiondata.recommended_role ? userprogressiondata.recommended_role : "") + "/" + (userprogressiondata.recommended_tagging ? userprogressiondata.recommended_tagging : "")}</td>
                                                            <td style={{ textTransform: "capitalize" }}>{userprogressiondata.recommended_by ? userprogressiondata.recommended_by : ""}</td>
                                                            <td>{userprogressiondata.comment ? <Tooltip
                                                                useContext
                                                                html={(
                                                                    <div className="controlled-example tooltip-main">
                                                                        <div className="tooltip-data">
                                                                            <h3 className="subheading">{userprogressiondata.comment}</h3>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                className="tootip-outer"
                                                                // open="true"
                                                                theme="light"
                                                                size="regular"
                                                                position="bottom"
                                                                trigger="click"
                                                            >
                                                                <span>{(userprogressiondata.comment) ? (userprogressiondata.comment.length > 10) ? userprogressiondata.comment.substr(0, 10) + "..." : userprogressiondata.comment : ''}</span>
                                                            </Tooltip> : ""}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fifth">
                                    <div className='data-table'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Grade</th>
                                                    <th>Updated By</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {BMGradingHistoryList && BMGradingHistoryList.length == 0 ? <tr><td align="center" colSpan="3"><h6 className="text-center text-danger text-bold">No Records Found</h6></td></tr> : null}
                                                {
                                                    BMGradingHistoryList && BMGradingHistoryList.length != 0 && BMGradingHistoryList.map((userbmgradingdata, k) => (
                                                        <tr key={k} >
                                                            <td><span>{DateFormat(new Date(userbmgradingdata.updated_date), 'dd mmm, yyyy')}</span></td>
                                                            <td>{userbmgradingdata.bm_grade}</td>
                                                            <td>{userbmgradingdata.created_by ? userbmgradingdata.created_by : "System"}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="sixth">
                                        <div className='data-table'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Created Date</th>
                                                        <th>First Login</th>
                                                        <th>Last Log in</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {AppUsageList && AppUsageList.length == 0 ? <tr><td align="center" colSpan="3"><h6 className="text-center text-danger text-bold">No Records Found</h6></td></tr> : null}
                                                    {
                                                        AppUsageList && AppUsageList.length != 0 && AppUsageList.map((userData, k) => (
                                                            <tr key={k} >
                                                                <td><span>{DateFormat(new Date(userData.created_date), 'dd mmm, yyyy')}</span></td>
                                                                <td><span>{DateFormat(new Date(userData.first_login_date), 'dd mmm, yyyy')}</span></td>
                                                                <td><span>{DateFormat(new Date(userData.last_login_date), 'dd mmm, yyyy')}</span></td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Tab.Pane>
                            </Tab.Content>
                        </div>

                    </Tab.Container>
                </Modal>

            </div>
    {/* modal for reason of rejection */}
    <div className='model-popup-outer rejection_reason_modal'>
                    <Modal show={rejectionReasonModal} handleClose={hideRejectionReasonModal} >
                        <div className="modal-header">
                            <h2>Are you sure you want to Reject User?</h2>
                        </div>
                        <div className="modal-body">
                            {ConfigList.rejection_reason &&
                                <div className='rejection_form'>
                                    <fieldset className="single-select">
                                        <div className="material">
                                            <Select components={{ ValueContainer: CustomValueContainer }}
                                                options={ConfigList.rejection_reason}
                                                value={ConfigList.rejection_reason.filter((e) => e.value === reason_for_rejection)}
                                                onChange={handleRejectSelect.bind(null)}
                                                placeholder="Reason For Rejection*"
                                                className="react-select"
                                                classNamePrefix="react-select"
                                            />
                                        </div>
                                        {
                                            (errors && errors.reason_for_rejection) ? <span className="error show">{errors.reason_for_rejection}</span> : ''
                                        }
                                    </fieldset>
                                    {reason_for_rejection === "others" &&
                                        <fieldset className="p-lg-t p-lg-b">
                                            <div class="material">
                                            <textarea placeholder=' ' onChange={(e) => handleChangetext(e, 'reject')} rows='6' className="form-input" name="rejection_reason_comment" value={rejection_reason_comment}></textarea>
                                            {
                                                (errors && errors.rejection_reason_comment) ? <span className="error show">{errors.rejection_reason_comment}</span> : ''
                                            }
                                            <label data-label="Rejection Reason Comment" className="form-label "></label>
                                            </div>
                                        </fieldset>
                                    }                                   
                                </div>
                            }
                            <div className='button-option'>
                                <button onClick={() => hideRejectionReasonModal()} className='btn-line'>No</button>
                                <button onClick={() => saveRejectionReasons()} className='btn-primary'>Yes</button>
                            </div>
                        </div>

                    </Modal>
                </div>
                {/* end of modal for reason of rejection */}

            {/* modal for reason of inactive */}
            <div className='model-popup-outer rejection_reason_modal'>
                <Modal show={inactiveReasonModal} handleClose={hideInactiveReasonModal} >
                    <div className="modal-header">
                        <h2>Are you sure you want to Mark user as Inactive ?</h2>
                    </div>
                    <div className="modal-body">
                        {ConfigList.inactive_reason &&
                            <div className='rejection_form'>
                                <fieldset className="single-select">
                                    <div className="material">
                                        <Select components={{ ValueContainer: CustomValueContainer }}
                                            options={ConfigList.inactive_reason}
                                            value={ConfigList.inactive_reason.filter((e) => e.value === reason_for_inactive)}
                                            onChange={handleInactiveSelect.bind(null)}
                                            placeholder="Reason For Inactive*"
                                            className="react-select"
                                            classNamePrefix="react-select"
                                        />
                                    </div>
                                    {
                                        (errors && errors.reason_for_inactive) ? <span className="error show">{errors.reason_for_inactive}</span> : ''
                                    }
                                </fieldset>
                                <fieldset className="p-lg-t p-lg-b">
                                    <div class="material">
                                        <textarea placeholder='' onChange={(e) => handleChangetext(e, 'inactive')} rows='6' className="form-input" name="inactive_reason_comment" value={inactive_reason_comment}></textarea>
                                        {
                                            (errors && errors.inactive_reason_comment) ? <span className="error show">{errors.inactive_reason_comment}</span> : ''
                                        }
                                        <label data-label="Write the reasons here" className="form-label "></label>
                                    </div>
                                </fieldset>
                            </div>
                        }
                        <div className='button-option'>
                            <button onClick={() => hideInactiveReasonModal()} className='btn-line'>No</button>
                            <button onClick={() => saveInactiveReasons()} className='btn-primary'>Yes</button>
                        </div>
                    </div>

                </Modal>
            </div>
            {/* end of modal for reason of rejection */}
        </div>
    )
}

const Modal = ({ handleClose, show, children, style = {} }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main' style={style}>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};

export default ViewUser;