import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import MasterService from '../../services/MasterService';
import { BusinessLine, PROGRESSION_FILTER_ROLES } from '../../config/constant';
import InputField from "../common/Input";
import { toast } from 'react-toastify';

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const UserApprovalStatus = [
    { value: "pending", label: "Pending" },
    { value: "approved", label: "Approved" },
];

const LeadListFilter = ({ filterData, setFilterData, searchWithFilters, getProgressionDashboard, errors, setErrors }) => {
    const [RoleList, setRoleList] = useState([]);
    const [ReportingManagerList, setReportingManagerList] = useState([]);

    useEffect(() => {
        getRoleList();
    }, []);

    const handleSelectChange = (selectName, selectValue) => {
        let tempFilterData = { ...filterData };
        let tempErrors = { ...errors };
        if (selectName === "reporting_role_id") {
            tempFilterData[selectName] = selectValue.id;
            getUsersByRole(selectValue.id);
        } else if (selectName === "reporting_manager") {
            tempFilterData[selectName] = selectValue.id;
            tempErrors['reporting_manager'] = ''

        } else {
            tempFilterData[selectName] = selectValue.value;
        }
        setErrors(tempErrors);
        setFilterData(tempFilterData);
    };

    const handleInputChange = (target_name, type, event) => {
        let tempFilterData = { ...filterData };
        if (target_name === "search_text") {
            tempFilterData[target_name] = event.target.value;
        }
        setFilterData(tempFilterData);
    };

    const handleResetFilter = (event) => {
        event.preventDefault();
        setFilterData({});
        getProgressionDashboard({});
    };

    const getRoleList = async () => {
        try {
            let response = await MasterService.post("/sfa/user/role_list", {});
            if (response.data.status === 200) {
                let role_list = response.data.data;
                role_list = role_list
                    .map((elm) => ({ id: elm.id, label: elm.name, value: elm.name }))
                    .filter((item) => PROGRESSION_FILTER_ROLES.includes(item.id));
                setRoleList(role_list);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };

    const getUsersByRole = async (role_id) => {
        try {
            let response = await MasterService.post("/sfa/user/get-users", { role_id });
            if (response.data.status === 200) {
                let reporting_manager_list = response.data.data.user_list.map((elm) => ({ id: elm.id, label: elm.name, value: elm.name }));
                setReportingManagerList(reporting_manager_list);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };

    return (
        <div className="lead-list-filter Progression-top-filter">
            <div className="search-bx">
                <div className="material">
                    <InputField
                        type="text"
                        name="search_text"
                        id="search_text"
                        label="Search By SFA ID or Name"
                        placeholder="Search By SFA ID or Name"
                        value={filterData.search_text}
                        onChange={handleInputChange.bind(this, "search_text", "text")}
                    />
                    <i className="ic-search"></i>
                </div>
            </div>
            <fieldset className="single-select">
                <div className="material">
                    <Select
                        components={{ ValueContainer: CustomValueContainer }}
                        options={BusinessLine}
                        onChange={handleSelectChange.bind(this, "business_line")}
                        value={BusinessLine.filter(({ value }) => value === filterData.business_line)}
                        placeholder="Business Line"
                        className="react-select"
                        classNamePrefix="react-select"
                    />
                </div>
            </fieldset>

            <fieldset className="single-select">
                <div className="material">
                    <Select
                        components={{ ValueContainer: CustomValueContainer }}
                        onChange={handleSelectChange.bind(this, "main_status")}
                        options={UserApprovalStatus}
                        value={UserApprovalStatus.filter(({ value }) => value === filterData.main_status)}
                        placeholder="Main Status"
                        className="react-select"
                        classNamePrefix="react-select"
                    />
                </div>
            </fieldset>

            <fieldset className="single-select">
                <div className="material">
                    <Select
                        components={{ ValueContainer: CustomValueContainer }}
                        options={UserApprovalStatus}
                        value={UserApprovalStatus.filter(({ value }) => value === filterData.reportee_status)}
                        onChange={handleSelectChange.bind(this, "reportee_status")}
                        placeholder="Reportee Status"
                        className="react-select"
                        classNamePrefix="react-select"
                    />
                </div>
            </fieldset>


            <fieldset className="single-select">
                <div className="material">
                    <Select
                        components={{ ValueContainer: CustomValueContainer }}
                        onChange={handleSelectChange.bind(this, "reporting_role_id")}
                        options={RoleList}
                        value={RoleList.filter(({ id }) => id === filterData.reporting_role_id)}
                        placeholder="Reporting to Role"
                        className="react-select"
                        classNamePrefix="react-select"
                    />
                </div>
            </fieldset>
            <fieldset className="single-select">
                <div className="material">
                    <Select
                        components={{ ValueContainer: CustomValueContainer }}
                        onChange={handleSelectChange.bind(this, "reporting_manager")}
                        options={ReportingManagerList}
                        value={ReportingManagerList.filter(({ id }) => id === filterData.reporting_manager)}
                        placeholder="Reporting Manager"
                        className="react-select"
                        classNamePrefix="react-select"
                        isDisabled={filterData.reporting_role_id && filterData.reporting_role_id !== "" ? false : true}
                    />
                </div>
                {
                    (errors && errors.reporting_manager) ? <span className="error show absolute">{errors.reporting_manager}</span> : ''
                }
            </fieldset>
            <div className="btn-submit-reset">
                <button className="btn-primary" onClick={(e) => { searchWithFilters(e, filterData) }}>Search</button>
                <button className="btn-reset" onClick={handleResetFilter} >Reset</button>
            </div>


        </div>
    );
};

export default LeadListFilter;
