import React, { useEffect, useState } from "react";
// import { BrowserRouter, Route, Switch, Router, NavLink, useNavigate, useParams, } from "react-router-dom";//removed warning
import { BrowserRouter, Route, NavLink, useNavigate } from "react-router-dom";
import AddRoleDetailsComponent from "./AddRoleDetailsComponent";
import AssignRights from "./AssignRights";
import RolePreviewConfirm from "./RolePreviewConfirm";
import { MasterService } from "../../services";
import { toast } from "react-toastify";
import { STATUS } from '../../config/constant';


const EditRole = (props) => {
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [roleDetails, setRoleDetails] = useState({});
    // const [loading, setLoading] = useState(false);//removed warning
    const [activePageId, setActivePageId] = useState("");
    const [formFillStatusArr, setFormFillStatusList] = useState([]);
    const [roleId, setRoleId] = useState(null);
    let [roleList, setRoleList] = useState([]);
    const historyLead = useNavigate();

    useEffect(() => {
        getCityList();
        getStateList();
        getRoleList();
        let search_role_id =
            props.location.search && props.location.search !== ""
                ? props.location.search.split("=")[1]
                : null;
        if (search_role_id) {
            setRoleId(search_role_id);
            getRoleDetailsById(search_role_id);
        }
        // checkValidPath();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRoleDetailsById = (id) => {
        // setLoading(true);//removed warning
        MasterService.post("/sfa/role/get_role_view", { role_id: id })
            .then((response) => {
                if (response.data.status === 200) {
                    const {
                        // business_unit,//removed warning
                        // business_vertical,//removed warning
                        role_name,
                        role_description,
                        // digital_consultant,//removed warning
                        role_status,
                        zone_id,
                        cities,
                        states,
                        reporting_from,
                        reporting_to,
                        feature_access,
                    } = response.data.data;
                    let tempRoleData = {
                        role_id: id,
                        role_details_info: {},
                        assign_rights: {},
                    };
                    tempRoleData.role_details_info.vertical = 1;
                    tempRoleData.role_details_info.business_unit = 1;
                    tempRoleData.role_details_info.role_name = role_name;
                    tempRoleData.role_details_info.role_description =
                        role_description;
                    tempRoleData.role_details_info.digital_consultant =
                       0;
                    tempRoleData.role_details_info.status =
                        role_status === "1" ? "active" : "inactive";
                    tempRoleData.assign_rights.role = reporting_to;
                    tempRoleData.assign_rights.reportee = reporting_from;
                    tempRoleData.assign_rights.feature_access = feature_access;
                    [tempRoleData.role_details_info.zone_id] = zone_id;
                    tempRoleData.role_details_info.city = cities;
                    tempRoleData.role_details_info.state = states;
                    setRoleDetails(tempRoleData);
                    getActivePageId(tempRoleData);
                    // setLoading(false);removed warning
                } else {
                    toast.error(response.data.message);
                    // setLoading(false);//removed warning
                }
            })
            .catch((err) => {
                toast.error(err);
                // setLoading(false);//removed warning
            });
    };

    const urlPrefix = `/edit-role`;
    const menuList = [
        {
            id: 1,
            index: 1,
            sub_menu: "Role Details",
            sub_menu_url: "role_details_info",
        }, {
            id: 2,
            index: 2,
            sub_menu: "Assign Rights",
            sub_menu_url: "assign_rights",
        }, {
            id: 3,
            index: 3,
            sub_menu: "Role Preview Confirm",
            sub_menu_url: "role_confirm"
        }
    ]

    const handleSave = async (element, name) => {
        let temp_role_details = { ...roleDetails };
        temp_role_details[name] = element;
        setRoleDetails(temp_role_details);
        let index = menuList.findIndex((x) => x.sub_menu_url === name);
        let next_element = menuList[index + 1];
        let current_element = menuList[index];
        setActivePageId(next_element.id);
        let tempformFillStatusArr = formFillStatusArr;
        if (!tempformFillStatusArr.includes(current_element.id)) {
            tempformFillStatusArr.push(current_element.id);
        }
        setFormFillStatusList(tempformFillStatusArr);
    };

    const getActivePageId = async (roleDetails) => {
        if (roleDetails.role_details_info) {
            let tempformFillStatusArr = formFillStatusArr
            setActivePageId(menuList[0].id)
            if (!tempformFillStatusArr.includes(menuList[0].id)) {
                tempformFillStatusArr.push(menuList[0].id)
            }
            setFormFillStatusList(tempformFillStatusArr)
        }
        if (roleDetails.assign_rights) {
            let tempformFillStatusArr = formFillStatusArr
            setActivePageId(menuList[1].id)
            if (!tempformFillStatusArr.includes(menuList[1].id)) {
                tempformFillStatusArr.push(menuList[1].id)
            }
            setFormFillStatusList(tempformFillStatusArr)
        }
    }

    const handleMenuClick = (sub_menu, index, e) => {
        const linkDisabled = activePageId > sub_menu.id ? false : true;
        if (linkDisabled) e.preventDefault();
        else {
            setActivePageId(sub_menu.id);
            let tempformFillStatusArr = formFillStatusArr;
            if (!tempformFillStatusArr.includes(sub_menu.id)) {
                tempformFillStatusArr.push(sub_menu.id);
                setFormFillStatusList(tempformFillStatusArr);
            }
        }
    };

    // const checkValidPath = () => {//removed warning
    //     if (Object.keys(roleDetails).length === 0) {
    //         handleReset();
    //         historyLead(`role_details_info`,  { replace: true });
    //     }
    // };

    // const handleReset = () => {//removed warning
    //     setActivePageId("");
    //     setFormFillStatusList([]);
    // };

    const getStateList = async () => {
        MasterService.post("/sfa/user/state_list", {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var state_list = response.data.data.map((elm) => ({
                        id: elm.id,
                        label: elm.state_name,
                        value: elm.id,
                    }));
                    setStateList(state_list);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) { });
    };

    const getCityList = async (state_ids) => {
        MasterService.post("/sfa/user/city_list", { state_ids })
            .then(function (response) {
                if (response.data.status === 200) {
                    var city_list = response.data.data.map((elm) => ({
                        id: elm.id,
                        label: elm.city_name,
                        value: elm.id,
                        state_id: elm.state_id,
                    }));
                    setCityList(city_list);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) { });
    };

    const getRoleList = async () => {
        MasterService.post("/sfa/user/role_list", {})
            .then(function (response) {
                if (response.data.status === 200) {
                    let role_list = response.data.data;
                    role_list = role_list.map((elm) => ({
                        id: elm.id,
                        label: elm.name,
                        value: elm.name,
                    }));
                    setRoleList(role_list);
                }
            })
            .catch(function (response) { });
    };

    const handleCreateRole = () => {
        // setLoading(true);//removed warning
        let city_data = cityList.filter((e) =>
            roleDetails.role_details_info.city.includes(e.id)
        );
        let state_data = city_data.map(({ state_id }) => state_id);
        state_data = state_data.filter((item, i, ar) => ar.indexOf(item) === i);
        let feature_access = roleDetails.assign_rights.feature_access
            .filter((item) => item.is_enabled)
            .map((item) => item.id);
        let params = {
            role_id: roleDetails.role_id,
            business_vertical: roleDetails.role_details_info.vertical,
            business_unit: roleDetails.role_details_info.business_unit,
            role_name: roleDetails.role_details_info.role_name,
            role_description: roleDetails.role_details_info.role_description,
            role_status:
                roleDetails.role_details_info.status === "active" ? "1" : "0",
            digital_consultant: roleDetails.role_details_info.digital_consultant
                ? "1"
                : "0",
            zone_id: parseInt(roleDetails.role_details_info.zone_id),
            state: state_data,
            city: city_data,
            reporting_to: roleDetails.assign_rights.role,
            reporting_from: roleDetails.assign_rights.reportee,
            feature_access: feature_access,
        };
        MasterService.post("/sfa/role/create_role", params).then((response) => {
            // setLoading(false);//removed warning
            if (response.data.status === 200) {
                if (response.data.message === "FAILED") {
                    if (
                        response.data.data &&
                        Object.keys(response.data.data).length > 0
                    ) {
                        for (const key in response.data.data) {
                            if (
                                Object.hasOwnProperty.call(
                                    response.data.data,
                                    key
                                )
                            ) {
                                const element = response.data.data[key];
                                toast.error(element.error_message);
                            }
                        }
                    }
                } else {
                    toast.success("Role Edited Successfully");
                    historyLead("/role-management");
                }
            } else {
                if (response.data.message !== "") {
                    toast.error(response.data.message);
                } else if (
                    response.data.error &&
                    response.data.error.length > 0
                ) {
                    response.data.error.forEach((msg) => {
                        toast.error(msg);
                    });
                }
            }
        });
    };

    const renderSwitch = (param, index) => {
        switch (param.sub_menu) {
            case 'Role Details':
                return <Route exact path={urlPrefix + '/role_details_info'}>
                    <AddRoleDetailsComponent
                        status={STATUS}
                        roleDetails={roleDetails}
                        handleSave={handleSave}
                        cityList={cityList}
                        stateList={stateList}
                    />
                </Route>
            case 'Assign Rights':
                return <Route exact path={urlPrefix + '/assign_rights'}>
                    <AssignRights
                        roleDetails={roleDetails}
                        handleSave={handleSave}
                        roleList={roleList}
                    />
                </Route>
            case 'Role Preview Confirm':
                return <Route exact path={urlPrefix + '/role_confirm'}>
                    <RolePreviewConfirm
                        stateList={stateList}
                        cityList={cityList}
                        roleDetails={roleDetails}
                        handleCreateRole={handleCreateRole}
                        roleList={roleList}
                    />
                </Route>
            default:
        }
    }

    return (
        <React.Fragment>
            <div className='add-user-outer'>
                <BrowserRouter>
                    <div className="add-user-form-sec">
                        <div className="menu-tab-left-panel">
                            <div className="add-user-left-panel nav">
                                <ul>
                                    {
                                        menuList.length !== 0 && menuList.map((menu, index) => {
                                            return <li key={index}>
                                                <NavLink to={urlPrefix + "/" + menu.sub_menu_url + "?=" + roleId}
                                                    className={formFillStatusArr && formFillStatusArr.includes(menu.id)
                                                        ? "completed" : activePageId === menu.id ? "active" : ""
                                                    }
                                                    aria-current="page"
                                                    exact
                                                    onClick={handleMenuClick.bind(this, menu, index)}
                                                >
                                                    <span className="img-type"></span>
                                                    {menu.sub_menu}
                                                </NavLink>
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="route-files">
                            {
                                menuList.length !== 0 && menuList.map((menu, index) => {
                                    return <div key={index}>
                                        {renderSwitch(menu)}
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </BrowserRouter>
            </div>
        </React.Fragment>
    );
};

export default EditRole;

