import React from 'react';
const ToggleSwitch = ({toggleChecked ,onClick}) => {
    return (
        <div className='ToggleSwitch ToggleSwitch__rounded'>
            <div className='ToggleSwitch__wrapper'>
                <div className={`Slider ${toggleChecked && 'isChecked'}`} onClick={()=>onClick(!toggleChecked)}></div>
            </div>
        </div>
    );
}
export default React.memo(ToggleSwitch);