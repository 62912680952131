import secureStorage from "../../config/encrypt";

export const useHasRight = (rights = []) => {
    const loginUserInfo = secureStorage.getItem("loginUserInfo");
    const rightsStatus = {};

    if (rights && Array.isArray(rights)) {
        for (const right of rights) {
            rightsStatus[right] = loginUserInfo && loginUserInfo.admin_role_rights && loginUserInfo.admin_role_rights[right] === 1;
        }
    }

    const adminRoleId = loginUserInfo ? loginUserInfo.user_data.admin_role_id : null;

    return { rightsStatus, adminRoleId };
};

